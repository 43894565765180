(function () {
	'use strict';

	angular.module('dcApp').directive('configTooltip', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$rootScope',
				'CommonServices',
				function ($scope, $rootScope, CommonServices) {
					var vm = this;
					$scope.datePatterns = [];
					CommonServices.getDateMasks().then(function (
						responseDatePatterns
					) {
						$scope.datePatterns = responseDatePatterns.data;
					});
					$scope.decPatterns = [
					 "#,##0.00%",
					 "#,##0%",
					 "#%",
					 "#.##%",
					 "#,##0.00",
					 "#,##",
					 "€ #,##0.##;(€ #,##0.##)",
					 "$ #,##0.##;($ #,##0.##)",
					 "#,##0.## €;(#,##0.## €)",
					 "#,##0.## $;(#,##0.## $)"
				 ];
				},
			];

			return buildDirectiveReturnObject(
				{ data: '=', utcAllowed: '='},
				controller,
				'./src/components/directives/configTooltipColumns/configTooltipColumns.html'
			);
		},
	]);
})();
