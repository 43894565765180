(function () {
	'use strict';

	angular.module('dcApp').directive('projectOpProgressStatus', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$rootScope',
				'$timeout',
				'elem',
				'gettextCatalog',
				'PROGRESS_STATUS',
				'PROJECT_PROGRESS_OPERATION',
				'PROJECT_OPERATION_DONE',
				function (
					$scope,
					$rootScope,
					$timeout,
					elem,
					gettextCatalog,
					PROGRESS_STATUS,
					PROJECT_PROGRESS_OPERATION,
					PROJECT_OPERATION_DONE
				) {
					var vm = this;
					vm.elem = elem;
					vm.data = {};
					vm.finished = PROGRESS_STATUS.finished;
					vm.running = PROGRESS_STATUS.running;
					vm.canceled = PROGRESS_STATUS.canceled;
					vm.failed = PROGRESS_STATUS.failed;
					vm.failed_version = PROGRESS_STATUS.failed_version;
					vm.operationExport = PROJECT_PROGRESS_OPERATION.export;
					vm.operationAnalysisExport =
						PROJECT_PROGRESS_OPERATION.export_analysis;
					vm.operationImport = PROJECT_PROGRESS_OPERATION.import;
					vm.operationDuplication = PROJECT_PROGRESS_OPERATION.duplication;
					vm.operationImportAnalysis =
						PROJECT_PROGRESS_OPERATION.import_analysis;
					vm.state = vm.running;
					vm.execId = undefined;
					vm.messages = {};

					vm.exportFailed = false;
					vm.progressBarOptions = {
						min: 0,
						max: 100,
						width: '100%',
						bindingOptions: {
							value: 'vm.progressBarValue',
						},
						statusFormat: function (value) {
							return vm.exportFailed ? '' : Math.round(value * 100) + '%';
						},
						onComplete: function (e) {
							if (!vm.exportFailed) {
								e.element.addClass('complete');
							}
						},
						onValueChanged: function (e) {
							if (vm.exportFailed) {
								e.element
									.find('.dx-progressbar-range')
									.addClass('project-op-progress-failed');
							}
						},
					};

					let subTitleKey = {};
					// Export messages
					subTitleKey[vm.operationExport] = gettextCatalog.getString(
						'project.op.progress.export.titlekey'
					);
					let messagesExport = {};
					messagesExport[vm.running] = {
						subTitleKey: subTitleKey[vm.operationExport],
						to: vm.data.to,
						projectLabel: vm.data.projectLabel,
						state: gettextCatalog.getString(
							'project.op.progress.export.in.progress'
						),
						message: gettextCatalog.getString(
							'project.op.progress.export.message'
						),
					};
					messagesExport[vm.failed] = {
						subTitleKey: subTitleKey[vm.operationExport],
						to: vm.data.to,
						projectLabel: vm.data.projectLabel,
						state: gettextCatalog.getString(
							'project.op.progress.export.failed'
						),
						message: gettextCatalog.getString(
							'project.op.progress.export.failed.message'
						),
						subMessage2: gettextCatalog.getString(
							'project.op.progress.import.failed.sub.message2'
						),
						subMessage3: gettextCatalog.getString(
							'project.op.progress.import.failed.sub.message3'
						),
					};
					messagesExport[vm.finished] = {
						subTitleKey: subTitleKey[vm.operationExport],
						to: vm.data.to,
						projectLabel: vm.data.projectLabel,
						state: gettextCatalog.getString('project.op.progress.export.done'),
						message: gettextCatalog.getString(
							'project.op.progress.export.done.message'
						),
					};

					vm.messages[vm.operationExport] = messagesExport;

					// duplication messages
					subTitleKey[vm.operationDuplication] = gettextCatalog.getString(
						'project.op.progress.duplicate.titlekey'
					);
					let messagesDuplication = {};
					messagesDuplication[vm.running] = {
						subTitleKey: subTitleKey[vm.operationDuplication],
						to: vm.data.to,
						projectLabel: vm.data.projectLabel,
						state: gettextCatalog.getString(
							'project.op.progress.duplicate.in.progress'
						),
						message: gettextCatalog.getString(
							'project.op.progress.duplicate.message'
						),
					};
					messagesDuplication[vm.failed] = {
						subTitleKey: subTitleKey[vm.operationDuplication],
						to: vm.data.to,
						projectLabel: vm.data.projectLabel,
						state: gettextCatalog.getString(
							'project.op.progress.duplicate.failed'
						),
						message: gettextCatalog.getString(
							'project.op.progress.duplicate.message'
						),
					};
					messagesDuplication[vm.finished] = {
						subTitleKey: subTitleKey[vm.operationDuplication],
						to: vm.data.to,
						projectLabel: vm.data.projectLabel,
						state: gettextCatalog.getString(
							'project.op.progress.duplicate.done'
						),
						message: gettextCatalog.getString(
							'project.op.progress.duplicate.done.message'
						),
					};

					vm.messages[vm.operationDuplication] = messagesDuplication;

					// export analysis
					subTitleKey[vm.operationAnalysisExport] = gettextCatalog.getString(
						'project.op.progress.export.titlekey'
					);
					let messagesExportAnalysis = {};
					messagesExportAnalysis[vm.running] = {
						subTitleKey: subTitleKey[vm.operationExport],
						to: vm.data.to,
						projectLabel: vm.data.projectLabel,
						state: gettextCatalog.getString(
							'project.op.progress.export.analyse.in.progress'
						),
						message: gettextCatalog.getString(
							'project.op.progress.export.analyse.message'
						),
					};
					messagesExportAnalysis[vm.failed] = {
						subTitleKey: subTitleKey[vm.operationExport],
						to: vm.data.to,
						projectLabel: vm.data.projectLabel,
						state: gettextCatalog.getString(
							'project.op.progress.export.analyse.failed'
						),
						message: gettextCatalog.getString(
							'project.op.progress.export.analyse.failed.message'
						),
					};
					vm.messages[vm.operationAnalysisExport] = messagesExportAnalysis;

					// import analysis
					subTitleKey[vm.operationImportAnalysis] = gettextCatalog.getString(
						'project.op.progress.import.analyse.titlekey'
					);
					let messagesImportAnalysis = {};
					messagesImportAnalysis[vm.running] = {
						subTitleKey: subTitleKey[vm.operationImportAnalysis],
						to: vm.data.to,
						projectLabel: vm.data.projectLabel,
						state: gettextCatalog.getString(
							'project.op.progress.import.analyse.in.progress'
						),
						message: gettextCatalog.getString(
							'project.op.progress.import.analyse.message'
						),
					};
					messagesImportAnalysis[vm.failed] = {
						subTitleKey: subTitleKey[vm.operationImportAnalysis],
						to: vm.data.to,
						projectLabel: vm.data.projectLabel,
						state: gettextCatalog.getString(
							'project.op.progress.import.analyse.failed'
						),
						message: gettextCatalog.getString(
							'project.op.progress.import.analyse.failed.message'
						),
						subMessage1: gettextCatalog.getString(
							'project.op.progress.import.failed.sub.message1'
						),
						subMessage2: gettextCatalog.getString(
							'project.op.progress.import.failed.sub.message2'
						),
						subMessage3: gettextCatalog.getString(
							'project.op.progress.import.failed.sub.message3'
						),
					};

					messagesImportAnalysis[vm.failed_version] = {
						subTitleKey: subTitleKey[vm.operationImportAnalysis],
						to: vm.data.to,
						projectLabel: vm.data.projectLabel,
						state: gettextCatalog.getString(
							'project.op.progress.import.analyse.failed.version'
						),
						message: gettextCatalog.getString(
							'project.op.progress.import.analyse.failed.version.message'
						),
						versionKey: gettextCatalog.getString(
							'project.op.progress.import.analyse.failed.version.key'
						),
					};
					vm.messages[vm.operationImportAnalysis] = messagesImportAnalysis;

					// export analysis
					subTitleKey[vm.operationImport] = gettextCatalog.getString(
						'project.op.progress.import.titlekey'
					);
					let messagesImport = {};
					messagesImport[vm.running] = {
						subTitleKey: subTitleKey[vm.operationDuplication],
						to: vm.data.to,
						projectLabel: vm.data.projectLabel,
						state: gettextCatalog.getString(
							'project.op.progress.import.in.progress'
						),
						message: gettextCatalog.getString(
							'project.op.progress.import.message'
						),
					};
					messagesImport[vm.failed] = {
						subTitleKey: subTitleKey[vm.operationDuplication],
						to: vm.data.to,
						projectLabel: vm.data.projectLabel,
						state: gettextCatalog.getString(
							'project.op.progress.import.failed'
						),
						message: gettextCatalog.getString(
							'project.op.progress.import.failed.message'
						),
						subMessage2: gettextCatalog.getString(
							'project.op.progress.import.failed.sub.message2'
						),
						subMessage3: gettextCatalog.getString(
							'project.op.progress.import.failed.sub.message3'
						),
					};
					messagesImport[vm.finished] = {
						subTitleKey: subTitleKey[vm.operationImport],
						state: gettextCatalog.getString('project.op.progress.import.done'),
						message: gettextCatalog.getString(
							'project.op.progress.import.done.message'
						),
					};

					vm.messages[vm.operationImport] = messagesImport;

					let currentOps = 1;
					$scope.$on('bulk_status', function (event, notif) {
						if (
							notif &&
							notif.status &&
							(notif.execution_id === vm.execId ||
								(vm.projectId && vm.projectId == notif.project_id))
						) {
							if (notif.status === vm.finished) {
								formatAndSentResultToParent(notif);
								$timeout(function () {
									vm.progressBarValue = 100;
									vm.state = vm.finished;
									vm.exportFailed = false;
								}, 900);
							} else if (notif.status === vm.failed) {
								$scope.$apply(function () {
									vm.progressBarValue = 100;
									vm.exportFailed = true;
									vm.state = vm.failed;
								});
							} else if (notif.status === vm.running) {
								$scope.$apply(function () {
									vm.progressBarValue = (currentOps / vm.countOps) * 100;
									vm.state = vm.running;
									vm.exportFailed = false;
								});
							} else if (notif.status === vm.failed_version) {
								$scope.$apply(function () {
									vm.version = notif.version;
									vm.progressBarValue = 100;
									vm.exportFailed = true;
									vm.state = vm.failed_version;
								});
							}
							currentOps = currentOps + 1;
						}
					});

					$scope.$on('bulk_status_progress', function (event, data) {
						vm.currentOperation = data.operation;
						vm.countOps = data.countOps;
						vm.progressBarValue = data.progressBarInitValue
							? data.progressBarInitValue
							: undefined;
						vm.execId = data.execId;
						vm.state = data.running ? vm.running : vm.state;
						vm.projectId = data.projectId;
					});

					function formatAndSentResultToParent(data) {
						let resultToBroadcast;
						let operationEvent;
						if (
							vm.currentOperation &&
							vm.currentOperation === vm.operationExport
						) {
							let result = data.result;
							resultToBroadcast = {
								execId: data.execution_id,
								link: result.link,
							};
							operationEvent = PROJECT_OPERATION_DONE.export;
						} else if (
							(vm.currentOperation &&
								vm.currentOperation === vm.operationImport) ||
							(vm.currentOperation &&
								vm.currentOperation === vm.operationDuplication)
						) {
							let result = data.result;
							resultToBroadcast = result.new_project_id;
							operationEvent =
								vm.currentOperation === vm.operationDuplication
									? PROJECT_OPERATION_DONE.duplication
									: PROJECT_OPERATION_DONE.import;
						}

						if (resultToBroadcast && operationEvent) {
							$rootScope.$broadcast(operationEvent, resultToBroadcast);
						}
					}
				},
			];
			return buildDirectiveReturnObject(
				{ isProgress: '=' },
				controller,
				'./src/components/directives/project/projectOpProgressStatus/projectOpProgressStatus.html',
				function postLink(scope, element, attrs) {
					initElementForDirective(element, attrs, function () {
						scope.vm.isProgress = false;
						scope.vm.progressBarValue = 0;
					});
					watchElementForDirective(
						element,
						scope,
						function () {
							return scope.vm.isProgress;
						},
						function () {
							scope.vm.exportFailed = false;
						},
						true
					);
				}
			);
		},
	]);
})();
