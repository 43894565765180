(function () {
	angular.module('dcApp').factory('CommonServices', [
		'$rootScope',
		'$http',
		'API_BASE_URL_BACKEND',
		function ($rootScope, $http, API_BASE_URL_BACKEND) {
			var CommonServices = {},
				endPoint = API_BASE_URL_BACKEND;

			CommonServices.getDateMasks = function () {
				return $http.get(endPoint + 'date_masks').then(function (response) {
					return response;
				});
			};

			CommonServices.getDateReadingMasks = function () {
				return $http
					.get(endPoint + 'date_reading_masks')
					.then(function (response) {
						return response;
					});
			};

			CommonServices.getServerTimezone = function () {
				return $http.get(	API_BASE_URL_BACKEND + $rootScope.getUrl('TIMEZONE_SERVER')).then(function (response) {
					return response;
				});
			};

			CommonServices.getTimezones = function () {
				return $http.get(	API_BASE_URL_BACKEND + $rootScope.getUrl('TIMEZONE')).then(function (response) {
					return response;
				});
			};

			CommonServices.getCharsets = function () {
				return $http
					.get(API_BASE_URL_BACKEND + 'charsets')
					.then(function (response) {
						return response;
					});
			};

			CommonServices.validateFileByReadingMaskGlob = function (
				fileName,
				readingMask
			) {
				return $http
					.get(
						API_BASE_URL_BACKEND +
							'/' +
							$rootScope.getUrl('EXPRESS_INTEGRATION_VALIDATE_FILE') +
							'?fileName=' +
							fileName +
							'&readingMask=' +
							readingMask
					)
					.then(function (response) {
						return response;
					});
			};

			CommonServices.isEmpty = function (value) {
				return value === undefined || value === null || value === '';
			};

			// Format decimal display
			CommonServices.formatNumber = function (value, tooltipPattern) {
					if (isEmpty(value)) {
						return value;
					}
					if (value instanceof Array) {
							return value.map(v => CommonServices.formatNumber(v, tooltipPattern)).join("; ");
					} else {
					let formattedValue = value;
					let pattern = tooltipPattern ? tooltipPattern.pattern : undefined;
					let maxFraction = !isEmpty(tooltipPattern.precision) ? tooltipPattern.precision : 2;
					switch (pattern) {
						case "#,##0.00%":
							formattedValue = value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: maxFraction }) + '%';
							break;
						case "#,##0%":
							formattedValue = value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + '%';
							break;
						case "#%":
							formattedValue = value.toFixed(0) + '%';
							break;
						case "#.##%":
							formattedValue = value.toFixed(2) + '%';
							break;
						case "#,##0.00":
							formattedValue = value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: maxFraction });
							break;
						case "#,##":
							formattedValue = value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
							break;
						case "€ #,##0.##;(€ #,##0.##)":
							formattedValue = value >= 0
									? "€ " + value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: maxFraction })
									: "(€ " + Math.abs(value).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: maxFraction }) + ")";
							break;
						case "$ #,##0.##;($ #,##0.##)":
							formattedValue = value >= 0
									? "$ " + value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: maxFraction })
									: "($ " + Math.abs(value).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: maxFraction }) + ")";
							break;
						case "#,##0.## €;(#,##0.## €)":
							formattedValue = value >= 0
									? value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: maxFraction }) + " €"
									: "(" + Math.abs(value).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: maxFraction }) + " €)";
							break;
						case "#,##0.## $;(#,##0.## $)":
								formattedValue = value >= 0
										? value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: maxFraction }) + " $"
										: "(" + Math.abs(value).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: maxFraction }) + " $)";
								break;
						default:
							formattedValue = value.toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: maxFraction});
					}

					return formattedValue;
					}
			}

			return CommonServices;
		},
	]);
})();
