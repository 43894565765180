(function () {
	'use strict';

	angular.module('dcApp').directive('simplegridview', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'GridViewCommon',
				'CommonServices',
				function ($scope, GridViewCommon, CommonServices) {
					var vm = this;
					// package com.adb.hdh.hd.common.enums.Defined in FormatType
					const formatPatterns = {
						PERCENT_2_DECIMALS: "#,##0.00%",
						PERCENT_NO_DECIMALS: "#,##0%",
						PERCENT_SIMPLE: "#",
						PERCENT_2_DECIMALS_DOT: "#.##%",
						NUMBER_2_DECIMALS: "#,##0.00",
						NUMBER_SIMPLE: "#,##",
						EURO_2_DECIMALS: "€ #,##0.##;(€ #,##0.##)",
						DOLLAR_2_DECIMALS: "$ #,##0.##;($ #,##0.##)",
						EURO_NUMBER_2_DECIMALS: "#,##0.## €;(#,##0.## €)",
						DOLLAR_NUMBER_2_DECIMALS: "#,##0.## $;(#,##0.## $)"
					};
					var getColumns = function () {
						var columnsDxGrid = [];
						for (var c in $scope.config.global.columns) {
							let col = _.find($scope.group.columns, function (item) {
								return (
									item.uuid == $scope.config.global.columns[c].source_column
								);
							});
							if (!col) {
								continue;
							}
							var colDx = {
								allowEditing: false,
								allowFiltering: true,
								allowFixing: true,
								allowHiding: true,
								allowReordering: true,
								allowSorting: true,
								allowSearch: true,
								dataField: $scope.config.global.columns[c].source_column,
								visible: $scope.config.global.columns[c].visible ? true : false,
								fixed: $scope.config.global.columns[c].fixed,
								caption: $scope.config.global.columns[c].caption
									? $scope.config.global.columns[c].caption
									: col.lib,
								is_list: col.is_list,

								alignment: $scope.config.global.columns[c].alignment,
								dataType: $scope.config.global.columns[c].type,
								font_color: $scope.config.global.columns[c].font_color,
								background_color:
									$scope.config.global.columns[c].background_color,
								font_family: $scope.config.global.columns[c].font_family,
								style: $scope.config.global.columns[c].style,
								bold: $scope.config.global.columns[c].bold,
								font_size: $scope.config.global.columns[c].font_size,

								cellTemplate: function (element, info) {
									if (info.column.font_color) {
										element
											.text(info.text)
											.css('color', info.column.font_color);
									} else {
										element.text(info.text);
									}
									if (info.column.font_family) {
										element
											.text(info.text)
											.css('font-family', info.column.font_family);
									}
									if (info.column.style) {
										element
											.text(info.text)
											.css('font-style', info.column.style);
									}
									if (info.column.background_color) {
										element
											.text(info.text)
											.css('background-color', info.column.background_color);
									}
									if (info.column.bold) {
										element.text(info.text).css('font-weight', 'bold');
									}
									if (info.column.font_size) {
										element
											.text(info.text)
											.css('font-size', info.column.font_size + 'px');
									}
								},
							};
							if ($scope.config.global.columns[c].format) {
								let format = $scope.config.global.columns[c].format;
								if (format in formatPatterns)  {
									let selectedPattern = formatPatterns[format];
									colDx.selectedPattern = selectedPattern;
									if ( (col.type === 'decimal' || col.type === 'integer' || col.type === 'biginteger' )) {
										colDx.calculateCellValue = function(rowData) {
												 let patternConf = {pattern: this.selectedPattern, precision: 2 };

												return CommonServices.formatNumber(rowData[this.dataField], patternConf);
										}
									}
								} else {
									colDx.format = $scope.config.global.columns[c].format;
								}
							}


							columnsDxGrid.push(colDx);
						}
						return columnsDxGrid;
					};

					$scope.showPopover = function ($event, obj) {
						if (Object.keys(obj).length === 0) {
							$scope.noFilterApplied = true;
						} else {
							$scope.noFilterApplied = false;
						}
						// Set a bigger z-index
						$(function () {
							DevExpress.ui.dxOverlay.baseZIndex(1999999998);
						});
					};

					function initPopover() {
						$scope.sourceCentring = vm.data.centering
							? vm.data.centering.source_centering
							: {};
						$scope.filterDetailsPopover = {
							target: '#filterInfoPopover' + $scope.uuid,
							showEvent: 'dxclick',
							position: 'top',
							width: 450,
							bindingOptions: {
								visible: 'visibleMetaData',
							},
						};
					}

					vm.init = function (ignoreCache) {
						function checkSimpleConfig(config) {
							return true;
						}

						function loadGroupDataInGrid(data) {
							initPopover();
							var dataSource = _.map(data, function (item) {
								var newItem = {};
								for (var c in $scope.group.columns) {
									newItem[$scope.group.columns[c].uuid] =
										item[$scope.group.columns[c].uuid].val;
								}
								return newItem;
							});

							let gridOptions = {
								columnFixing: { enabled: true },
								columnAutoWidth: true,
								showBorders: true,
								noDataText: '',
								onContentReady: function (e) {
									limitColumnWithWhenLargerThan400(e);
								},
							};

							GridViewCommon.setCommonGridConfig(gridOptions, $scope.config);
							gridOptions.allowColumnReordering =
								$scope.config.global.allow_column_reordering;
							gridOptions.groupPanel = {
								visible: $scope.config.global.group_panel_visible,
							};
							gridOptions.columns = getColumns();
							gridOptions.dataSource = dataSource;
							gridOptions.summary = { totalItems: [] };
							gridOptions.columnChooser = { enabled: false };
							gridOptions.scrolling = { mode: 'infinite' };

							for (var c in $scope.config.global.columns) {
								if ($scope.config.global.columns[c].summary1) {
									gridOptions.summary.totalItems.push({
										column: $scope.config.global.columns[c].source_column,
										summaryType: $scope.config.global.columns[c].summary1,
									});
								}
								if ($scope.config.global.columns[c].summary2) {
									gridOptions.summary.totalItems.push({
										column: $scope.config.global.columns[c].source_column,
										summaryType: $scope.config.global.columns[c].summary2,
									});
								}
							}

							if ($scope.config.global.tree_mode) {
								$scope.treeOptions = gridOptions;
								$scope.treeOptions.keyExpr = $scope.config.global.key_expr;
								$scope.treeOptions.parentIdExpr =
									$scope.config.global.parent_id_expr;
								$scope.treeOptions.filterMode = 'fullBranch';
							} else {
								$scope.gridOptions = gridOptions;
							}
						}

						GridViewCommon.initializeGridViewScope(
							ignoreCache,
							$scope,
							vm,
							'simple',
							checkSimpleConfig,
							loadGroupDataInGrid
						);

						$scope.init(ignoreCache);

						if (!vm.data.full_data) {
							$scope.jobDetails = { rerunMethod: $scope.rerunWithoutCache };
							$scope.jobDetailsId = generateUuid('_');
						}
					};
				},
			];

			return buildDirectiveReturnObject(
				{ data: '=' },
				controller,
				'./src/components/directives/handledata/gridView/simple/gridView.html',
				function postLink(scope, element, attrs, $ctrl) {
					$ctrl.init(false);
				}
			);
		},
	]);
})();
