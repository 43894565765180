(function () {
	'use strict';

	angular.module('dcApp').controller('valueSearchController', [
		'SearchService',
		'$timeout',
		'$scope',
		'$state',
		'$rootScope',
		'toaster',
		'$location',
		'PAGINATIONS_SIZE',
		'PAGINATIONS_SIZES',
		'$q',
		'API_BASE_URL_BACKEND',
		'$http',
		'$window',
		'TypeEntiteService',
		'SparkJobsServices',
		'DataBlocksService',
		'gettextCatalog',
		function (
			SearchService,
			$timeout,
			$scope,
			$state,
			$rootScope,
			toaster,
			$location,
			PAGINATIONS_SIZE,
			PAGINATIONS_SIZES,
			$q,
			API_BASE_URL_BACKEND,
			$http,
			$window,
			TypeEntiteService,
			SparkJobsServices,
			DataBlocksService,
			gettextCatalog
		) {
			// Help
			$scope.helpTitleTra = gettextCatalog.getString(
				'Aide Recherche de valeurs'
			);

			let translateErrorMessage = gettextCatalog.getString(
				'context.unavailable'
			);

			$scope.text = '';
			$scope.entites_types = [];
			$scope.isBCRoot = true;
			$scope.currentPage = 1;
			$scope.pageSize = 10;
			$scope.ignoreCase = true;
			$scope.advancedMode = false;
			$scope.widgetData = {};
			$scope.entiteTypeStatus = {};
			$scope.selected_entites_types_ = {};
			$scope.filter = { type: 'string', operator: 'equal' };
			$scope.elementGraphData = {};
			$scope.multicolumnMode = 'OR';
			$scope.showHdhGraph = function (et) {
				$scope.elementGraphData.centeredElementLabel = et.lib;
				$scope.elementGraphData.centeredElementType = et.type;
				$scope.elementGraphData.centeredElementId = et.id;
				$scope.elementGraphData.showHdhElementGraph = true;
			};

			getScopeQB = function () {
				return angular.element($('#searchDiv')).scope();
			};

			$scope.openValueList = function (rule_id) {
				$scope.selectedRule = getRuleById_(rule_id);
				$scope.listValue = angular.copy($scope.selectedRule.value_list);

				$scope.widgetData.columns = [];
				$scope.widgetData.listValue = $scope.listValue;
				$scope.widgetData.selectedRule = $scope.selectedRule;
				$scope.widgetData.updateaction_l2 = $scope.saveListValue;
				$scope.$apply();
				$scope.widgetData.showListValueFilter = true;
				$scope.$apply();
			};

			$scope.saveListValue = function () {
				$scope.selectedRule.value_list = $scope.widgetData.listValue;
			};

			var getRuleById_ = function (id) {
				var rules = getAllShildrenRules($scope.rulesRoot.rules);

				for (var r in rules) {
					if (rules[r].id_ == id) {
						return rules[r];
					}
				}
			};

			let stringTra = gettextCatalog.getString('Chaîne de caractères');
			let dateTra = gettextCatalog.getString('Date');
			let integerTra = gettextCatalog.getString('Entier');
			let bigIntegerTra = gettextCatalog.getString('Grand nombre entier');
			let decimalTra = gettextCatalog.getString('Décimal');
			let booleanTra = gettextCatalog.getString('Booléen');
			$scope.types = [
				{
					code: 'string',
					lib: stringTra,
				},
				{
					code: 'date',
					lib: dateTra,
				},
				{
					code: 'integer',
					lib: integerTra,
				},
				{
					code: 'big_integer',
					lib: bigIntegerTra,
				},
				{
					code: 'decimal',
					lib: decimalTra,
				},
				{
					code: 'boolean',
					lib: booleanTra,
				},
			];

			$scope.getOperators = function (type) {
				return _.filter(qbOperators, function (op) {
					return op.apply_to.indexOf(type) > -1;
				});
			};

			$scope.$on('job_private', function (event, message) {
				$scope.handleSocketMessageJobRunning(message);
			});

			$scope.handleSocketMessageJobRunning = function (notif) {
				if (
					notif.data.status == 'CACHED' ||
					(notif.data.status !== 'SUCCESS' &&
						notif.data.status !== 'ERROR' &&
						notif.data.status !== 'CANCELLED' &&
						notif.data.status !== 'CACHED')
				) {
					console.log('OOOPs return: ', notif);
					return;
				}

				for (var et in $scope.entites_types) {
					if (notif.data.client_id == $scope.entites_types[et].clientId) {
						if (notif.data.status !== 'ERROR') {
							SearchService.getSearchResultByJobKey(notif.data.job_id, 0).then(
								function (jobResponse) {
									setEntiteTypeCount(notif.data.client_id, jobResponse.data);
									return;
								}
							);
						} else if (notif.data.status == 'ERROR') {
							fillEntiteError(
								notif.data.error_message,
								$scope.entites_types[et]
							);
						}
					}
				}
			};

			$scope.setRoot = function (builder, root) {
				$scope.rulesRoot = root;
				$scope.qBuilderInstance = builder;
			};

			$scope.qbFilters = [];
			for (var t in $scope.types) {
				var fil = {
					id: t,
					label: $scope.types[t].lib,
					type: $scope.types[t].code,
					unique: true,
				};
				$scope.qbFilters.push(fil);

				if (fil.type == 'date') {
					fil.plugin = 'datetimepicker';
					fil.plugin_config = {
						format: 'DD/MM/YYYY HH:mm:ss',
					};
					fil.validation = {
						format: 'DD/MM/YYYY HH:mm:ss',
					};
				} else if (fil.type == 'boolean') {
					fil.input = 'radio';
					fil.values = {
						1: 'Vrai',
						0: 'Faux',
					};
				}
			}

			$('#builder-plugins').queryBuilder({
				plugins: ['sortable', 'bt-tooltip-errors'],
				filters: $scope.qbFilters,
				mainInstance: true,
				selectFilterActive: true,
				mainInstanceDivId: 'searchDiv',
				rootCondition: 'AND',
				lang_code: $rootScope.currentLangCode
					? $rootScope.currentLangCode
					: 'fr',
				disable_select_value: true,
				disable_select_column: true,
			});

			$('#builder-plugins').on(
				'afterCreateRuleInput.queryBuilder',
				function (e, rule) {
					if (rule.filter.type === 'date') {
						var $input = rule.$el.find('.rule-value-container [name*=_value_]');
						$input.on('dp.change', function () {
							$input.trigger('change');
						});
					}
				}
			);

			$scope.addGroup = function (that, mod) {
				that.addGroup(mod);
				return;
			};

			$scope.deleteRule = function (that, model) {
				$scope.nbRules--;
				that.deleteRule(model);
			};

			$scope.deleteGroup = function (that, model) {
				that.deleteGroup(model);
				return;
			};

			$scope.rule_id = 0;
			$scope.addRules = function () {
				$scope.rule_id++;
				$scope.selectedThat = $scope.qBuilderInstance;
				$scope.selectedMod = $scope.rulesRoot;
				var model = $scope.selectedThat.addRule($scope.selectedMod);
				model.filter = $scope.qbFilters[0];
				model.is_active = true;
				$scope.selectedThat.addFilter(fil);
				model.id_ = $scope.rule_id;
			};

			$scope.setFilters = function (filters) {
				$scope.filters = filters;
			};

			$scope.getSelectedEntiteTypes = function () {
				var selected_entites_types = [];
				for (var et in $scope.selected_entites_types_.list) {
					selected_entites_types.push(
						$scope.selected_entites_types_.list[et].code
					);
				}
				return selected_entites_types;
			};

			$scope.searchEntites = function () {
				$scope.searchEntiteError = {};
				$scope.search = true;
				$scope.activeEntiteTypeWidgets = [];
				for (let et in $scope.entites_types) {
					if ($scope.entites_types[et].widget) {
						$scope.activeEntiteTypeWidgets.push($scope.entites_types[et].code);
					}
				}
				$scope.entites_types = [];
				for (let et in $scope.selected_entites_types_.list) {
					let entiteType = {
						id: $scope.selected_entites_types_.list[et].id,
						code: $scope.selected_entites_types_.list[et].code,
						lib: $scope.selected_entites_types_.list[et].lib,
						type: $scope.selected_entites_types_.list[et].isDataBlock
							? 'DATABLOCK'
							: 'ENTITETYPE',
					};

					entiteType.jobDetails = {
						rerunMethod: function () {
							$scope.searchEntitesByEntiteType(entiteType, true);
						},
					};

					$scope.searchEntitesByEntiteType(entiteType);
					$scope.entites_types.push(entiteType);
				}
				return;
			};

			$scope.checkEnabledStopAll = function () {
				$scope.enabledStopAll = false;
				if ($scope.entites_types) {
					for (var e in $scope.entites_types) {
						if ($scope.entites_types[e].loading) {
							$scope.enabledStopAll = true;
							return;
						}
					}
				}
			};

			var setEntiteTypeCount = function (client_id, data) {
				var entiteType = {};
				for (var et in $scope.entites_types) {
					if (client_id == $scope.entites_types[et].clientId) {
						entiteType = $scope.entites_types[et];
						break;
					}
				}

				entiteType.jobDetails.initAndUpdate({
					name: 'Données',
					pushDate: data.push_date,
					endExecutionDate: data.exec_end_date,
					duration: data.time_exec,
				});
				entiteType.jobDetails.setFromCache(data.is_cached);
				entiteType.loading = false;
				entiteType.loaded = true;
				entiteType.count = data.data;
				$scope.checkEnabledStopAll();
				if (
					$scope.activeEntiteTypeWidgets.indexOf(entiteType.code) > -1 &&
					entiteType.count > 0
				) {
					$scope.loadWidget(entiteType);
				}
			};

			function handleError(error, entiteType) {
				let errorMessage = '';
				if (
					error &&
					error.data &&
					(error.data.code == '37' ||
						error.data.code == '38' ||
						error.data.code == '39')
				) {
					errorMessage = translateErrorMessage;
				}
				fillEntiteError(errorMessage, entiteType);
			}

			function fillEntiteError(errorMessage, entiteType) {
				$scope.searchEntiteError[entiteType.code].errorMessage = errorMessage;
				$scope.searchEntiteError[entiteType.code].error = true;
				entiteType.loading = false;
				entiteType.loaded = false;
			}

			$scope.searchEntitesByEntiteType = function (entiteType, withoutCache) {
				$rootScope.disableAjaxLoading = true;
				entiteType.clientId = generateClientId($rootScope.account.login);
				entiteType.loading = true;
				delete entiteType.jobKey;
				$scope.searchEntiteError[entiteType.code] = {};

				if ($scope.advancedMode) {
					var result = $scope.qBuilderInstance.getRules();
					if (result.invalid == true || !result.queries.rules[0]) {
						return;
					}
					$scope.searchFilter = result.queries;
					stringifyArrayValue($scope.searchFilter);
					SearchService.searchEntitesByEntiteType(
						$scope.searchFilter,
						entiteType.code,
						entiteType.clientId,
						$scope.multicolumnMode,
						withoutCache
					)
						.then(function (response) {
							entiteType.jobKey = response.data.value;
							entiteType.loaded = false;
							entiteType.stopped = false;
							$scope.enabledStopAll = true;

							if (response.data.exec_data_result) {
								setEntiteTypeCount(
									entiteType.clientId,
									response.data.exec_data_result
								);
								delete entiteType.clientId;
								return;
							}

							if (!entiteType.jobKey) {
								entiteType.loading = false;
								entiteType.loaded = false;
							}
						})
						.catch(function (error) {
							handleError(error, entiteType);
						});
				} else {
					SearchService.searchEntitesByEntiteTypeByText(
						$scope.text,
						$scope.ignoreCase,
						entiteType.code,
						entiteType.clientId,
						$scope.multicolumnMode,
						withoutCache
					)
						.then(function (response) {
							entiteType.jobKey = response.data.value;
							entiteType.loaded = false;
							entiteType.stopped = false;
							$scope.enabledStopAll = true;

							if (response.data.exec_data_result) {
								setEntiteTypeCount(
									entiteType.clientId,
									response.data.exec_data_result
								);
								delete entiteType.clientId;
								return;
							}

							if (!entiteType.jobKey) {
								entiteType.loading = false;
								entiteType.loaded = false;
							}
						})
						.catch(function (error) {
							handleError(error, entiteType);
						});
				}
			};

			$scope.searchEntiteError = {};
			$scope.reloadSearch = function (entiteType) {
				$scope.searchEntitesByEntiteType(entiteType);
			};

			$scope.stopSearch = function (entiteType) {
				if (entiteType.jobKey) {
					SparkJobsServices.killJob(entiteType.jobKey).then(function (
						response
					) {
						entiteType.loading = false;
						entiteType.loaded = false;
						entiteType.stopped = true;
						$scope.checkEnabledStopAll();
					});
				}
			};

			$scope.stopAllSearch = function () {
				for (var et in $scope.entites_types) {
					if ($scope.entites_types[et].loading) {
						$scope.stopSearch($scope.entites_types[et]);
					}
				}
			};

			$scope.loadWidget = function (entiteType) {
				let etId = entiteType.etId ? entiteType.etId : entiteType.id;
				TypeEntiteService.getEntiteTypeGrammar(etId).then(function (response) {
					if (!response.data) {
						return;
					}
					var grammar = response.data;

					for (var c in grammar.columns) {
						grammar.columns[c].uuid = generateUuid('_');
					}
					if (!$scope.advancedMode) {
						entiteType.widget = buildDataBlock(
							{ grammar: grammar },
							$scope.entiteTypeLib,
							etId
						);
						entiteType.widget.hidenGlobalSearchText = $scope.text;
						entiteType.widget.entiteReboundAction =
							$scope.getAttachedEntitesFromWidget;
						entiteType.widget.entiteType = entiteType;
						entiteType.widget.globalSearchTextIgnoreCase = $scope.ignoreCase;
						entiteType.widget.globalSearchTextMode = $scope.multicolumnMode;
					} else {
						SearchService.getSearchGrammarFilter(
							$scope.searchFilter,
							grammar.columns,
							$scope.multicolumnMode
						).then(function (response1) {
							$scope.widgetData.updateWidgetColumnsAndRulesParam =
								'nestedRules';

							entiteType.widget = buildDataBlock(
								{ grammar: grammar },
								$scope.entiteTypeLib,
								etId
							);
							entiteType.widget.entiteReboundAction =
								$scope.getAttachedEntitesFromWidget;
							entiteType.widget.entiteType = entiteType;
							entiteType.widget.steps = [{ filter: response1.data }];
						});
					}
				});
			};

			$scope.setNestedFilterForWidget = function (rules, grammar) {
				if (!grammar.rules) {
					grammar.rules = { condition: 'OR', rules: [] };
				}
				grammar.rules.rules.push(rules);
			};

			$scope.removeWidget = function (entiteType) {
				delete entiteType.widget;
			};

			var stringifyArrayValue = function (rule) {
				if (rule.condition && rule.rules) {
					for (var r in rule.rules) {
						stringifyArrayValue(rule.rules[r]);
					}
				} else {
					if ($.isArray(rule.value)) {
						rule.value = JSON.stringify(rule.value);
					}
				}
			};

			var init = function () {
				$scope.selected_entites_types_ = {};
				$scope.selected_entites_types_.list = [];
				$scope.entite_types = [];
				TypeEntiteService.getTypes(undefined, undefined, true).then(function (
					response
				) {
					var eTypes = response.data;

					for (let et in eTypes) {
						eTypes[et].lib = eTypes[et].label;
						if (eTypes[et].active_in_value_search) {
							$scope.selected_entites_types_.list.push(eTypes[et]);
						}
					}

					$scope.entite_types = $scope.entite_types.concat(eTypes);
					const excludePublic = false;
					const excludeInactive = true;
					DataBlocksService.getDatablocks(excludePublic, excludeInactive).then(function (response2) {
						var datablocks = response2.data;
						for (let et in datablocks) {
							datablocks[et].lib = datablocks[et].metadata.label;
							datablocks[et].code = datablocks[et].metadata.code;
							datablocks[et].isDataBlock = true;
						}
						$scope.entite_types = $scope.entite_types.concat(datablocks);
					});
				});

				if ($location.search().value) {
					$scope.text = $location.search().value;
					$scope.entite = $location.search().entite;
					$scope.centredSearch = true;
					$scope.searchEntites();
				}
			};

			init();
		},
	]);
})();
