'use strict';

angular
	.module('dcApp')
	.controller('MapListController', [
		'$timeout',
		'$scope',
		'$state',
		'$rootScope',
		'toaster',
		'MapService',
		'$q',
		'PermissionService',
		'API_BASE_URL_BACKEND',
		'gettextCatalog',
		'DcElementListService',
		function (
			$timeout,
			$scope,
			$state,
			$rootScope,
			toaster,
			MapService,
			$q,
			PermissionService,
			API_BASE_URL_BACKEND,
			gettextCatalog,
			DcElementListService
		) {
			let dataGirdColumnName = {};
			dataGirdColumnName.label = gettextCatalog.getString('Libellé');
			dataGirdColumnName.tags = gettextCatalog.getString('Tags');
			dataGirdColumnName.updateDate = gettextCatalog.getString(
				'Date de mise à jour'
			);
			dataGirdColumnName.actions = gettextCatalog.getString('Actions');
			dataGirdColumnName.code = gettextCatalog.getString('Code');
			dataGirdColumnName.creationDate =
				gettextCatalog.getString('Date de création');
			dataGirdColumnName.description = gettextCatalog.getString('Description');

			let toasterPopSuccess = gettextCatalog.getString('Succès');
			let toasterPopDeleteMap = gettextCatalog.getString(
				'Suppression de la Carte effectuée'
			);
			let toasterPopDuplicateMap = gettextCatalog.getString(
				'Duplication Carte effectuée'
			);
			let toasterPopDeleteMaps = gettextCatalog.getString(
				'Suppression des Cartes effectuée'
			);

			$scope.accessToken =
				window._keycloak && window._keycloak.token
					? window._keycloak.token
					: '';
			$scope.API_BASE_URL_BACKEND = API_BASE_URL_BACKEND;
			$scope.currentProjectId = $rootScope.currentProjectId;
			$scope.showCurrentProjectOnly = true;
			$scope.activeElementsOnly = false;
			$scope.checkPermissionData = {};
			PermissionService.haveGlobalPermission('map-create').then(function (
				response
			) {
				$scope.create_authorised = response.data;
			});

			$scope.createMap = function () {
				$state.go('maps-new', {});
			};

			$scope.deleteElementData = {};
			$scope.doShowDeleteModal = function (count) {
				$scope.deleteElementData = {
					label: $scope.objToDelete ? $scope.objToDelete.lc : undefined,
					doDelete: $scope.bulkDelete
						? $scope.deleteBulkMaps
						: $scope.deleteMap,
					showConfirmDeleteElement: true,
					bulk: $scope.bulkDelete,
					count: count,
				};
			};

			$scope.bulkDelete = false;
			$scope.showDeleteModal = function (obj) {
				$scope.objToDelete = obj;
				$scope.bulkDelete = false;
				$scope.checkPermissionData.elementId = obj.id;
				$scope.checkPermissionData.type = 'map';
				$scope.checkPermissionData.action = 'delete';
				$scope.checkPermissionData.actionIfPermitted = $scope.doShowDeleteModal;
				$scope.checkPermissionData.showPermissionCheck = true;
			};

			$scope.deleteBulkMapsModal = function () {
				$scope.bulkDelete = true;
				$scope.doShowDeleteModal($scope.selectedElems.length);
			};

			$scope.deleteBulkMaps = function () {
				let mapIds = _.map($scope.selectedElems, function (con) {
					return con.id;
				});
				MapService.deleteBulkMaps(mapIds)
					.then(function () {
						if ($scope.map_authorised) {
							initMaps();
						}
						toaster.pop('success', toasterPopSuccess, toasterPopDeleteMaps);
					})
					.catch(function (e) {
						if (e && e.data && e.data.code == '40' && $scope.map_authorised) {
							initMaps();
						}
					});
			};

			$scope.editMap = function (obj) {
				$scope.objToEdit = obj;
				$scope.checkPermissionData.elementId = obj.id;
				$scope.checkPermissionData.type = 'map';
				$scope.checkPermissionData.action = 'edit';
				$scope.checkPermissionData.actionIfPermitted = $scope.goToEdit;
				$scope.checkPermissionData.showPermissionCheck = true;
			};

			$scope.duplicateMap = function (obj) {
				MapService.duplicateMap(obj.id).then(function (response) {
					toaster.pop('success', toasterPopSuccess, toasterPopDuplicateMap);
					$state.go('maps-edit', { id: response.data });
				});
			};

			$scope.goToEdit = function () {
				$state.go('maps-edit', { id: $scope.objToEdit.id });
			};

			$scope.viewMap = function (obj) {
				$state.go('maps-view', { id: obj.id, mode: 'visualisation' });
			};

			$scope.deleteMap = function () {
				MapService.deleteMap($scope.objToDelete.id).then(function (response) {
					toaster.pop('success', toasterPopSuccess, toasterPopDeleteMap);
					initMaps();
				});
			};

			function getListMaps() {
				var deferred = $q.defer();
				MapService.getMaps($scope.showCurrentProjectOnly, $scope.activeElementsOnly).then(
					function (response) {
						deferred.resolve(response.data);
					},
					function (errorMessage) {
						deferred.reject();
					}
				);

				return deferred.promise;
			}

			let yesTra = gettextCatalog.getString('Oui');
			let noTra = gettextCatalog.getString('Non');
			$scope.getGridData = function () {
				$scope.showGrid = false;
				getListMaps().then(function (content) {
					if (content != undefined) {
						$scope.maps = [];
						$scope.tags = [];
						var tags = [];
						var i = 0;
						for (var r in content) {
							$scope.maps.push(
								DcElementListService.buildHdListElement(
									content[r],
									$rootScope,
									false
								)
							);
							i++;
							tags = _.union(tags, content[r].metadata.tags);
						}

						for (var i in tags) {
							if (_.find($scope.tags, { id: tags[i].id }) == null) {
								$scope.tags.push({ id: tags[i].id, label: tags[i].code });
							}
						}

						$scope.gridData = angular.copy($scope.maps);
						$scope.dataGridOptions.dataSource = $scope.gridData;
						$scope.showGrid = true;
					}
				});
			};

			function initMaps() {
				PermissionService.haveGlobalPermission('map').then(function (response) {
					$scope.map_authorised = response.data;
					if ($scope.map_authorised) {
						$scope.getGridData();
						$scope.disableAllSelectedElems = true;
					}
				});
			}

			function init() {
				$scope.active = true;
			}

			init();

			$scope.moveOrCopyDataMap = {};
			$scope.moveOrCopyMapsToProject = function () {
				let connectorIds = _.map($scope.selectedElems, function (con) {
					return con.id;
				});
				$scope.moveOrCopyDataMap = {
					elements: connectorIds,
					type: 'map',
					showMoveCopy: true,
				};
			};
			$scope.moveOrCopyOneMapToProject = function (elementId) {
				$scope.moveOrCopyDataMap = {
					elements: [elementId],
					type: 'map',
					showMoveCopy: true,
				};
			};

			// Get DxGrid dataGridOptions
			DcElementListService.getDcElementDxList($scope, 'map', false);
			initMaps();
		},
	])
	.controller('MapEditController', [
		'$q',
		'$http',
		'API_BASE_URL_BACKEND',
		'$stateParams',
		'$state',
		'$timeout',
		'$scope',
		'$rootScope',
		'toaster',
		'MapService',
		'GenericService',
		'PAGINATIONS_SIZES',
		'PAGINATIONS_SIZE',
		'$window',
		'PictogrammeService',
		'MAP_ELEMENT_TYPES',
		'hdSourceService',
		'gettextCatalog',
		'MAPS_TILES_PROVIDERS',
		'PermissionService',
		function (
			$q,
			$http,
			API_BASE_URL_BACKEND,
			$stateParams,
			$state,
			$timeout,
			$scope,
			$rootScope,
			toaster,
			MapService,
			GenericService,
			PAGINATIONS_SIZES,
			PAGINATIONS_SIZE,
			$window,
			PictogrammeService,
			MAP_ELEMENT_TYPES,
			hdSourceService,
			gettextCatalog,
			MAPS_TILES_PROVIDERS,
			PermissionService
		) {
			// Help
			$scope.helpTitleTra = gettextCatalog.getString('Aide Carte');

			let toasterPopSuccess = gettextCatalog.getString('Succès');
			let toasterPopDeleteMap = gettextCatalog.getString(
				'Suppression de la Carte effectuée'
			);
			let toasterPopSaveMap = gettextCatalog.getString(
				'Enregistrement des paramètres effectuée'
			);
			let toasterPopCreationMap = gettextCatalog.getString(
				'Création de la Carte effectuée'
			);
			let toasterPopEditMap = gettextCatalog.getString(
				'Modification de la Carte effectuée'
			);

			$scope.accessToken =
				window._keycloak && window._keycloak.token
					? window._keycloak.token
					: '';
			$scope.checkPermissionData = {};
			$scope.metadata = {};
			$scope.metadata.actif = true;
			$scope.metadata.tags = [];
			$scope.permissionData = {};
			$scope.API_BASE_URL_BACKEND = API_BASE_URL_BACKEND;
			$scope.widgetData = {};
			$scope.iconsSelector = {};
			$scope.elementGraphData = {};
			$scope.dataModel = {};

			let personalizeTra = gettextCatalog.getString('Personnaliser...');
			let NoneTra = gettextCatalog.getString('Aucun');

			$scope.mpaTilesProviders = _.each(MAPS_TILES_PROVIDERS, function (el) {
				if (el.custom) {
					el.providerName = personalizeTra;
				} else if (el.none) {
					el.providerName = NoneTra;
				}
			});

			$scope.tileConfigs = [];

			$scope.mapView = {};

			$scope.redirectionToList = function () {
				$('#confirmationModal').modal('hide');
				$timeout(function () {
					$state.go('maps');
				}, 300);
			};

			$scope.dataModel.redirectionToList = $scope.redirectionToList;

			//    $scope.link_modes_driving = gettextCatalog.getString("Voiture")

			$scope.link_modes = [
				{ value: 'DRIVING', lib: 'Voiture' },
				{ value: 'WALKING', lib: 'Vélo' },
				{ value: 'BICYCLING', lib: 'Pieds' },
			];
			$scope.elemetTypes = MAP_ELEMENT_TYPES;

			$scope.deleteElementData = {};
			$scope.doShowDeleteModal = function () {
				$scope.deleteElementData = {
					label:
						$scope.currentMap && $scope.currentMap.metadata
							? $scope.currentMap.metadata.label
							: undefined,
					doDelete: $scope.deleteMap,
					showConfirmDeleteElement: true,
				};
			};

			$scope.showDeleteModal = function () {
				$scope.checkPermissionData.elementId = $scope.currentMap.id;
				$scope.checkPermissionData.type = 'map';
				$scope.checkPermissionData.action = 'delete';
				$scope.checkPermissionData.actionIfPermitted = $scope.doShowDeleteModal;
				$scope.checkPermissionData.showPermissionCheck = true;
			};

			$scope.deleteMap = function () {
				MapService.deleteMap($scope.currentMap.id).then(function (response) {
					toaster.pop('success', toasterPopSuccess, toasterPopDeleteMap);
					$timeout(function () {
						$state.go('maps');
					}, 300);
				});
			};

			$scope.toggleShowTabs = function () {
				$scope.hideTabs = !$scope.hideTabs;
			};

			//handle data source directive
			$scope.onLoadSource = function (noAvailableSources) {
				$scope.sourceExists = !noAvailableSources;
				$scope.disabledConfig = noAvailableSources;
				if ($scope.disabledConfig) {
					$scope.disabledVisualisation = true;
				}
			};

			$scope.dataSource = {
				onOpenSource: $scope.toggleShowTabs,
				onLoadSource: $scope.onLoadSource,
			};
			//handle data source directive

			$scope.showDataBlockViews = function (saveSelectedDataBlockViews) {
				$scope.widgetData.saveSelectedDataBlockViews =
					saveSelectedDataBlockViews;
				$scope.widgetData.showDataBlockViews = true;
			};

			$scope.dataSource.showDataBlockViews = $scope.showDataBlockViews;

			$scope.showSources = function () {
				if (!$scope.configSaved && $scope.mapConfig) {
					$scope.saveConfig(showSources);
				}
				showSources();
			};

			var showSources = function () {
				delete $scope.mapView.mapId;
				$scope.dataSource.reinit();
			};
			//end

			$scope.showHdhGraph = function () {
				$scope.elementGraphData.centeredElementLabel =
					$scope.currentMap.metadata.label;
				$scope.elementGraphData.centeredElementType = 'MAP';
				$scope.elementGraphData.centeredElementId = $scope.currentMap.id;
				$scope.elementGraphData.showHdhElementGraph = true;
			};

			$scope.showPermissions = function () {
				$scope.checkPermissionData.elementId = $scope.currentMap.id;
				$scope.checkPermissionData.type = 'map';
				$scope.checkPermissionData.action = 'permissions';
				$scope.checkPermissionData.actionIfPermitted = $scope.doShowPermissions;
				$scope.checkPermissionData.showPermissionCheck = true;
			};

			$scope.doShowPermissions = function () {
				$scope.permissionData.metadataId = $scope.currentMap.metadata.id;
				$scope.permissionData.type = 'map';
				$scope.permissionData.elementId = $scope.currentMap.id;
				$scope.permissionData.lib = $scope.currentMap.metadata.label;
				$scope.permissionData.showElementPermissions = true;
			};

			$scope.showConfirmationModal = function () {
				$('#confirmationModal').modal('show');
			};

			$scope.showConfig = function () {
				delete $scope.mapView.mapId;
				$scope.configSaved = false;
				MapService.getMapConfig($stateParams.id).then(function (response) {
					$scope.mapConfig = response.data;
					if (!$scope.mapConfig.global) {
						$scope.mapConfig.global = {};
					}
					setGroupColumns(0);
					getSelectedTileConfig();
				});
			};

			var setGroupColumns = function (i) {
				if (!$scope.mapConfig.groups[i]) {
					return;
				}
				hdSourceService
					.getSourceColumns(
						$scope.mapConfig.groups[i].source_id,
						'MAP',
						$stateParams.id
					)
					.then(function (response) {
						$scope.mapConfig.groups[i].columns = response.data;
						setGroupColumns(i + 1);
					});
			};

			function getSelectedTileConfig() {
				$scope.tileConfigs = [];
				if ($scope.mapConfig.global && $scope.mapConfig.global.tiles_config) {
					for (let i in $scope.mapConfig.global.tiles_config) {
						let tile = _.find($scope.mpaTilesProviders, function (elm) {
							return (
								elm.providerUrl ==
								$scope.mapConfig.global.tiles_config[i].tile_provider_url
							);
						});
						if (tile == undefined) {
							tile = _.find($scope.mpaTilesProviders, function (elm) {
								return elm.custom;
							});
						}
						$scope.tileConfigs[i] = { selected: tile };
						$scope.tileConfigs[i].providerName =
							$scope.mapConfig.global.tiles_config[i].tile_provider_name;
						$scope.tileConfigs[i].providerUrl =
							$scope.mapConfig.global.tiles_config[i].tile_provider_url;
						$scope.tileConfigs[i].tileId =
							$scope.mapConfig.global.tiles_config[i].tile_provider_id;
						$scope.tileConfigs[i].apiKey =
							$scope.mapConfig.global.tiles_config[i].tile_provider_api_key;
					}
				} else {
					$scope.tileConfigs[0] = { selected: $scope.mpaTilesProviders[1] };
					$scope.tileConfigs[0].providerName =
						$scope.tileConfigs[0].selected.providerName;
					$scope.tileConfigs[0].providerUrl =
						$scope.tileConfigs[0].selected.providerUrl;
					$scope.tileConfigs[0].tileId =
						$scope.tileConfigs[0].selected.defaultId;
				}
			}

			function setTileConfig() {
				$scope.mapConfig.global.tiles_config = [];
				for (let i in $scope.tileConfigs) {
					$scope.mapConfig.global.tiles_config[i] = {
						tile_provider_name: $scope.tileConfigs[i].selected.custom
							? $scope.tileConfigs[i].providerName
							: $scope.tileConfigs[i].selected.providerName,
						tile_provider_url: $scope.tileConfigs[i].selected.custom
							? $scope.tileConfigs[i].providerUrl
							: $scope.tileConfigs[i].selected.providerUrl,
						tile_provider_id: $scope.tileConfigs[i].tileId,
						tile_provider_api_key: $scope.tileConfigs[i].apiKey,
					};
				}
			}

			$scope.handleTileConfigChange = function (index) {
				if (
					$scope.tileConfigs[index] &&
					$scope.tileConfigs[index].selected &&
					$scope.tileConfigs[index].selected.custom
				) {
					$scope.tileConfigs[index].providerName = undefined;
					$scope.tileConfigs[index].providerUrl = undefined;
					$scope.tileConfigs[index].tileId = undefined;
					$scope.tileConfigs[index].apiKey = undefined;
				} else {
					$scope.tileConfigs[index].tileId =
						$scope.tileConfigs[index].selected.defaultId;
				}
			};

			$scope.addTileConf = function () {
				$scope.tileConfigs.push({ providerUrl: '' });
			};

			$scope.removeTileConf = function (index) {
				$scope.tileConfigs.splice(index, 1);
			};

			$scope.saveConfig = function (afterSave) {
				// get tile config
				setTileConfig();
				var config = angular.copy($scope.mapConfig);
				var groups = [];
				for (var g in config.groups) {
					groups.push({
						id: config.groups[g].id,
						config: config.groups[g].config,
					});
				}
				config.groups = groups;
				MapService.editMapConfig($stateParams.id, config).then(function (
					response
				) {
					//$scope.configSaved = true;
					toaster.pop('success', toasterPopSuccess, toasterPopSaveMap);
					$scope.disabledVisualisation = false;
					$scope.configIsValid = true;
					if (afterSave) {
						afterSave();
					}
				});
			};

			$scope.addMapElement = function (group) {
				if (!group.config.elements) {
					group.config.elements = [];
				}
				group.config.elements.push({
					type: $scope.elemetTypes[0].code,
					opacity: 0.1,
				});
			};

			$scope.removeMapElement = function (group, index) {
				group.config.elements.splice(index, 1);
			};

			$scope.selectIcon = function (me) {
				$scope.selectedElementForIcon = me;
				$scope.iconsSelector.saveSelection = function (icon) {
					$scope.selectedElementForIcon.icon = icon;
				};
				$scope.iconsSelector.showIconsSelector = true;
			};

			$scope.openElementTooltip = function (me, group) {
				$scope.selectedMapElement = me;
				updateSortedColumns(me, group);
				$('#listTooltipColumnsModal').modal('show');
			};

			var updateSortedColumns = function (me, group) {
				if (!me.tooltip_columns) {
					me.tooltip_columns = [];
					me.tooltip_columns_patterns = {};
				}
				var sortedColumns = [];
				for (let c in me.tooltip_columns) {
					var col = _.find(group.columns, function (item) {
						return item.uuid == me.tooltip_columns[c];
					});
					if (!col) {
						continue;
					}
					col.selected = true;
					if (me.tooltip_columns_patterns && me.tooltip_columns_patterns[col.uuid]) {
						col.pattern = me.tooltip_columns_patterns[col.uuid].pattern;
						col.precision = me.tooltip_columns_patterns[col.uuid].precision;
					}
					sortedColumns.push(col);
				}

				for (let c in group.columns) {
					if (sortedColumns.indexOf(group.columns[c]) < 0) {
						group.columns[c].selected = false;
						sortedColumns.push(group.columns[c]);
					}
				}
				$scope.sortedColumns = sortedColumns;
			};

			$scope.saveElementTooltip = function () {
				$scope.selectedMapElement.tooltip_columns = [];
				$scope.selectedMapElement.tooltip_columns_patterns = {};
				for (let c in $scope.sortedColumns) {
					if ($scope.sortedColumns[c].selected) {
						$scope.selectedMapElement.tooltip_columns.push(
							$scope.sortedColumns[c].uuid
						);
						if ($scope.sortedColumns[c].pattern) {
							let precision = !isEmpty($scope.sortedColumns[c].precision) ? $scope.sortedColumns[c].precision : 2;
							$scope.selectedMapElement.tooltip_columns_patterns[$scope.sortedColumns[c].uuid] = {pattern: $scope.sortedColumns[c].pattern, precision: precision};
						} else {
							delete $scope.selectedMapElement.tooltip_columns_patterns[$scope.sortedColumns[c].uuid];
						}
					}
				}
				$('#listTooltipColumnsModal').modal('hide');
			};

			$scope.closeElementTooltip = function () {
				$('#listTooltipColumnsModal').modal('hide');
			};

			$scope.showVisualization = function () {
				if (!$scope.configSaved && $scope.mapConfig) {
					PermissionService.havePermission($stateParams.id, 'edit', 'map').then(
						function (response) {
							if (response.data) {
								$scope.saveConfig(showVisualization);
							} else {
								showVisualization();
							}
						}
					);
				} else {
					showVisualization();
				}
			};

			var showVisualization = function () {
				$scope.configSaved = true;
				if ($scope.sourceExists && $scope.configIsValid) {
					if (!$scope.mapView.mapId) {
						$scope.mapView.mapId = $stateParams.id;
					} else {
						$scope.mapView.reinit();
					}
				}
			};

			init();

			function init() {
				$scope.isNew = true;
				$scope.savingInProgress = false;

				if ($stateParams.id) {
					$scope.isNew = false;
					//if no source is available => deactivate config and visualisation tab. Else activate config tab
					hdSourceService
						.getNbrSourcesByElement('MAP', $stateParams.id)
						.then(function (response) {
							$scope.sourceExists = response.data;
							if ($scope.sourceExists) {
								MapService.checkMapConfig($stateParams.id).then(function (
									responseCheck
								) {
									$scope.configIsValid = responseCheck.data;
									if ($stateParams.mode == 'visualisation') {
										$('.nav-tabs a[data-target="#visualization"]').tab('show');
										$scope.showVisualization();
									} else {
										$('.nav-tabs a[data-target="#config"]').tab('show');
										$scope.disabledVisualisation = !$scope.configIsValid;
										$scope.showConfig();
									}
								});
							} else if ($stateParams.mode == 'visualisation') {
								$scope.disabledConfig = true;
								$('.nav-tabs a[data-target="#visualization"]').tab('show');
								$scope.showVisualization();
							} else {
								$scope.disabledConfig = true;
								$scope.disabledVisualisation = true;
							}
						});

					$scope.metadata.isNew = false;
					var mapId = $stateParams.id;
					$scope.dataSource.hdId = mapId;
					$scope.dataSource.hdType = 'MAP';
					MapService.getMap(mapId).then(function (response) {
						$scope.currentMap = response.data;
						$scope.metadata = $scope.currentMap.metadata;
						$scope.mapLib = $scope.metadata.label;
						$scope.metadataLoaded = true;
					});
				} else {
					$scope.metadataLoaded = true;
				}
			}

			$scope.saveAndReturnToList = function () {
				$scope.saveMap(true);
			};
			$scope.dataModel.save = $scope.saveAndReturnToList;

			$scope.savingInProgress = false;
			let catchMapSaveErrors = function (error) {
				$scope.savingInProgress = false;
			};

			$scope.saveMap = function (returnToList) {
				$('#confirmationModal').modal('hide');

				// set metadata
				for (var t in $scope.metadata.tags) {
					$scope.metadata.tags[t].color =
						$scope.metadata.tags[t].color != undefined
							? $scope.metadata.tags[t].color
							: '#dbf5d1';
				}

				var map = {};

				map.metadata = {
					active: $scope.metadata.actif,
					tags: $scope.metadata.tags,
					licence_type: $scope.metadata.licence_type,
					icon_id: $scope.metadata.icon_id,
					short_label: $scope.metadata.label,
					description: $scope.metadata.description,
				};

				$scope.savingInProgress = true;

				if ($scope.isNew) {
					map.metadata.code = $scope.metadata.code;
					MapService.createMap(map)
						.then(function (response) {
							toaster.pop('success', toasterPopSuccess, toasterPopCreationMap);
							if (returnToList) {
								$timeout(function () {
									$scope.savingInProgress = false;
									$state.go('maps');
								}, 300);
							} else {
								$timeout(function () {
									$scope.savingInProgress = false;
									$state.go('maps-edit', { id: response.data });
								}, 300);
							}
						})
						.catch(catchMapSaveErrors);
				} else {
					MapService.editMap($scope.currentMap.id, map)
						.then(function (response) {
							toaster.pop('success', toasterPopSuccess, toasterPopEditMap);
							$scope.savingInProgress = false;
							if (returnToList) {
								$timeout(function () {
									$state.go('maps');
								}, 300);
							}
						})
						.catch(catchMapSaveErrors);
				}
			};
		},
	]);
