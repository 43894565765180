(function () {
	'use strict';

	angular
		.module('dcApp')
		.controller(
			'DashBoardElmTitlesConfigurationController',
			DashBoardElmTitlesConfigurationController
		);

	DashBoardElmTitlesConfigurationController.$inject = ['$scope'];

	function DashBoardElmTitlesConfigurationController($scope) {
		var vm = this;

		vm.availableFonts = [
			{
				code: 'segoe_ui_light',
				label: 'Segoe UI Light',
			},
			{
				code: 'helvetica_neue_light',
				label: 'Helvetica Neue Light',
			},
			{
				code: 'segoe_ui',
				label: 'Segoe UI',
			},
			{
				code: 'helvetica_neue',
				label: 'Helvetica Neue',
			},
			{
				code: 'trebuchet_ms',
				label: 'Trebuchet MS',
			},
			{
				code: 'verdana',
				label: 'Verdana',
			},
			{
				code: 'sans_serif',
				label: 'Sans-serif',
			},
		];

		vm.titleConfig = vm.titleConfig || {
			text: '',
			font: {
				color: '#696363',
				size: 25,
				family: 'verdana',
			},
			subtitle: {
				text: '',
				font: {
					color: '#bd8686',
					size: 12,
					family: 'verdana',
				},
			},
		};
	}
})();
