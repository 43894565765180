(function () {
	'use strict';

	angular.module('dcApp').directive('jobs', [
		function () {
			var controller = [
				'$log',
				'$scope',
				'$state',
				'$rootScope',
				'SparkJobsServices',
				'ParametersServices',
				'PAGINATIONS_SIZE',
				'PAGINATIONS_SIZE',
				'$window',
				'gettextCatalog',
				'UserService',
				'ElementsLinkGenerator',
				'toaster',
				'CTX_VAR_NAME',
				'JOBS_CACHE_STATUS',
				function (
					$log,
					$scope,
					$state,
					$rootScope,
					SparkJobsServices,
					ParametersServices,
					PAGINATIONS_SIZE,
					PAGINATIONS_SIZES,
					$window,
					gettextCatalog,
					UserService,
					ElementsLinkGenerator,
					toaster,
					CTX_VAR_NAME,
					JOBS_CACHE_STATUS
				) {
					var vm = this;

					var date_format = 'DD/MM/Y HH:mm:ss.SSS';

					$scope.cacheConfig = { active: $rootScope.jobCacheActive };

					$scope.init = function () {
						$scope.currentUser = $rootScope.account;
						UserService.isAdmin().then(function (response) {
							$scope.currentUserIsAdmin = response.data;
							$scope.loadJobs();
						});
					};

					$scope.updateJobs = function (job) {
						if (!$scope.gridApi) {
							return;
						}
						var row = getGridRow(job.job_id);
						if (row[0] && !$scope.onlyRunning) {
							updateJob(row[0], job);
						} else if (
							row[0] &&
							$scope.onlyRunning &&
							job.status != 'RUNNING'
						) {
							var job = getJob(job.job_id);
							removeJob(job[0]);
						} else if (
							!row[0] &&
							(job.status == 'RUNNING' || !$scope.onlyRunning)
						) {
							addJob(job);
						}
					};

					var getGridRow = function (job_key) {
						return _.filter($scope.gridApi.grid.rows, function (item) {
							return item.entity.job_key == job_key;
						});
					};

					var getJob = function (job_key) {
						return _.filter($scope.jobs, function (item) {
							return item.job_key == job_key;
						});
					};

					var addJob = function (job) {
						var activity;
						var userLogin;
						if (job.request) {
							activity = $rootScope.getRunningJob(job.request.action);
							userLogin = job.request.user_login;
						}
						$scope.jobs.push({
							user: userLogin,
							activity: activity,
							parentId: job.parent_id,
							job_key: job.job_id,
							error_message: job.error_message,
							type: job.type,
							element_label: job.element_label,
							status: job.status,
							duration: job.execution_time
								? job.execution_time / 1000 + ' s'
								: '',
							start_time: moment(job.start_time).format(date_format),
							push_date: moment(job.push_date).format(date_format),
							showCancel: showKill(job)
						});
						$scope.gridOptions.data = $scope.jobs;
					};

					var removeJob = function (job) {
						var jobs = _.filter($scope.jobs, function (item) {
							return item.job_key !== job.job_key;
						});
						$scope.jobs = jobs;
						$scope.gridOptions.data = $scope.jobs;
					};

					var updateJob = function (row, job) {
						row.entity.status = job.status;
						row.entity.duration = job.execution_time + ' s';
						row.entity.start_time = moment(job.start_time).format(date_format);
						row.entity.push_date = moment(job.push_date).format(date_format);
					};

					$scope.goToElement = function (el) {
						let elTo = {
							id: el.element_id,
							type: el.element_type,
							sub_type: el.element_sub_type,
						}
						ElementsLinkGenerator.getLinkAndGo(elTo);
					};

				$scope.goToProject = function (el) {
					if (!isEmpty(el.project_id)) {
						ElementsLinkGenerator.goToProject(el.project_id);
					}
				};

					$scope.initOptions = function () {
						$scope.gridOptions = {
							paging: {
								enabled: true,
								pageSize: PAGINATIONS_SIZE,
							},
							pager: {
								showPageSizeSelector: true,
								allowedPageSizes: PAGINATIONS_SIZES,
								showInfo: true,
								visible: true,
							},
							filterRow: {
								visible: true,
								applyFilter: 'auto',
							},
							rowAlternationEnabled: true,
							headerFilter: {
								visible: true,
								applyFilter: 'auto',
							},
							showColumnLines: true,
							showRowLines: true,
							allowColumnReordering: true,
							allowColumnResizing: true,
							columnAutoWidth: true,
							showBorders: true,
							columnFixing: {
								enabled: true,
							},
							columns: [
								{
									caption: gettextCatalog.getString('Utilisateur'),
									cellTemplate: 'user',
								},
								{
									caption: gettextCatalog.getString('Tâche'),
									dataField: 'activity',
								},
								{
									caption: gettextCatalog.getString('jobs.list.element.element.project'),
									cellTemplate: 'projectLabel',
									dataField: 'project_label',
								},
								{
									caption: gettextCatalog.getString('jobs.list.element.label'),
									cellTemplate: 'elementLabel',
									dataField: 'element_label',
								},
								{
									caption: gettextCatalog.getString('jobs.list.element.type'),
									dataField: 'element_type_label',
								},
								{
									caption: gettextCatalog.getString('Etat'),
									dataField: 'status',
								},
								{
									caption: gettextCatalog.getString('Durée'),
									dataField: 'duration',
								},
								{
									caption: gettextCatalog.getString("Date d'exécution"),
									dataField: 'start_time',
								},
								{
									caption: gettextCatalog.getString("Date d'émission"),
									dataField: 'push_date',
								},
								{
									caption: gettextCatalog.getString('Actions'),
									alignment: 'center',
									cellTemplate: 'actions',
									width: 120,
									allowReordering: false,
								},
							],
							onInitialized: function (e) {
								$scope.gridInstance = e.component;
							},
						};
					};
					$scope.onlyRunning = true;
					$scope.loadJobs = function () {
						if (!$window.sessionStorage.getItem(CTX_VAR_NAME)) {
							return;
						}
						$scope.jobs = [];
						delete $scope.gridOptions;
						$scope.showGrid = false;
						SparkJobsServices.getJobs($scope.onlyRunning).then(function (
							response
						) {
							for (var r in response.data) {
								$scope.jobs.push({
									activity: $rootScope.getRunningJob(response.data[r].type),
									user: response.data[r].user_id,
									element_id: response.data[r].element_id,
									job_key: response.data[r].job_id,
									error_message: response.data[r].error_message,
									element_type_label: getElementTypeLabel(response.data[r].element_type),
									element_type: response.data[r].element_type,
									element_label: response.data[r].element_label,
									project_id: response.data[r].project_id,
									project_label: response.data[r].project_label,
									status: response.data[r].status,
									id: r,
									duration: response.data[r].execution_time
										? response.data[r].execution_time / 1000 + ' s'
										: '',
									start_time: moment(response.data[r].start_date)
										.format(date_format),
									push_date: moment(response.data[r].submission_date)
										.format(date_format),
									showCancel: showKill(response.data[r])
								});
							}

							$scope.initOptions();
							$scope.gridOptions.dataSource = $scope.jobs;
							$scope.showGrid = true;
							if ($scope.gridInstance && $scope.gridInstance.refresh) {
								$scope.gridInstance.option('dataSource', $scope.jobs);
								$scope.gridInstance.refresh();
							}
						});
					};

					$scope.killJob = function (jobKey) {
						SparkJobsServices.killJob(jobKey).then(function (response) {
							$scope.loadJobs();
						});
					};

					$scope.killAllJobs = function () {
						SparkJobsServices.killAllJobs().then(function (response) {
							$scope.loadJobs();
						});
					};

					$scope.clearCache = function () {
						SparkJobsServices.clearJobs().then(function (response) {});
					};

					$scope.updateJobsCacheStatus = function () {
						$rootScope.jobCacheActive = $scope.cacheConfig.active;
						$window.localStorage.setItem(
							JOBS_CACHE_STATUS,
							$rootScope.jobCacheActive
						);
					};

					$scope.close = function () {
						$(vm.element).modal('hide');
					};

					function showKill(job) {
						const subDate = job.submission_date ? moment(job.submission_date) : moment(job.push_date);
						return job.status === 'RUNNING' ||
							job.status === 'WAITING' ||
							(job.status === 'UNKNOWN' && moment().diff(subDate, 'minutes') < 60);
					}
					function getElementTypeLabel(type) {
						if (isEmpty(type)) {
							return type;
						}
						let txtType = getTypeLabelFromHdhType(type)
						return gettextCatalog.getString(txtType);
					};
				},
			];

			return {
				restrict: 'E',

				scope: {
					datamodel: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl: './src/components/directives/job/jobs/jobs.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					$(element).modal({
						show: false,
						keyboard: attrs.keyboard,
						backdrop: attrs.backdrop,
					});
					$(element).on('hidden.bs.modal', function () {
						scope.$apply(function () {
							scope.vm.datamodel.showJobs = false;
						});
					});
					$(element).on('shown.bs.modal', function () {
						scope.$apply(function () {
							scope.onlyRunning = true;
							scope.init();
						});
					});

					scope.$watch(
						function () {
							return scope.vm.datamodel.updateList;
						},
						function (value) {
							scope.updateJobs(value);
						}
					);

					scope.$watch(
						function () {
							return scope.vm.datamodel.showJobs;
						},
						function (value) {
							scope.vm.element = element;
							if (value == true) {
								$(element).modal('show');
							} else {
								$(element).modal('hide');
							}
						}
					);
				},
			};
		},
	]);
})();
