(function () {
	'use strict';

	angular.module('dcApp').component('projectExposuresRootCode', {
		bindings: {
			root: '=',
			invalid: '=?',
		},
		templateUrl:
			'./src/components/directives/projectExposuresCode/projectExposuresRootCode.html',
		controller: [
			'$log',
			'$scope',
			'ProjectService',
			function ($log, $scope, ProjectService) {
				var vm = this;
				vm.duplicatedExposuresRootCode = false;

				$scope.$watch(
					'vm.root',
					function (exposuresRootCode) {
						if (exposuresRootCode) {
							ProjectService.isExposuresRootCodeUnique(exposuresRootCode)
								.then((response) => {
									vm.duplicatedExposuresRootCode = !response.data;
									$scope.$emit(
										'importProjectDetails:validity:rootCode',
										response.data
									);
								})
								.catch((error) => {
									$log.error(error);
								});
						}
					},
					true
				);
			},
		],
		controllerAs: 'vm',
		link: function (scope, element, attrs) {
			// Initialization logic
			initElementForDirective(element, attrs, function () {});

			// Watch logic
			watchElementForDirective(
				element,
				scope,
				function () {},
				function () {},
				true
			);
		},
	});
})();
