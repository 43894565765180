(function () {
	'use strict';

	angular.module('dcApp').factory('DashboardService', [
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'$q',
		'DateService',
		function ($http, $rootScope, API_BASE_URL_BACKEND, $q, DateService) {
			var DashboardService = {};

			DashboardService.getDashboards = function (excludePublic, excludeInactive) {
				var url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('DASHBOARDS_GET_ALL');
				url = excludePublic ? url + '?excludePublic=true' : url + '?excludePublic=false'
				url = excludeInactive ? url + '&excludeInactive=true' : url + '&excludeInactive=false'
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			DashboardService.getDashboard = function (dashboardId) {
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('DASHBOARDS_GET');
				url = url.replace('{id}', dashboardId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			DashboardService.createDashboard = function (dashboard) {
				var deferred = $q.defer();
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('DASHBOARDS_CREATE');
				$http.post(url, dashboard).then(
					function (response) {
						var results = {};
						results.data = response.data;
						results.headers = response.headers();
						results.status = response.status;
						results.config = response.config;
						deferred.resolve(results);
					},
					function (err) {
						deferred.reject(err);
					}
				);
				return deferred.promise;
			};

			DashboardService.editDashboard = function (dashboardId, dashboard) {
				var deferred = $q.defer();
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('DASHBOARDS_EDIT');
				url = url.replace('{id}', dashboardId);
				$http.put(url, dashboard).then(
					function (response) {
						var results = {};
						results.data = response.data;
						results.headers = response.headers();
						results.status = response.status;
						results.config = response.config;
						deferred.resolve(results);
					},
					function (err) {
						deferred.reject(err);
					}
				);
				return deferred.promise;
			};

			DashboardService.deleteDashboard = function (dashboardId) {
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('DASHBOARDS_DELETE');
				url = url.replace('{id}', dashboardId);
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			DashboardService.deleteDashboards = function (ids) {
				var url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('DASHBOARDS_DELETE_BULK');
				if (Array.isArray(ids)) {
					url = url + '?ids=' + ids[0];
					for (let i = 1; i < ids.length; i++) {
						url = url + '&ids=' + ids[i];
					}
				} else {
					url = url + '?ids=' + ids;
				}
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			DashboardService.editDashboardConfig = function (dashboardId, config) {
				var deferred = $q.defer();
				var url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('DASHBOARDS_EDIT_CONFIG');
				url = url.replace('{id}', dashboardId);
				$http.put(url, config).then(
					function (response) {
						var results = {};
						results.data = response.data;
						results.headers = response.headers();
						results.status = response.status;
						results.config = response.config;
						deferred.resolve(results);
					},
					function (err) {
						deferred.reject(err);
					}
				);
				return deferred.promise;
			};

			DashboardService.getDashboardConfig = function (dashboardId) {
				var url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('DASHBOARDS_GET_CONFIG');
				url = url.replace('{id}', dashboardId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			DashboardService.getCenteringOptions = function () {
				var url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('OPTIONS_HD_CENTERING');
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			DashboardService.centeringDateToISO = function(centering) {
				if (centering && centering.value) {
					if (centering.value.date_value) {
						centering.value.date_value = centering.value.isoDateValue ? centering.value.isoDateValue
						: DateService.dateStringToISO(centering.value.date_value);
						delete centering.value.isoDateValue;
					}
					if (centering.value.between_date_value1) {
						centering.value.between_date_value1 = centering.value.betweenIsoDateValue1 ? centering.value.betweenIsoDateValue1
            						: DateService.dateStringToISO(centering.value.between_date_value1);
            delete centering.value.betweenIsoDateValue1;
					}
					if (centering.value.between_date_value2) {
						centering.value.between_date_value2 = centering.value.betweenIsoDateValue2 ? centering.value.betweenIsoDateValue2
            						: DateService.dateStringToISO(centering.value.between_date_value2);
            delete centering.value.betweenIsoDateValue2;
					}
					if (centering.value.date_without_time_value) {
						centering.value.date_without_time_value = centering.value.isoDateWithoutTimeValue ? centering.value.isoDateWithoutTimeValue
						: DateService.dateStringToISO(centering.value.date_without_time_value);
						delete centering.value.isoDateWithoutTimeValue;
					}
					if (centering.value.between_date_without_time_value1) {
						centering.value.between_date_without_time_value1 = centering.value.betweenIsoDateWithoutTimeValue1 ? centering.value.betweenIsoDateWithoutTimeValue1
												: DateService.dateStringToISO(centering.value.between_date_without_time_value1);
						delete centering.value.betweenIsoDateWithoutTimeValue1;
					}
					if (centering.value.between_date_without_time_value2) {
						centering.value.between_date_without_time_value2 = centering.value.betweenIsoDateWithoutTimeValue2 ? centering.value.betweenIsoDateWithoutTimeValue2
												: DateService.dateStringToISO(centering.value.between_date_without_time_value2);
						delete centering.value.betweenIsoDateWithoutTimeValue2;
					}
					if (centering.slider_date_max) {
						centering.slider_date_max = centering.sliderIsoDateMax ? centering.sliderIsoDateMax
						: DateService.dateStringToISO(centering.slider_date_max);
						delete centering.sliderIsoDateMax;
					}
					if (centering.slider_date_min) {
						centering.slider_date_min = centering.sliderIsoDateMin ? centering.sliderIsoDateMin
						: DateService.dateStringToISO(centering.slider_date_min);
						delete centering.sliderIsoDateMin;
					}
				}
			}

			DashboardService.centeringRelativeDateCalculator = function (date, increment, unit) {
				const momentUnit = getMomentUnit(unit);
				if (!date || !unit || !increment) {
					return null;
				}

				return moment(date).add(increment, momentUnit).toISOString();
			};

			DashboardService.generatePage = function (inc) {
				const pageNumber = isEmpty(inc) ? 1 : inc + 1;
				return {
								id: uuidv4(),
								title: "Page" + pageNumber,
								font_color: "#000000",
								background_color: "#ffffff"
				};
			};

			function getMomentUnit(enumValue) {
        switch (enumValue) {
          case "millisecond":
            return "milliseconds";
          case "second":
            return "seconds";
          case "minute":
            return "minutes";
          case "hour":
            return "hours";
				 	case "dayOfWeek":
          case "day":
            return "days";
          case "month":
            return "months";
          case "quarter":
            return "quarters";
          case "year":
            return "years";
          default:
            return null;
        }
      }

			return DashboardService;
		},
	]);
})();
