'use strict';

angular
	.module('dcApp')
	.controller('DashboardListController', [
		'$timeout',
		'$scope',
		'$state',
		'$rootScope',
		'toaster',
		'DashboardService',
		'GRIDSTER_OPTIONS',
		'$q',
		'PermissionService',
		'API_BASE_URL_BACKEND',
		'gettextCatalog',
		'DcElementListService',
		function (
			$timeout,
			$scope,
			$state,
			$rootScope,
			toaster,
			DashboardService,
			GRIDSTER_OPTIONS,
			$q,
			PermissionService,
			API_BASE_URL_BACKEND,
			gettextCatalog,
			DcElementListService
		) {
			let dataGirdColumnName = {};
			dataGirdColumnName.label = gettextCatalog.getString('Libellé');
			dataGirdColumnName.tags = gettextCatalog.getString('Tags');
			dataGirdColumnName.updateDate = gettextCatalog.getString(
				'Date de mise à jour'
			);
			dataGirdColumnName.actions = gettextCatalog.getString('Actions');
			dataGirdColumnName.code = gettextCatalog.getString('Code');
			dataGirdColumnName.creationDate =
				gettextCatalog.getString('Date de création');
			dataGirdColumnName.description = gettextCatalog.getString('Description');

			let toasterPopSuccess = gettextCatalog.getString('Succès');
			let toasterPopDeleteTableau = gettextCatalog.getString(
				'Suppression du Tableau de bord effectuée'
			);
			let toasterPopDeleteTableaux = gettextCatalog.getString(
				'Suppression des tableaux de bord effectuée'
			);

			$scope.accessToken = window._keycloak.token;
			$scope.API_BASE_URL_BACKEND = API_BASE_URL_BACKEND;
			$scope.checkPermissionData = {};
			$scope.currentProjectId = $rootScope.currentProjectId;
			$scope.showCurrentProjectOnly = true;
			$scope.activeElementsOnly = false;
			// current / previous tab
			$scope.previousTab;
			$scope.currentTab;

			PermissionService.haveGlobalPermission('dashboard-create').then(function (
				response
			) {
				$scope.create_authorised = response.data;
			});

			$scope.dashboardView = {};

			$scope.switchToView = function () {
				$scope.selectedIndex = 0;
				viewDash();
				$scope.viewMode = true;
			};

			$scope.editViewedDashboard = function () {
				$scope.editDashboard($scope.dashboards[$scope.selectedIndex]);
			};

			$scope.showNext = function () {
				return (
					$scope.dashboards &&
					$scope.dashboards.length > $scope.selectedIndex + 1
				);
			};

			$scope.showPrevious = function () {
				return $scope.dashboards && $scope.selectedIndex - 1 > -1;
			};

			$scope.nextDashboard = function () {
				$scope.selectedIndex = $scope.selectedIndex + 1;
				viewDash();
			};

			$scope.previousDashboard = function (i) {
				$scope.selectedIndex = $scope.selectedIndex - 1;
				viewDash();
			};

			var viewDash = function () {
				$scope.dashboardView.ready = false;
				$scope.dashboardViewLib = $scope.dashboards[$scope.selectedIndex].lc;
				$scope.dashboardView.dashboardId =
					$scope.dashboards[$scope.selectedIndex].id;
				$timeout(function () {
					$scope.dashboardView.ready = true;
				}, 200);
				if ($scope.dashboardView.reinit) {
					$scope.dashboardView.reinit();
				}
			};

			$scope.createDashboard = function () {
				$state.go('dashboards-new', {});
			};

			$scope.deleteElementData = {};
			$scope.doShowDeleteModal = function (count) {
				$scope.deleteElementData = {
					label: $scope.objToDelete ? $scope.objToDelete.lc : undefined,
					doDelete: $scope.bulkDelete
						? $scope.deleteBulkDashboards
						: $scope.deleteDashboard,
					showConfirmDeleteElement: true,
					bulk: $scope.bulkDelete,
					count: count,
				};
			};
			$scope.bulkDelete = false;
			$scope.showDeleteModal = function (obj) {
				$scope.objToDelete = obj;
				$scope.bulkDelete = false;
				$scope.checkPermissionData.elementId = obj.id;
				$scope.checkPermissionData.type = 'dashboard';
				$scope.checkPermissionData.action = 'delete';
				$scope.checkPermissionData.actionIfPermitted = $scope.doShowDeleteModal;
				$scope.checkPermissionData.showPermissionCheck = true;
			};

			$scope.deleteBulkDashboardsModal = function () {
				$scope.bulkDelete = true;
				$scope.doShowDeleteModal($scope.selectedElems.length);
			};

			$scope.deleteBulkDashboards = function () {
				let ids = _.map($scope.selectedElems, function (con) {
					return con.id;
				});
				DashboardService.deleteDashboards(ids)
					.then(function () {
						if ($scope.dashboard_authorised) {
							initDashboards();
						}
						toaster.pop('success', toasterPopSuccess, toasterPopDeleteTableaux);
					})
					.catch(function (e) {
						if (
							e &&
							e.data &&
							e.data.code == '40' &&
							$scope.dashboard_authorised
						) {
							initDashboards();
						}
					});
			};

			$scope.editDashboard = function (obj) {
				$scope.objToEdit = obj;
				$scope.checkPermissionData.elementId = obj.id;
				$scope.checkPermissionData.type = 'dashboard';
				$scope.checkPermissionData.action = 'edit';
				$scope.checkPermissionData.actionIfPermitted = $scope.goToEdit;
				$scope.checkPermissionData.showPermissionCheck = true;
			};

			$scope.goToEdit = function () {
				$state.go('dashboards-edit', { id: $scope.objToEdit.id });
			};

			$scope.viewDashboard = function (obj) {
				$state.go('dashboards-view', { id: obj.id, mode: 'visualisation' });
			};

			$scope.deleteDashboard = function () {
				DashboardService.deleteDashboard($scope.objToDelete.id).then(function (
					response
				) {
					toaster.pop('success', toasterPopSuccess, toasterPopDeleteTableau);
					initDashboards();
				});
			};

			function getListDashboards() {
				var deferred = $q.defer();
				DashboardService.getDashboards(
					$scope.showCurrentProjectOnly,
					$scope.activeElementsOnly
				).then(
					function (response) {
						deferred.resolve(response.data);
					},
					function (err) {
						deferred.reject(err);
					}
				);

				return deferred.promise;
			}

			let yesTra = gettextCatalog.getString('Oui');
			let noTra = gettextCatalog.getString('Non');
			$scope.getGridData = function () {
				$scope.showGrid = false;
				$scope.viewMode = false;
				getListDashboards().then(function (content) {
					if (content !== undefined) {
						$scope.dashboards = [];
						$scope.tags = [];
						var tags = [];
						for (var r in content) {
							$scope.dashboards.push({
								id: content[r].id,
								code: content[r].metadata.code,
								lc: content[r].metadata.label,
								desc: content[r].metadata.description,
								active: content[r].metadata.actif ? yesTra : noTra,
								icon_id: content[r].metadata.icon_id,
								tags: content[r].metadata.tags,
								tagAsArray: $rootScope.mapTags(content[r].metadata.tags),
								creationDate: $rootScope.getDateTimeWithPattern(
									content[r].metadata.creation_date
								),
								updateDate: $rootScope.getDateTimeWithPattern(
									content[r].metadata.update_date
								),
								createdBy: content[r].metadata.created_by,
								updatedBy: content[r].metadata.updated_by,
								project: content[r].metadata.project,
							});
							tags = _.union(tags, content[r].metadata.tags);
						}

						for (var i in tags) {
							if (_.find($scope.tags, { id: tags[i].id }) == null) {
								$scope.tags.push({ id: tags[i].id, label: tags[i].code });
							}
						}

						$scope.gridData = angular.copy($scope.dashboards);
						$scope.dataGridOptions.dataSource = $scope.gridData;
						$scope.showGrid = true;
					}
				});
			};

			$scope.disableAllSelectedElems = true;

			function initDashboards() {
				PermissionService.haveGlobalPermission('dashboard').then(function (
					response
				) {
					$scope.dashboard_authorised = response.data;
					if ($scope.dashboard_authorised) {
						$scope.getGridData();
						$scope.disableAllSelectedElems = true;
					}
				});
			}

			function init() {
				$scope.active = true;
			}

			$scope.moveOrCopyData = {};
			$scope.moveOrCopyElementToProject = function () {
				let connectorIds = _.map($scope.selectedElems, function (con) {
					return con.id;
				});
				$scope.moveOrCopyData = {
					elements: connectorIds,
					type: 'dashboard',
					showMoveCopy: true,
				};
			};
			$scope.moveOrCopyOneElementToProject = function (elementId) {
				$scope.moveOrCopyData = {
					elements: [elementId],
					type: 'dashboard',
					showMoveCopy: true,
				};
			};

			init();
			// Get DxGrid dataGridOptions
			DcElementListService.getDcElementDxList($scope, 'dashboard', false);
			initDashboards();
		},
	])
	.controller('DashboardEditController', [
		'$q',
		'$http',
		'API_BASE_URL_BACKEND',
		'$stateParams',
		'$state',
		'$timeout',
		'$scope',
		'$rootScope',
		'toaster',
		'DashboardService',
		'GenericService',
		'PAGINATIONS_SIZES',
		'PAGINATIONS_SIZE',
		'$window',
		'PictogrammeService',
		'hdSourceService',
		'MapService',
		'TimeLineService',
		'ChartService',
		'GridService',
		'MediaService',
		'gettextCatalog',
		'PublicationService',
		'UserGroupService',
		'PermissionService',
		'GRIDSTER_OPTIONS',
		'DateService',
		'ModalService',
		function (
			$q,
			$http,
			API_BASE_URL_BACKEND,
			$stateParams,
			$state,
			$timeout,
			$scope,
			$rootScope,
			toaster,
			DashboardService,
			GenericService,
			PAGINATIONS_SIZES,
			PAGINATIONS_SIZE,
			$window,
			PictogrammeService,
			hdSourceService,
			MapService,
			TimeLineService,
			ChartService,
			GridService,
			MediaService,
			gettextCatalog,
			PublicationService,
			UserGroupService,
			PermissionService,
			GRIDSTER_OPTIONS,
			DateService,
			ModalService
		) {
			// Help
			$scope.helpTitleTra = gettextCatalog.getString('Aide Tableaux de Bord');
			$scope.gridsterPushingAutoSwitchLimit = 15;
			let dataGirdColumnName = {};
			dataGirdColumnName.label = gettextCatalog.getString('Libellé');
			dataGirdColumnName.type = gettextCatalog.getString('Type');
			dataGirdColumnName.description = gettextCatalog.getString('Description');

			let toasterPopSuccess = gettextCatalog.getString('Succès');
			let toasterPopSaveTableau = gettextCatalog.getString(
				'Enregistrement des paramètres effectué'
			);
			let toasterPopCreationTableau = gettextCatalog.getString(
				'Création du Tableau de bord effectuée'
			);
			let toasterPopEditTableau = gettextCatalog.getString(
				'Modification du Tableau de bord effectuée'
			);
			let toasterPopDeleteTableau = gettextCatalog.getString(
				'Suppression du Tableau de bord effectuée'
			);

			let toasterPopFillParameters = gettextCatalog.getString(
				'Veuillez remplir les paramètres obligatoires'
			);
			let toasterPopFillUpdatedCenteringParameters = gettextCatalog.getString(
				'Veuillez remplir les paramètres Centrage alimenté'
			);
			let toasterPopErrorParameters = gettextCatalog.getString(
				'La borne supérieure doit être supérieure à la borne inférieure'
			);
			let toasterError = gettextCatalog.getString('Erreur');
			let toasterEmptyEditor = gettextCatalog.getString(
				'Editeur de texte vide'
			);
			$scope.onSourceOrigin = gettextCatalog.getString(
				"Sur l'origine de la Source"
			);
			$scope.onSourceOutput = gettextCatalog.getString(
				'Après traitement de la source'
			);

			$scope.accessToken = window._keycloak.token;
			$scope.checkPermissionData = {};
			$scope.metadata = {};
			$scope.metadata.actif = true;
			$scope.metadata.tags = [];
			$scope.permissionData = {};
			$scope.API_BASE_URL_BACKEND = API_BASE_URL_BACKEND;
			$scope.widgetData = {};
			$scope.iconsSelector = {};
			$scope.elementGraphData = {};
			$scope.dataModel = {};
			$scope.selectedPage = {id: ''};
			$scope.centeringDateSelectorMode = [
			{"code": "ABSOLUTE", "label": gettextCatalog.getString("centering.date.selector.mode.absolute")},
			 {"code": "RELATIVE", "label": gettextCatalog.getString("centering.date.selector.mode.relative")}
			]

			let CENTERING_MAX_ELEMENTS = 12;

			$scope.dashboardView = {};
			$scope.tagBox = {
				displayExpr: 'code',
				valueExpr: 'id',
				searchEnabled: false,
				editEnabled: false,
				tagTemplate: 'tagTemplate',
			};

			$scope.getTagBox = function (list) {
				$scope.tagBox.value = list.data.tags;
				return $scope.tagBox;
			};

			$scope.redirectionToList = function () {
				$('#confirmationModal').modal('hide');
				$timeout(function () {
					$state.go('dashboards');
				}, 300);
			};

			$scope.editElement = function (element) {
				let subType = element.grid_type
					? element.grid_type
					: element.chart_type
					? element.chart_type
					: undefined;
				subType = subType
					? subType
					: element.media_type
					? element.media_type
					: undefined;

				let url;
				switch (element.type) {
					case 'MAP':
						url = $state.href('maps-edit', {
							id: element.hd_id,
						});
						$window.open(url, '_blank');
						return;
					case 'CHART':
						url = $state.href('charts-edit', {
							type: subType,
							id: element.hd_id,
						});
						$window.open(url, '_blank');
						return;
					case 'GRID':
						url = $state.href('grids-edit', {
							type: subType,
							id: element.hd_id,
						});
						$window.open(url, '_blank');
						return;
					case 'MEDIA':
						url = $state.href('medias-edit', {
							type: subType,
							id: element.hd_id,
						});
						$window.open(url, '_blank');
						return;
					case 'TIMELINE':
						url = $state.href('timelines-edit', {
							id: element.hd_id,
						});
						$window.open(url, '_blank');
						return;
					default:
						return element.type;
				}
			};

			$scope.dataModel.redirectionToList = $scope.redirectionToList;

			$scope.deleteElementData = {};
			$scope.doShowDeleteModal = function () {
				$scope.deleteElementData = {
					label:
						$scope.currentDashboard && $scope.currentDashboard.metadata
							? $scope.currentDashboard.metadata.label
							: undefined,
					doDelete: $scope.deleteDashboard,
					showConfirmDeleteElement: true,
				};
			};

			$scope.showDeleteModal = function () {
				$scope.checkPermissionData.elementId = $scope.currentDashboard.id;
				$scope.checkPermissionData.type = 'dashboard';
				$scope.checkPermissionData.action = 'delete';
				$scope.checkPermissionData.actionIfPermitted = $scope.doShowDeleteModal;
				$scope.checkPermissionData.showPermissionCheck = true;
			};

			$scope.deleteDashboard = function () {
				DashboardService.deleteDashboard($scope.currentDashboard.id).then(
					function (response) {
						toaster.pop('success', toasterPopSuccess, toasterPopDeleteTableau);
						$('#deleteModal').modal('hide');
						$timeout(function () {
							$state.go('dashboards');
						}, 300);
					}
				);
			};

			var gridsterOptions = angular.copy(GRIDSTER_OPTIONS);

			$scope.showHdhGraph = function () {
				$scope.elementGraphData.centeredElementLabel =
					$scope.currentDashboard.metadata.label;
				$scope.elementGraphData.centeredElementType = 'DASHBOARD';
				$scope.elementGraphData.centeredElementId = $scope.currentDashboard.id;
				$scope.elementGraphData.showHdhElementGraph = true;
			};

			$scope.showPermissions = function () {
				$scope.checkPermissionData.elementId = $scope.currentDashboard.id;
				$scope.checkPermissionData.type = 'dashboard';
				$scope.checkPermissionData.action = 'permissions';
				$scope.checkPermissionData.actionIfPermitted = $scope.doShowPermissions;
				$scope.checkPermissionData.showPermissionCheck = true;
			};

			$scope.doShowPermissions = function () {
				$scope.permissionData.metadataId = $scope.currentDashboard.metadata.id;
				$scope.permissionData.type = 'dashboard';
				$scope.permissionData.elementId = $scope.currentDashboard.id;
				$scope.permissionData.lib = $scope.currentDashboard.metadata.label;
				$scope.permissionData.showElementPermissions = true;
			};

			$scope.showConfirmationModal = function () {
				$('#confirmationModal').modal('show');
			};

			var initConstants = function (data) {
				$scope.centeringOptions = data;
			};

			$scope.getElementCenterings = function (centering) {
				return getAllCenteringMap(
					$scope.dashboardConfig.elements,
					centering.uuid
				);
			};

			$scope.operatorRequireValues = function (operator) {
				return (
					operator !== 'is_null' &&
					operator !== 'is_not_null' &&
					operator !== 'is_empty' &&
					operator !== 'is_not_empty'
				);
			};

			function getUnusedColor() {
				let usedColors = _.map(
					$scope.dashboardConfig.global.centerings,
					function (elm) {
						return elm.color;
					}
				);
				let unusedColors = _.difference($scope.colors, usedColors);

				return unusedColors && unusedColors[0] ? unusedColors[0] : 'purple';
			}

			$scope.showConfig = function (showVisualisationIfNoElement) {
				delete $scope.dashboardView.dashboardId;
				delete $scope.gridsterOptions;
				delete $scope.isConfigMode;
				$scope.configSaved = false;

				DashboardService.getDashboardConfig($stateParams.id).then(function (
					response
				) {
					$scope.dashboardConfig = response.data;
					//if no element is available => open visualisation tab
					// if $stateParams.mode == 'visualisation' and no previous tab load
					if ($stateParams.mode == 'visualisation' && !$scope.previousTab) {
						$('.nav-tabs a[data-target="#visualization"]').tab('show');
						addTabChangeListener();
						showVisualization();
					} else {
						$scope.currentTab = 'configTab';
						DashboardService.getCenteringOptions().then(function (
							optionsResponse
						) {
							initConstants(optionsResponse.data);
							if (!$scope.dashboardConfig.global) {
								$scope.dashboardConfig.global = {};
							}

							if (!$scope.dashboardConfig.global.centerings) {
								$scope.dashboardConfig.global.centerings = [];
							}

							for (
								var i = $scope.dashboardConfig.global.centerings.length;
								i < CENTERING_MAX_ELEMENTS;
								i++
							) {
								// colors
								let colorToUse = $scope.colors[i];
								if (
									!$scope.dashboardConfig.global.centerings[i] ||
									!$scope.dashboardConfig.global.centerings[i].color
								) {
									colorToUse = getUnusedColor();
								}
								$scope.dashboardConfig.global.centerings.push({
									autocomplete_mode: 'simple',
									type: $scope.centeringOptions.centering_value_types[0].code,
									label: '',
									color: colorToUse,
									operator: 'equal',
								});
							}

							for (var e in $scope.dashboardConfig.elements) {
								$scope.dashboardConfig.elements[e].row =
									$scope.dashboardConfig.elements[e].config.row;
								$scope.dashboardConfig.elements[e].col =
									$scope.dashboardConfig.elements[e].config.col;
								$scope.dashboardConfig.elements[e].head_band =
									$scope.dashboardConfig.elements[e].config.head_band;
								$scope.dashboardConfig.elements[e].border =
									$scope.dashboardConfig.elements[e].config.border;
								$scope.dashboardConfig.elements[e].scroll =
									$scope.dashboardConfig.elements[e].config.scroll;
								$scope.dashboardConfig.elements[e].label_visible =
									$scope.dashboardConfig.elements[e].config.label_visible;
								$scope.dashboardConfig.elements[e].sizeY =
									$scope.dashboardConfig.elements[e].config.size_y;
								delete $scope.dashboardConfig.elements[e].size_y;
								$scope.dashboardConfig.elements[e].sizeX =
									$scope.dashboardConfig.elements[e].config.size_x;
								delete $scope.dashboardConfig.elements[e].size_x;
								$scope.dashboardConfig.elements[e].centering =
									$scope.dashboardConfig.elements[e].config.centering;
								$scope.dashboardConfig.elements[e].centering_modes =
									$scope.dashboardConfig.elements[e].config.centering_modes;
								$scope.dashboardConfig.elements[e].titleConfig =
									$scope.dashboardConfig.elements[e].config.title_config;
								delete $scope.dashboardConfig.elements[e].title_config;
								$scope.dashboardConfig.elements[e].page =
															$scope.dashboardConfig.elements[e].config.page;
								delete $scope.dashboardConfig.elements[e].config.page;
							}

							if (
								$scope.dashboardConfig.elements.length + 1 >
								$scope.gridsterPushingAutoSwitchLimit
							) {
								gridsterOptions.pushing = false;
							}

							$scope.gridsterOptions = gridsterOptions;
							$scope.isConfigMode = true;
							addTabChangeListener();
							$timeout(function () {
									updatePageSelection();
							}, 300);
						});
					}
				});
			};

			$scope.saveConfig = function (afterSave) {
				// if not previous tab is conf return
				if (
					$scope.previousTab !== 'configTab' &&
					$scope.currentTab !== 'configTab'
				) {
					if (afterSave) {
						afterSave();
					}
					return;
				}
				var config = angular.copy($scope.dashboardConfig);
				delete config.filteredElements;
				var elements = [];
				for (var g in config.elements) {
					let ele = {
						id: config.elements[g].id,
						uuid: config.elements[g].uuid,
						label: config.elements[g].label,
						type: config.elements[g].type
					};
					ele.config = {
						label_visible: config.elements[g].label_visible,
						col: config.elements[g].col,
						row: config.elements[g].row,
						size_x: config.elements[g].sizeX,
						size_y: config.elements[g].sizeY,
						border: config.elements[g].border,
						head_band: config.elements[g].head_band,
						scroll: config.elements[g].scroll,
						title_config: config.elements[g].titleConfig,
						page: config.elements[g].page
					};
					if (ele.type == 'HTML_EDITOR') {
						ele.html_editor = config.elements[g].html_editor;
					} else {
						ele.config.centering_modes = config.elements[g].centering_modes;
						ele.hd_id = config.elements[g].hd_id;
						ele.config.centering = config.elements[g].centering;
					}
					elements.push(ele);
				}

				config.elements = elements;
				var centerings = [];
				for (var c in config.global.centerings) {
					if (config.global.centerings[c].uuid) {
						let centering = config.global.centerings[c];
						DashboardService.centeringDateToISO(centering);
						centerings.push(centering);
					}
				}
				config.global.centerings = centerings;
				DashboardService.editDashboardConfig($stateParams.id, config).then(
					function (response) {
						//$scope.configSaved = true;
						toaster.pop('success', toasterPopSuccess, toasterPopSaveTableau);
						if (afterSave) {
							afterSave();
						}
					}
				);
			};

			$scope.setElementCentering = function (centering, element) {
				element.selectedCentering = centering;
				// get operator label
				let ops = $scope.getOperators(centering);
				let usedOperator = element.selectedCentering.operator
					? _.find(ops, function (el) {
							return el.code === element.selectedCentering.operator;
					  })
					: undefined;
				element.selectedCentering.operatorTranslation = usedOperator
					? usedOperator.label
					: element.selectedCentering.operator;
				hdSourceService
					.getSourcesByElement(element.type, element.hd_id)
					.then(function (response) {
						element.sources = response.data;
						element.tmpCentering = angular.copy(element.centering);
						element.tmpCenteringModes = angular.copy(element.centering_modes);
						element.showCentering = true;
						if (!element.tmpCenteringModes) {
						}
						if (!element.tmpCenteringModes[centering.uuid]) {
							element.tmpCenteringModes[centering.uuid] = 'BEFORE_OPERATION';
						}
						fetchSourceColumns(element, 0);
					});
			};

			var fetchSourceColumns = function (element, i) {
				if (!element.sources[i]) {
					showElementCentering(element);
					return;
				}
				let ignoreOperations =
					element.tmpCenteringModes &&
					element.tmpCenteringModes[element.selectedCentering.uuid] ==
						'BEFORE_OPERATION';
				hdSourceService
					.getSourceColumns(
						element.sources[i].id,
						element.type,
						element.hd_id,
						ignoreOperations
					)
					.then(function (response) {
						element.sources[i].columns = _.filter(
							response.data,
							function (item) {
								return filterOnColumnType(item, element.selectedCentering.type);
							}
						);
						fetchSourceColumns(element, i + 1);
					});
			};

			var showElementCentering = function (element) {
				if (!element.tmpCentering[element.selectedCentering.uuid]) {
					element.tmpCentering[element.selectedCentering.uuid] = {};
				}
			};

			var filterOnColumnType = function (column, type) {
				if (type == 'string' && column.type == 'string') {
					return true;
				}
				if (type == 'date' && column.type == 'date') {
					return true;
				}
				if (
					type == 'numeric' &&
					(column.type == 'decimal' ||
						column.type == 'integer' ||
						column.type == 'big_integer')
				) {
					return true;
				}
			};

			$scope.cancelElementCentering = function (element) {
				reinitElementCenteringTmpData(element);
			};

			$scope.saveElementCentering = function (element) {
				let oneColumnIsSelected = false;
				for (let s in element.sources) {
					if (
						element.tmpCentering[element.selectedCentering.uuid] &&
						element.tmpCentering[element.selectedCentering.uuid][
							element.sources[s].id
						]
					) {
						oneColumnIsSelected = true;
						break;
					}
				}
				if (!oneColumnIsSelected) {
					toaster.pop(
						'error',
						gettextCatalog.getString('Veuillez sélectionner une colonne')
					);
					return;
				}
				element.centering_modes = element.tmpCenteringModes;
				element.centering = element.tmpCentering;
				reinitElementCenteringTmpData(element);
			};

			$scope.deleteElementCentering = function (element) {
				delete element.centering[element.selectedCentering.uuid];
				delete element.centering_modes[element.selectedCentering.uuid];
				reinitElementCenteringTmpData(element);
			};

			$scope.changeCenteringMode = function (element) {
				delete element.tmpCentering[element.selectedCentering.uuid];
				fetchSourceColumns(element, 0);
			};

			$scope.changeAutocompleteMode = function (element) {
				if ($scope.selectedCentering.autocomplete_mode == 'simple') {
					delete $scope.selectedCentering.autocomplete_element;
					delete $scope.selectedCentering.autocomplete_source;
					delete $scope.selectedCentering.autocomplete_column;
				}
			};

			$scope.elementCenteringIsOpened = function () {
				for (let e in $scope.dashboardConfig.elements) {
					if ($scope.dashboardConfig.elements[e].showCentering) {
						return true;
					}
				}
				return false;
			};

			var reinitElementCenteringTmpData = function (element) {
				delete element.showCentering;
				delete element.selectedCentering;
				delete element.sources;
				delete element.tmpCentering;
				delete element.invalidCentering;
				delete element.message;
			};

			$scope.addElementCentering = function (centering, element) {
				if (!element.centering) {
					element.centering = {};
				}
				element.centering[centering.uuid] = {};
			};

			$scope.colors = [
				'#679EC5',
				'blue',
				'red',
				'gray',
				'#E3A857',
				'green',
				'#008080',
				'#808000',
				'#FF00FF',
				'#800000',
				'#00FFFF',
				'#7B68EE',
			];

			$scope.editCentering = function (centering) {
				$scope.selectedCentering = angular.copy(centering);
				if (centering.type === 'date' && !centering.value.without_time) {
					$scope.type = gettextCatalog.getString('Date avec heures');
					$scope.selectedCentering.type = 'date';
					$scope.selectedCentering.value.without_time = false;
				} else if (centering.type === 'numeric') {
					$scope.type = gettextCatalog.getString('Numérique');
					$scope.selectedCentering.type = 'numeric';
				} else if (centering.type === 'date' && centering.value.without_time) {
					$scope.type = gettextCatalog.getString('Date sans heures');
					$scope.selectedCentering.type = 'date';
					$scope.selectedCentering.value.without_time = true;
				} else if (centering.type === 'string') {
					$scope.type = gettextCatalog.getString('Caractère');
					$scope.selectedCentering.type = 'string';
				}
				$scope.selectedCenteringIndex =
					$scope.dashboardConfig.global.centerings.indexOf(centering);
				$scope.selectedCentering.selection_values = _.map(
					$scope.selectedCentering.selection_values,
					function (item) {
						return { code: item };
					}
				);
				if ($scope.selectedCentering.autocomplete_mode == 'reference') {
					$scope.changeAutocompleteElement();
					$scope.changeAutocompleteSource();
				}
			};

			$scope.setTypeOperator = function (type) {
				$scope.type = type;
				if (
					$scope.selectedCentering.hasOwnProperty('value') == false &&
					$scope.type === gettextCatalog.getString('Date sans heures')
				) {
					$scope.selectedCentering.value = { without_time: true };
					$scope.isWithoutTime = true;
				} else if (
					$scope.selectedCentering.hasOwnProperty('value') == false &&
					$scope.type === gettextCatalog.getString('Date avec heures')
				) {
					$scope.selectedCentering.value = { without_time: false };
					$scope.isWithoutTime = false;
				}
				if ($scope.type === gettextCatalog.getString('Date avec heures')) {
					$scope.selectedCentering.type = 'date';
					$scope.selectedCentering.value.without_time = false;
					$scope.isWithoutTime = false;
				} else if ($scope.type === gettextCatalog.getString('Numérique')) {
					$scope.selectedCentering.type = 'numeric';
				} else if (
					$scope.type === gettextCatalog.getString('Date sans heures')
				) {
					$scope.selectedCentering.type = 'date';
					$scope.selectedCentering.value.without_time = true;
					$scope.isWithoutTime = true;
				} else if ($scope.type === gettextCatalog.getString('Caractère')) {
					$scope.selectedCentering.type = 'string';
				}
			};

			$scope.cancelEditCentering = function () {
				reinitCenteringTmpData();
			};

			$scope.deleteCentering = function () {
				setCenteringSelectionValues();
				deleteCenteredElement($scope.selectedCentering);
				$scope.dashboardConfig.global.centerings[
					$scope.selectedCenteringIndex
				] = {
					operator: 'equal',
					autocomplete_mode: 'simple',
					value: {},
					label: '',
					color: $scope.selectedCentering.color,
				};
				reinitCenteringTmpData();
			};

			var deleteCenteredElement = function (centering) {
				for (var e in $scope.dashboardConfig.elements) {
					if (
						$scope.dashboardConfig.elements[e].centering &&
						$scope.dashboardConfig.elements[e].centering[centering.uuid]
					) {
						delete $scope.dashboardConfig.elements[e].centering[centering.uuid];
					}
				}
			};

			var setCenteringSelectionValues = function () {
				$scope.selectedCentering.selection_values = _.filter(
					$scope.selectedCentering.selection_values,
					function (item) {
						return item;
					}
				);
				$scope.selectedCentering.selection_values = _.map(
					$scope.selectedCentering.selection_values,
					function (item) {
						return item.code;
					}
				);
			};

			$scope.changeAutocompleteElement = function () {
				let selectedElement = _.find(
					$scope.dashboardConfig.elements,
					function (item) {
						return item.uuid == $scope.selectedCentering.autocomplete_element;
					}
				);
				if (!selectedElement) {
					return;
				}
				hdSourceService
					.getSourcesByElement(selectedElement.type, selectedElement.hd_id)
					.then(function (response) {
						$scope.selectedElementSources = response.data;
					});
			};

			$scope.changeAutocompleteSource = function () {
				if (!$scope.selectedCentering.autocomplete_source) {
					return;
				}
				let selectedElement = _.find(
					$scope.dashboardConfig.elements,
					function (item) {
						return item.uuid == $scope.selectedCentering.autocomplete_element;
					}
				);
				hdSourceService
					.getSourceColumns(
						$scope.selectedCentering.autocomplete_source,
						selectedElement.type,
						selectedElement.hd_id
					)
					.then(function (response) {
						$scope.selectedSourceColumns = _.filter(
							response.data,
							function (item) {
								return item.type == 'string';
							}
						);
					});
			};

			$scope.reinitValues = function (centering) {
				if (centering.initValue) {
					centering.initValue(centering);
				}
			};

			$scope.changeUpdatedCentering = function () {
				delete $scope.selectedCentering.updated_centering_column;
				delete $scope.selectedCentering.updated_centering_operator;
			};

			var reinitCenteringTmpData = function () {
				delete $scope.selectedCentering;
				delete $scope.selectedCenteringIndex;
				delete $scope.centeringMessage;
				delete $scope.selectedElementSources;
				delete $scope.selectedSourceColumns;
			};

			$scope.saveCentering = function () {
				if (
					!$scope.selectedCentering.label ||
					!$scope.selectedCentering.type ||
					!$scope.selectedCentering.operator
				) {
					$scope.centeringMessage = toasterPopFillParameters;
					return;
				}

				if (
					$scope.selectedCentering.updated_centering &&
					(!$scope.selectedCentering.updated_centering_operator ||
						!$scope.selectedCentering.updated_centering_column)
				) {
					$scope.centeringMessage = toasterPopFillUpdatedCenteringParameters;
					return;
				}

				if (
					$scope.selectedCentering.slider &&
					$scope.selectedCentering.type == 'numeric'
				) {
					if (
						$scope.selectedCentering.slider_min == undefined ||
						$scope.selectedCentering.slider_max == undefined ||
						$scope.selectedCentering.slider_step == undefined
					) {
						$scope.centeringMessage = toasterPopFillParameters;
						return;
					}
					if (
						$scope.selectedCentering.slider_max && $scope.selectedCentering.slider_min >
						$scope.selectedCentering.slider_max
					) {
						$scope.centeringMessage = toasterPopErrorParameters;
						return;
					}
				}

				if (
					$scope.selectedCentering.slider &&
					$scope.selectedCentering.type == 'date' &&
					$scope.selectedCentering.slider_date_mode !== "RELATIVE" &&
					($scope.selectedCentering.operator == 'between' ||
						$scope.selectedCentering.operator == 'not_between')
				) {
					if (
						$scope.selectedCentering.slider_date_min == undefined ||
						$scope.selectedCentering.slider_date_step == undefined
					) {
						$scope.centeringMessage = toasterPopFillParameters;
						return;
					}
					if (
						$scope.selectedCentering.slider_date_max && $scope.selectedCentering.slider_date_min >
						$scope.selectedCentering.slider_date_max
					) {
						$scope.centeringMessage = toasterPopErrorParameters;
						return;
					}
					if (
						$scope.selectedCentering.slider_date_mode === "RELATIVE" &&
						(isEmpty($scope.selectedCentering.slider_date_increment) || isEmpty($scope.selectedCentering.slider_date_step))
					) {
						$scope.centeringMessage = toasterPopFillParameters;
						return;
					}
				}

				if (
					$scope.dashboardConfig.global.centerings[
						$scope.selectedCenteringIndex
					].type !== $scope.selectedCentering.type
				) {
					deleteCenteredElement($scope.selectedCentering);
				}

				if (!$scope.selectedCentering.uuid) {
					$scope.selectedCentering.uuid = generateUuid('_');
					$scope.selectedCentering.value = {};
				}

				if ($scope.selectedCentering.label.length > 200) {
					toaster.pop(
						'error',
						'Erreur',
						gettextCatalog.getString(
							'Le libellé ne doit pas dépasser 200 caractères'
						)
					);
					return;
				}

				setCenteringSelectionValues();
				$scope.selectedCentering.value.without_time = $scope.selectedCentering
					.value.without_time
					? $scope.selectedCentering.value.without_time
					: $scope.isWithoutTime;
				$scope.dashboardConfig.global.centerings[
					$scope.selectedCenteringIndex
				] = $scope.selectedCentering;

				if (
					$scope.dashboardConfig.global.centerings[
						$scope.selectedCenteringIndex
					].initValue
				) {
					$scope.dashboardConfig.global.centerings[
						$scope.selectedCenteringIndex
					].initValue(
						$scope.dashboardConfig.global.centerings[
							$scope.selectedCenteringIndex
						]
					);
				}

				if ($scope.selectedCentering.sliderIsoDateMax) {
					$scope.dashboardConfig.global.centerings[
						$scope.selectedCenteringIndex
					].sliderIsoDateMax = $scope.selectedCentering.sliderIsoDateMax;
					$scope.dashboardConfig.global.centerings[
						$scope.selectedCenteringIndex
					].slider_date_max = $scope.selectedCentering.sliderIsoDateMax;
				}

				if ($scope.selectedCentering.sliderIsoDateMin) {
					$scope.dashboardConfig.global.centerings[
						$scope.selectedCenteringIndex
					].sliderIsoDateMin = $scope.selectedCentering.sliderIsoDateMin;
					$scope.dashboardConfig.global.centerings[
						$scope.selectedCenteringIndex
					].slider_date_min = $scope.selectedCentering.sliderIsoDateMin;
				}

				reinitCenteringTmpData();
			};

			$scope.addDashboardElement = function () {
				if (!$scope.dashboardConfig.elements) {
					$scope.dashboardConfig.elements = [];
				}
				if (
					$scope.dashboardConfig.elements.length + 1 >
					$scope.gridsterPushingAutoSwitchLimit
				) {
					$scope.gridsterOptions.pushing = false;
				}
				$scope.dashboardConfig.elements.push({
					uuid: generateUuid('_'),
					head_band: true,
					border: true,
					scroll: false,
					page: $scope.selectedPage.id
				});
				updatePageSelection();
			};

			$scope.setBorder = function (element) {
				element.border = !element.border;
			};

			$scope.setHeadBand = function (element) {
				element.head_band = !element.head_band;
			};

			$scope.setScroll = function (element) {
				element.scroll = !element.scroll;
			};

			$scope.getOperators = function (centering) {
				if (!$scope.centeringOptions) {
					return;
				}
				if (centering.type == 'string') {
					return $scope.centeringOptions.string_operators;
				} else if (centering.type == 'numeric') {
					return $scope.centeringOptions.numeric_operators;
				} else if (centering.type == 'date') {
					return $scope.centeringOptions.date_operators;
				}
			};

			$scope.getOperatorsForUpdatedCentering = function () {
				if (!$scope.centeringOptions) {
					return;
				}
				// return _.filter($scope.centeringOptions.string_operators, function(item){ return item.code == 'in' ||  item.code == 'not_in'});
				return $scope.centeringOptions.string_operators;
			};

			$scope.getOtherCenterings = function () {
				return _.filter(
					$scope.dashboardConfig.global.centerings,
					function (item) {
						return (
							item.uuid &&
							item.type == 'string' &&
							item.uuid != $scope.selectedCentering.uuid
						);
					}
				);
			};

			var getAvailableTags = function () {
				return new Promise(function (resolve) {
					setTimeout(function () {
						resolve(
							_.map($scope.tags, function (el) {
								return el.label;
							})
						);
					}, 50);
				});
			};

			var lookupTagDataSource = {
				store: new DevExpress.data.CustomStore({
					loadMode: 'raw',
					load: function () {
						return getAvailableTags().then(function (response) {
							return response;
						});
					},
				}),
			};

			var hdElementsGridOptions = {
				paging: {
					enabled: true,
					pageSize: PAGINATIONS_SIZE,
				},
				pager: {
					showPageSizeSelector: true,
					allowedPageSizes: PAGINATIONS_SIZES,
					showInfo: true,
					visible: true,
				},
				filterRow: {
					visible: true,
					applyFilter: 'auto',
				},
				selection: {
					mode: 'single',
				},
				rowAlternationEnabled: true,
				headerFilter: {
					visible: true,
					applyFilter: 'auto',
				},
				showColumnLines: true,
				showRowLines: true,
				allowColumnReordering: true,
				allowColumnResizing: true,
				columnAutoWidth: true,
				showBorders: true,
				columnFixing: {
					enabled: true,
				},
				columns: [
					{
						caption: dataGirdColumnName.label,
						dataField: 'lc',
						width: '35.8%',
						allowReordering: false,
					},
					{
						caption: dataGirdColumnName.type,
						cellTemplate: 'chartElemType',
						width: '11%',
					},
					{
						caption: dataGirdColumnName.description,
						dataField: 'desc',
						width: '29%',
					},
					{
						dataField: 'tags',
						caption: 'Tags',
						cellTemplate: 'tagTemplate',
						allowHeaderFiltering: true,
						allowFiltering: false,
						enableCellEdit: false,
						lookup: {
							dataSource: lookupTagDataSource,
						},
						width: '32%',
						calculateFilterExpression: function (
							filterValue,
							selectedFilterOperation
						) {
							if (!filterValue) return;
							return function (rowData) {
								return _.indexOf(rowData.tagAsArray, filterValue) != -1;
							};
						},
					},
					{
						dataField: 'updateDate',
						width: '20%',
						caption: gettextCatalog.getString('Date de mise à jour'),
						sortOrder: 'desc',
					},
					{
						dataField: 'updatedBy',
						width: '20%',
						caption: gettextCatalog.getString('Dernier modificateur'),
					},
				],
				onSelectionChanged: function (selectedItems) {
					setSelectedHdElement(selectedItems.selectedRowsData[0]);
				},
				onRowDblClick: function (item) {
					setSelectedHdElement(item.data);
					$scope.saveHdElement();
				},
			};

			var setSelectedHdElement = function (data) {
				if (data) {
					$scope.selectedElement.hd_id = data.id;
					$scope.selectedElement.hd_label = data.lc;
					$scope.selectedElement.chart_type = data.chart_type;
					$scope.selectedElement.grid_type = data.grid_type;
				}
			};

			$scope.returnToElements = function () {
				delete $scope.selectedElement;
				delete $scope.showMaps;
				delete $scope.showTimelines;
				delete $scope.showCharts;
				delete $scope.showMedias;
				delete $scope.showGrids;
				delete $scope.htmlEditor;
				delete $scope.selectedType;
				$scope.hideElements = false;
			};

			$scope.saveHdElement = function () {
				if ($scope.selectedType === 'HTML_EDITOR') {
					if (
						!$scope.htmlEditor.html_content ||
						!$scope.htmlEditor.html_content[0]
					) {
						toaster.pop('error', toasterError, toasterEmptyEditor);
						return;
					}
					$scope.selectedElement.html_editor = $scope.htmlEditor;
				}
				$scope.selectedElement.type = $scope.selectedType;
				delete $scope.showMaps;
				delete $scope.showTimelines;
				delete $scope.showCharts;
				delete $scope.showMedias;
				delete $scope.showGrids;
				delete $scope.selectedType;
				delete $scope.htmlEditor;
				$scope.hideElements = false;
			};

			$scope.clear = function (element) {
				delete element.type;
				delete element.hd_id;
				delete element.hd_label;
				delete element.chart_type;
				delete element.media_type;
				delete element.grid_type;
			};

			$scope.setMap = function (element) {
				$scope.selectedType = 'MAP';
				delete $scope.hdElementsGridOptions;
				MapService.getMaps(false, true).then(function (response) {
					fillHdElements(response.data);
				});
				$scope.selectedElement = element;
				$scope.hideElements = true;
				$scope.showMaps = true;
			};

			$scope.setTimeline = function (element) {
				$scope.selectedType = 'TIMELINE';
				delete $scope.hdElementsGridOptions;
				TimeLineService.getTimeLines(false, true).then(function (response) {
					fillHdElements(response.data);
				});
				$scope.selectedElement = element;
				$scope.hideElements = true;
				$scope.showTimelines = true;
			};

			$scope.setChart = function (element) {
				$scope.selectedType = 'CHART';
				delete $scope.hdElementsGridOptions;
				ChartService.getAllCharts(false, true).then(function (response) {
					fillHdElements(response.data, true, false, false);
				});
				$scope.selectedElement = element;
				$scope.hideElements = true;
				$scope.showCharts = true;
			};

			$scope.setMedia = function (element) {
				$scope.selectedType = 'MEDIA';
				delete $scope.hdElementsGridOptions;
				MediaService.getAllMedias(false, true).then(function (response) {
					fillHdElements(response.data, false, false, true);
				});
				$scope.selectedElement = element;
				$scope.hideElements = true;
				$scope.showMedias = true;
			};

			$scope.setGrid = function (element) {
				$scope.selectedType = 'GRID';
				delete $scope.hdElementsGridOptions;
				GridService.getAllGrids(false, true).then(function (response) {
					fillHdElements(response.data, false, true, false);
				});
				$scope.selectedElement = element;
				$scope.hideElements = true;
				$scope.showGrids = true;
			};

			$scope.editHtmlEditor = function (element) {
				$scope.selectedType = 'HTML_EDITOR';
				$scope.htmlEditor = element.html_editor
					? angular.copy(element.html_editor)
					: {};
				delete $scope.hdElementsGridOptions;
				$scope.selectedElement = element;
				$scope.hideElements = true;
			};

			$scope.setHtmlEditor = function (element) {
				$scope.selectedType = 'HTML_EDITOR';
				$scope.htmlEditor = {};
				delete $scope.hdElementsGridOptions;
				$scope.selectedElement = element;
				$scope.hideElements = true;
			};

			var fillHdElements = function (data, isChart, isGrid, isMedia) {
				var hdElements = [];
				$scope.tags = [];
				var tags = [];
				for (var e in data) {
					var element = {
						id: data[e].id,
						lc: data[e].metadata.label,
						desc: data[e].metadata.description,
					};
					if (isChart) {
						element.chart_type = data[e].type;
						element.chart_sub_type = data[e].sub_type;
					}
					if (isGrid) {
						element.grid_type = data[e].type;
					}
					if (isMedia) {
						element.media_type = data[e].type;
					}
					element.tags = data[e].metadata.tags;
					tags = _.union(tags, element.tags);
					element.updateDate = $rootScope.getDateTimeWithPattern(
						data[e].metadata.update_date
					);
					element.updatedBy = data[e].metadata.updated_by;
					element.tagAsArray = $rootScope.mapTags(data[e].metadata.tags);
					hdElements.push(element);
				}

				for (var i in tags) {
					if (_.find($scope.tags, { id: tags[i].id }) == null) {
						$scope.tags.push({ id: tags[i].id, label: tags[i].code });
					}
				}

				$scope.hdElementsGridOptions = angular.copy(hdElementsGridOptions);
				$scope.hdElementsGridOptions.dataSource = hdElements;
			};

			$scope.removeDashboardElement = function (element) {
				$scope.dashboardConfig.elements.splice(
					$scope.dashboardConfig.elements.indexOf(element),
					1
				);
				for (var c in $scope.dashboardConfig.global.centerings) {
					if (
						$scope.dashboardConfig.global.centerings[c].autocomplete_element ==
						element.uuid
					) {
						delete $scope.dashboardConfig.global.centerings[c]
							.autocomplete_element;
						delete $scope.dashboardConfig.global.centerings[c]
							.autocomplete_source;
						delete $scope.dashboardConfig.global.centerings[c]
							.autocomplete_column;
					}
				}
				updatePageSelection();
			};

			$scope.showVisualization = function () {
				if (!$scope.configSaved && $scope.dashboardConfig) {
					PermissionService.havePermission(
						$stateParams.id,
						'edit',
						'dashboard'
					).then(function (response) {
						if (response.data) {
							$scope.saveConfig(showVisualization);
						} else {
							showVisualization();
						}
					});
				} else {
					showVisualization();
				}
			};

			var showVisualization = function () {
				if (!$scope.dashboardView.dashboardId) {
					$scope.dashboardView.dashboardId = $stateParams.id;
				} else {
					$scope.dashboardView.reinit();
				}
				delete $scope.isConfigMode;
			};
			init();

			function init() {
				$scope.centeringTypes = [
					gettextCatalog.getString('Caractère'),
					gettextCatalog.getString('Numérique'),
					gettextCatalog.getString('Date sans heures'),
					gettextCatalog.getString('Date avec heures'),
				];
				$scope.isNew = true;
				$scope.savingInProgress = false;
				$scope.isWithoutTime = false;
				$scope.type = $scope.type
					? $scope.type
					: gettextCatalog.getString('Caractère');
				if ($stateParams.id) {
					$scope.isNew = false;
					$scope.metadata.isNew = false;
					var dashboardId = $stateParams.id;
					PermissionService.havePermission(
						$stateParams.id,
						'publication',
						'dashboard'
					).then(function (response) {
						$scope.publicationPermitted = response.data;
					});

					DashboardService.getDashboard(dashboardId).then(function (response) {
						$scope.currentDashboard = response.data;
						$scope.metadata = $scope.currentDashboard.metadata;
						$scope.dashboardLib = $scope.metadata.label;
						$scope.metadataLoaded = true;
						$scope.showConfig(true);
					});
				} else {
					$scope.metadataLoaded = true;
				}
			}

			$scope.saveAndReturnToList = function () {
				$scope.saveDashboard(true);
			};
			$scope.dataModel.save = $scope.saveAndReturnToList;

			$scope.savingInProgress = false;
			let catchDashboardSaveErrors = function (error) {
				$scope.savingInProgress = false;
			};

			$scope.saveDashboard = function (returnToList) {
				$('#confirmationModal').modal('hide');

				// set metadata
				for (var t in $scope.metadata.tags) {
					$scope.metadata.tags[t].color =
						$scope.metadata.tags[t].color !== undefined
							? $scope.metadata.tags[t].color
							: '#dbf5d1';
				}

				var dashboard = {};

				dashboard.metadata = {
					active: $scope.metadata.actif,
					tags: $scope.metadata.tags,
					licence_type: $scope.metadata.licence_type,
					icon_id: $scope.metadata.icon_id,
					short_label: $scope.metadata.label,
					description: $scope.metadata.description,
				};

				$scope.savingInProgress = true;

				if ($scope.isNew) {
					dashboard.metadata.code = $scope.metadata.code;
					DashboardService.createDashboard(dashboard)
						.then(function (response) {
							toaster.pop(
								'success',
								toasterPopSuccess,
								toasterPopCreationTableau
							);
							if (returnToList) {
								$timeout(function () {
									$scope.savingInProgress = false;
									$state.go('dashboards');
								}, 300);
							} else {
								$timeout(function () {
									$scope.savingInProgress = false;
									$state.go('dashboards-edit', { id: response.data });
								}, 300);
							}
						})
						.catch(catchDashboardSaveErrors);
				} else {
					DashboardService.editDashboard($scope.currentDashboard.id, dashboard)
						.then(function (response) {
							toaster.pop('success', toasterPopSuccess, toasterPopEditTableau);
							$scope.savingInProgress = false;
							if (returnToList) {
								$timeout(function () {
									$state.go('dashboards');
								}, 300);
							}
						})
						.catch(catchDashboardSaveErrors);
				}
			};

			//Dashboard publication
			$scope.expirationDatePattern = 'DD/MM/YYYY HH:mm';
			$scope.showPublication = function () {
				if (!$scope.configSaved && $scope.dashboardConfig) {
					$scope.saveConfig(showPublication);
					return;
				}
				delete $scope.gridsterOptions;
				showPublication();
			};

			$scope.newPublication = function () {
				delete $scope.publicationToEdit;
				PublicationService.generateHashedLink($stateParams.id).then(function (
					response
				) {
					$scope.publication = {
						publication_mode: 'EXTERNAL',
						label: $scope.dashboardLib,
						external_email_items: [],
						link: response.data,
						expiration_mode: 'EXPIRATION_DATE',
						link_mode: 'UNIQUE',
					};
					$scope.publication.full_link =
						$scope.publicationLink + $scope.publication.link;
					$('#publicationModal').modal('show');
				});
			};

			$scope.addDestination = function () {
				$scope.publication.external_email_items.push({});
			};

			$scope.copyToClipboard = function (value) {
				copyToClipboard(value);
				toaster.pop('success', gettextCatalog.getString('Copie effectuée !'));
			};

			$scope.removeDestination = function (index) {
				$scope.publication.external_email_items.splice(index, 1);
			};

			$scope.viewPublication = function (publication) {
				$window.open($scope.publicationLink + publication.link, '_blank');
			};

			$scope.updatePublication = function (publication) {
				PublicationService.updatePublication(
					$stateParams.id,
					publication.id
				).then(function (response) {
					toaster.pop(
						'success',
						gettextCatalog.getString('Mise à jour effectuée !')
					);
					updatePublicationList();
				});
			};

			var editPublication = function (publication) {
				$scope.publicationToEdit = publication;
				PublicationService.findPublication(
					$stateParams.id,
					publication.id
				).then(function (response) {
					$scope.publication = response.data;
					$scope.publication.external_email_items = [];
					if ($scope.publication.expiration_date) {
						$scope.publication.expiration_date =
							DateService.dateToStringWithPatternLocal(
								$scope.publication.expiration_date,
								$scope.expirationDatePattern
							);
						$scope.publication.expiration_mode = 'EXPIRATION_DATE';
					} else {
						$scope.publication.expiration_mode = 'NONE';
					}

					if (
						$scope.publication.publication_mode === 'INTERNAL' &&
						$scope.publication.internal_users !== undefined &&
						$scope.publication.internal_users.length !== 0
					) {
						$scope.publication.internal_mode = 'USERS';
						UserGroupService.getAvailableUsers().then(function (
							availableUsersResponse
						) {
							$scope.users = availableUsersResponse.data;
							for (let u in $scope.users) {
								if (
									$scope.publication.internal_users.indexOf(
										$scope.users[u].user_id
									) > -1
								) {
									$scope.users[u].selected = true;
								}
							}
						});
					}

					if (
						$scope.publication.publication_mode === 'INTERNAL' &&
						$scope.publication.internal_groups !== undefined &&
						$scope.publication.internal_groups.length !== 0
					) {
						$scope.publication.internal_mode = 'GROUPS';
						UserGroupService.getGroups().then(function (
							availableGroupsResponse
						) {
							$scope.groups = availableGroupsResponse;
							$scope.groups = $scope.groups.map((g) => {
								const idx = $scope.publication.internal_groups.findIndex(
									(internal) => internal === g.id
								);
								g.selected = idx !== -1;
								return g;
							});
						});
					}

					$scope.publication.full_link =
						$scope.publicationLink + $scope.publication.link;
					for (let m in $scope.publication.external_emails) {
						$scope.publication.external_email_items.push({
							email: $scope.publication.external_emails[m],
						});
					}
					if ($scope.publication.multiple_link) {
						$scope.publication.link_mode = 'PER_USER';
					} else {
						$scope.publication.link_mode = 'UNIQUE';
					}

					if (!$scope.publication.link) {
						PublicationService.generateHashedLink($stateParams.id).then(
							function (response) {
								$scope.publication.link = response.data;
								$scope.publication.full_link =
									$scope.publicationLink + $scope.publication.link;
								$('#publicationModal').modal('show');
							}
						);
					} else {
						$('#publicationModal').modal('show');
					}
				});
			};

			$scope.editPublication = function (publication) {
				editPublication(publication);
			};

			$scope.editArchivedPublication = function (publication) {
				editPublication(publication);
			};

			$scope.updatePublicationStatus = function (publication) {
				PublicationService.updatePublicationStatus(
					$stateParams.id,
					publication.id,
					publication.active
				).then(function (response) {});
			};

			$scope.showPublicationViews = function (publication) {
				$scope.publication = publication;
				$scope.publicationViewsShown = true;
				$scope.publicationListsShown = false;

				delete $scope.publicationViewsGridOptions;
				PublicationService.getAllPublicationViews(
					$stateParams.id,
					$scope.publication.id
				).then(function (response) {
					$scope.publicationViewsGridOptions = angular.copy(
						publicationViewsGridOptions
					);
					$scope.publicationViewsGridOptions.dataSource = response.data;
				});
			};

			$scope.cancelPublicationViews = function () {
				$scope.publicationViewsShown = false;
				$scope.publicationListsShown = true;
			};

			$scope.restorePublication = function (publication) {
				PublicationService.restorePublication(
					$stateParams.id,
					publication.id
				).then(function (response) {
					toaster.pop(
						'success',
						gettextCatalog.getString('Restauration effectuée avec succès !')
					);
					updatePublicationList();
				});
			};

			PublicationService.getPublicationLink().then(function (response) {
				$scope.publicationLink = response.data;
			});

			$scope.deleteOrArchivePublication = function (publication) {
				$scope.publication = publication;
				$scope.widgetData.confirmationDialogTitle = gettextCatalog.getString(
					'Confirmation de la suppression'
				);
				$scope.widgetData.confirmationDialogMessage = gettextCatalog.getString(
					"Attention ! Si cette publication n'a jamais été consultée, sa suppression sera définitive"
				);
				$scope.widgetData.confirmationGreenAction = true;
				$scope.widgetData.confirmationDialogActionName =
					gettextCatalog.getString('Supprimer');
				$scope.widgetData.enableConfirmDialogAction = true;
				$scope.widgetData.confirmDialogAction =
					$scope.confirmDeleteOrArchivePublication;
				$scope.widgetData.showConfirmationDialog = true;
			};

			$scope.confirmDeleteOrArchivePublication = function () {
				PublicationService.deleteOrArchivePublication(
					$stateParams.id,
					$scope.publication.id
				).then(function (response) {
					updatePublicationList();
				});
			};

			$scope.deletePublication = function (publication) {
				$scope.publication = publication;
				$scope.widgetData.confirmationDialogTitle = gettextCatalog.getString(
					'Confirmation de la suppression'
				);
				$scope.widgetData.confirmationDialogMessage = gettextCatalog.getString(
					'Attention ! en confirmant, cette publication sera supprimée définitivement'
				);
				$scope.widgetData.confirmationGreenAction = true;
				$scope.widgetData.confirmationDialogActionName =
					gettextCatalog.getString('Supprimer');
				$scope.widgetData.enableConfirmDialogAction = true;
				$scope.widgetData.confirmDialogAction = $scope.confirmDeletePublication;
				$scope.widgetData.showConfirmationDialog = true;
			};

			$scope.confirmDeletePublication = function () {
				PublicationService.deletePublication(
					$stateParams.id,
					$scope.publication.id
				).then(function (response) {
					updatePublicationList();
				});
			};

			$scope.saveUpdatedPublication = function (publication) {
				if (
					!moment(
						$scope.publicationToEdit.expiration_date,
						$scope.expirationDatePattern
					).isValid()
				) {
					toaster.pop(
						'error',
						gettextCatalog.getString(
							'Format non supporté. Utiliser le sélecteur de date pour poursuivre.'
						)
					);
					return;
				}
				PublicationService.editPublication(
					$stateParams.id,
					$scope.publicationToEdit.id,
					$scope.publicationToEdit
				).then(function (response) {
					updatePublicationList();
					toaster.pop(
						'success',
						gettextCatalog.getString(
							'Modifications de la publication enregistrées !'
						)
					);
					$('#editPublicationModal').modal('hide');
				});
			};

			$scope.savePublication = function () {
				if (!validatePublicationData($scope.publication)) {
					return;
				}
				if ($scope.publication.internal_mode === 'USERS') {
					$scope.publication.internal_users = _.map(
						_.filter($scope.users, function (item) {
							return item.selected;
						}),
						function (item) {
							return item.user_id;
						}
					);
				} else if ($scope.publication.internal_mode === 'GROUPS') {
					$scope.publication.internal_groups = _.map(
						_.filter($scope.groups, function (item) {
							return item.selected;
						}),
						function (item) {
							return item.id;
						}
					);
				}

				$scope.publication.external_emails = _.map(
					$scope.publication.external_email_items,
					function (item) {
						return item.email;
					}
				);
				let publication = angular.copy($scope.publication);
				publication.expiration_date = publication.isoExpirationDate
					? publication.isoExpirationDate
					: DateService.dateToISOString(
							moment(publication.expiration_date, $scope.expirationDatePattern)
					  );
				delete publication.isoExpirationDate;
				if (publication.link_mode === 'PER_USER') {
					publication.multiple_link = true;
				}
				if (
					publication.publication_mode === 'INTERNAL' ||
					(publication.publication_mode === 'EXTERNAL' &&
						publication.multiple_link)
				) {
					delete publication.link;
				}
				if (!publication.publication_mode) {
					publication.publication_mode = 'EXTERNAL';
				}
				if (!publication.expiration_mode) {
					publication.expiration_mode = 'NONE';
				}

				if (publication.expiration_mode === 'NONE') {
					delete publication.expiration_date;
				}

				$scope.widgetData.confirmationDialogTitle =
					gettextCatalog.getString('Confirmation');
				$scope.widgetData.confirmationDialogMessage =
					($scope.publicationToEdit
						? gettextCatalog.getString(
								'Confirmez-vous la modification de la publication'
						  )
						: gettextCatalog.getString(
								'Confirmez-vous la création de la publication'
						  )) +
					' ' +
					publication.label +
					' ' +
					gettextCatalog.getString('en date du') +
					' ' +
					DateService.dateToStringWithPatternLocal(
						new Date(),
						$scope.expirationDatePattern
					) +
					' ?';
				$scope.widgetData.confirmationDialogActionName =
					gettextCatalog.getString('Confirmer');
				$scope.widgetData.confirmationGreenAction = true;
				$scope.widgetData.enableConfirmDialogAction = true;

				$scope.widgetData.confirmDialogAction = function () {
					if ($scope.publicationToEdit) {
						PublicationService.editPublication(
							$stateParams.id,
							$scope.publicationToEdit.id,
							publication
						).then(function (response) {
							updatePublicationList();
							toaster.pop(
								'success',
								gettextCatalog.getString(
									'Modifications de la publication enregistrées !'
								)
							);
							$('#publicationModal').modal('hide');
							delete $scope.publicationToEdit;
						});
					} else {
						PublicationService.createPublication(
							$stateParams.id,
							publication
						).then(function (response) {
							updatePublicationList();
							toaster.pop(
								'success',
								gettextCatalog.getString(
									'Nouvelle publication créée avec succès !'
								)
							);
							$('#publicationModal').modal('hide');
						});
					}
				};

				$scope.widgetData.showConfirmationDialog = true;
			};

			var validatePublicationData = function (publication) {
				if (!publication.label || !publication.label[0]) {
					toaster.pop(
						'error',
						gettextCatalog.getString('Le titre est obligatoire')
					);
					return false;
				}

				if (publication.label.length > 120) {
					toaster.pop(
						'error',
						gettextCatalog.getString(
							'Le titre ne peut dépasser 120 caractères. Le réduire avant de poursuivre'
						)
					);
					return false;
				}
				if (publication.expiration_mode === 'EXPIRATION_DATE') {
					if (
						!publication.expiration_date ||
						!moment(
							publication.expiration_date,
							$scope.expirationDatePattern
						).isValid()
					) {
						toaster.pop(
							'error',
							gettextCatalog.getString(
								'Format non supporté. Utiliser le sélecteur de date pour poursuivre.'
							)
						);
						return false;
					}
					if (
						moment().diff(
							moment(publication.expiration_date, 'DD-MM-YYYY HH:mm'),
							'minutes'
						) > 0
					) {
						toaster.pop(
							'error',
							gettextCatalog.getString(
								'La date d’expiration ne peut être inférieur ou égale à la date de création de la publication. Choisir une date d’expiration valide.'
							)
						);
						return false;
					}
				}

				if (publication.publication_mode === 'EXTERNAL') {
					for (let e in publication.external_email_items) {
						let email = publication.external_email_items[e].email;
						if (
							!email ||
							!email[0] ||
							!email.includes('@') ||
							!email.includes('.')
						) {
							toaster.pop(
								'error',
								gettextCatalog.getString(
									'Le courriel d’un des destinataires du lien de partage est invalide'
								)
							);
							return false;
						}
					}
				}
				return true;
			};

			$scope.changeInternalMode = function () {
				if ($scope.publication.internal_mode === 'USERS') {
					UserGroupService.getAvailableUsers().then(function (
						availableUsersResponse
					) {
						$scope.users = availableUsersResponse.data;
					});
				} else if ($scope.publication.internal_mode === 'GROUPS') {
					UserGroupService.getGroups().then(function (availableGroupsResponse) {
						$scope.groups = availableGroupsResponse;
					});
				}
			};

			let elementToEditTitlesIndex;
			$scope.openTitlesEdit = function(elm) {
				elementToEditTitlesIndex = $scope.dashboardConfig.elements.indexOf(elm);
				ModalService.open({
          templateUrl: './src/components/modules/hd/dashboard/dashboardTitlesConf.html',
          controller: 'DashBoardElmTitlesConfigurationController',
          controllerAs: 'vm',
          bindToController: true,
          locals: {
            titleConfig: elm.titleConfig
          }
        }).then(function(config) {
          $scope.dashboardConfig.elements[elementToEditTitlesIndex].titleConfig = config;
        }, function(reason) {
          elementToEditTitlesIndex = undefined;
        });
			}

			$scope.initCenteringDateSelector = function(centering) {
				if (!isEmpty(centering.slider_date_mode)) {
					return;
				}
				centering.slider_date_mode = "ABSOLUTE";
			}

			$scope.multiPageSwitchingConfirmation = function () {
				$scope.dashboardConfig.global.multi_page = true;
				$scope.widgetData.confirmationDialogTitle = gettextCatalog.getString(
					'dashboard.page-switch-.msg.title'
				);
				$scope.widgetData.confirmationDialogMessage = gettextCatalog.getString(
					"dashboard.page-switch-.msg"
				);
				$scope.widgetData.confirmationGreenAction = false;
				$scope.widgetData.confirmationDialogActionName =
					gettextCatalog.getString('dashboard.page-switch-.msg.action');
				$scope.widgetData.enableConfirmDialogAction = true;
				$scope.widgetData.confirmDialogAction = toSinglePage;
				$scope.widgetData.showConfirmationDialog = true;
			};

			$scope.multiPageSwitching = function() {
				if ($scope.dashboardConfig.global.multi_page) {
					$scope.dashboardConfig.global.pages =  [];
					const page = DashboardService.generatePage(0);
					$scope.dashboardConfig.global.pages.push(page);
					$scope.selectedPage.id = page.id;
					$scope.dashboardConfig.elements.forEach(elm => {
						elm.page = page.id;
						return elm;
					});
					$scope.selectedPage.id = undefined;
					updatePageSelection();
				} else if ($scope.dashboardConfig.global.pages && $scope.dashboardConfig.global.pages.length == 1) {
					toSinglePage();
				} else {
					$scope.multiPageSwitchingConfirmation();
				}
			}

			function toSinglePage() {
				delete $scope.dashboardConfig.global.pages;
				$scope.dashboardConfig.elements.forEach(elm => {
					delete elm.page;
					return elm;
				});
				$scope.selectedPage.id = undefined;
				$scope.dashboardConfig.global.multi_page = false;
				updatePageSelection();
			}

			$scope.openPagesManagement = function(elm) {
				elementToEditTitlesIndex = $scope.dashboardConfig.elements.indexOf(elm);
				ModalService.open({
					templateUrl: './src/components/modules/hd/dashboard/pages/dashboard-pages-conf.html',
					controller: 'DashBoardPagesConfigurationController',
					controllerAs: 'vm',
					bindToController: true,
					locals: {
						title: gettextCatalog.getString('dashboard.pages.management'),
						pages: angular.copy($scope.dashboardConfig.global.pages)
					}
				}).then(function(pages) {
					if (!$scope.dashboardConfig.global.pages || $scope.dashboardConfig.global.pages.length === 0) {
						$scope.dashboardConfig.global.pages = pages;
						return;
					}
					const oldPagesIds = $scope.dashboardConfig.global.pages.map(page => page.id);
					const newPagesIds =  pages.map(page => page.id);
					const deletedIds = oldPagesIds.filter(id => !newPagesIds.includes(id));
					if (deletedIds && deletedIds.length > 0) {
						const deletedSelected = deletedIds.includes($scope.selectedPage.id);
						$scope.dashboardConfig.elements = _.filter($scope.dashboardConfig.elements, function (elm) { return !deletedIds.includes(elm.page); });
						if(deletedSelected) {
							$scope.selectedPage.id = pages[0].id;
						}
					}
					$scope.dashboardConfig.global.pages = pages;
					updatePageSelection();
				});
			}



			var showPublication = function () {
				$scope.publicationListsShown = true;
				updatePublicationList();
			};

			var updatePublicationList = function () {
				delete $scope.publicationArchivedGridOptions;
				delete $scope.publicationGridOptions;
				PublicationService.findAllPublications($stateParams.id).then(function (
					response
				) {
					for (let p in response.data) {
						response.data[p].expiration_date_formatted = response.data[p]
							.expiration_date
							? formatDate(response.data[p].expiration_date)
							: gettextCatalog.getString('Aucune');
						response.data[p].creation_date_formatted = formatDate(
							response.data[p].creation_date
						);
						response.data[p].last_snapshot_formatted = response.data[p]
							.last_data_snapshot_date
							? formatDate(response.data[p].last_data_snapshot_date)
							: gettextCatalog.getString('Aucune');
					}
					$scope.publicationGridOptions = angular.copy(publicationGridOptions);
					// add last snapshot date to grid
					$scope.publicationGridOptions.columns.splice(4, 0, {
						caption: gettextCatalog.getString("publication.last.update.date"),
						dataField: 'last_snapshot_formatted',
						allowReordering: false,
						allowFiltering: false,
					});
					$scope.publicationArchivedGridOptions = angular.copy(
						publicationGridOptions
					);
					$scope.publicationGridOptions.dataSource = _.filter(
						response.data,
						function (item) {
							return !item.deleted && !item.expired;
						}
					);
					$scope.publicationArchivedGridOptions.dataSource = _.filter(
						response.data,
						function (item) {
							return item.deleted || item.expired;
						}
					);
				});
			};

			var publicationGridOptions = getDefaultDxGridConfig(
				PAGINATIONS_SIZE,
				PAGINATIONS_SIZES
			);
			var publicationViewsGridOptions = getDefaultDxGridConfig(
				PAGINATIONS_SIZE,
				PAGINATIONS_SIZES
			);
			publicationGridOptions.columns = [
				{
					caption: gettextCatalog.getString('Nom'),
					dataField: 'label',
					allowReordering: false,
				},
				{
					caption: gettextCatalog.getString('Créée par'),
					cellTemplate: 'createdBy',
					allowReordering: false,
					allowFiltering: false,
				},
				{
					caption: gettextCatalog.getString('Date de création'),
					dataField: 'creation_date_formatted',
					allowReordering: false,
					allowFiltering: false,
				},
				{
					caption: gettextCatalog.getString("Date d'expiration"),
					dataField: 'expiration_date_formatted',
					allowReordering: false,
					allowFiltering: false,
				},
				{
					caption: gettextCatalog.getString('Statut'),
					alignment: 'center',
					cellTemplate: 'status',
					width: '200px',
					allowReordering: false,
					allowFiltering: false,
				},
				{
					caption: gettextCatalog.getString('Actions'),
					alignment: 'center',
					cellTemplate: 'actions',
					width: '200px',
					allowReordering: false,
					allowFiltering: false,
				},
			];
			publicationViewsGridOptions.columns = [
				{
					caption: gettextCatalog.getString('Créateur'),
					cellTemplate: 'createdBy',
					allowReordering: false,
				},
				{
					caption: gettextCatalog.getString("Date d'accès"),
					dataField: 'date',
					allowReordering: false,
					allowFiltering: false,
					alignment: 'left',
					customizeText: function (data) {
						return formatDate(data.value);
					}
				},
				{
					caption: gettextCatalog.getString('Ouvert par (courriel)'),
					dataField: 'email',
					allowReordering: false,
					allowFiltering: false,
				},
				{
					caption: gettextCatalog.getString('IP'),
					dataField: 'ip',
					allowReordering: false,
					allowFiltering: false,
				},
				{
					caption: gettextCatalog.getString('Navigateur'),
					dataField: 'browser',
					allowReordering: false,
					allowFiltering: false,
				},
			];

			function addTabChangeListener() {
				const tabs = $('.nav-tabs a');
				// remove previous listeners
				tabs.off('shown.bs.tab');
				tabs.on('shown.bs.tab', function (e) {
					$scope.previousTab = e.relatedTarget ? e.relatedTarget.id : undefined;
					$scope.currentTab = e.target ? e.target.id : undefined;
				});
			}

			function updatePageSelection() {
				if ($scope.dashboardConfig.global.multi_page) {
						$rootScope.$broadcast("UPDATE_PAGES_SELECTION",{});
				} else {
					$scope.dashboardConfig.filteredElements =  $scope.dashboardConfig.elements;
				}
			}
		},
	]);
