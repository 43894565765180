(function () {
	'use strict';

	angular
		.module('dcApp')
		.directive('dashboardPages', [
			'$parse',
			function ($parse) {
				var controller = [
					'$scope',
					function ($scope) {
						var vm = this;
						vm.selectedPage = null;
						vm.selectPage = selectPage;
						vm.updatePageSelectionOn = updatePageSelection;

						function selectPage(pageId) {
							vm.selectedPage = pageId;
							updatePageSelection();
						}

						function updatePageSelection() {
							vm.selectedPage = isEmpty(vm.selectedPage) ?  vm.dashboardConfig.global.pages[0].id : vm.selectedPage;
							vm.dashboardConfig.filteredElements =  _.filter(vm.dashboardConfig.elements, function (elm) {
								return elm.page === vm.selectedPage || (elm.config && elm.config.page === vm.selectedPage);
							});
							if (vm.dashboardConfig.global.centering_active) {
								const usedCentering = vm.dashboardConfig.filteredElements.flatMap(elm => {
									return elm.centering ? Object.keys(elm.centering) : [];
								});
								vm.dashboardConfig.global.filteredCentering =  _.filter(vm.dashboardConfig.global.centerings, function (elm) {
									return usedCentering.indexOf(elm.uuid) > -1;
								});
							}
						}
					},
				];
				return buildDirectiveReturnObject(
					{
						dashboardConfig: '=',
						selectedPage: '=',
						editMode: '=',
						openPagesManagement: '<'
					},
					controller,
					'./src/components/directives/handledata/dashboardPages/dashboard-pages.html',
					function postLink(scope, element, attrs) {
						scope.$on("UPDATE_PAGES_SELECTION",function(event, data){
							scope.vm.updatePageSelectionOn();
						});
					}
				);
			},
		]);
})();
