(function () {
	'use strict';

	angular.module('dcApp').directive('elementToDuplicate', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$rootScope',
				'$timeout',
				'elem',
				function ($scope, $rootScope, $timeout, elem) {
					var vm = this;
					vm.userNameTruncateThresh = 20;

					vm.elem = elem;
					vm.init = init;
					vm.initVmMembers = initVmMembers;
					vm.toggleElementType = toggleElements;
					vm.deleteMember = function (item) {
						if ($scope.displayedUsers.length > 0) {
							$scope.displayedUsers.splice(
								$scope.displayedUsers.indexOf(item),
								1
							);
						}
						if (vm.projectToImportInfos.members.length > 0) {
							vm.projectToImportInfos.members.splice(
								vm.projectToImportInfos.members.indexOf(item),
								1
							);
						}
					};

					$scope.querySearch = querySearch;
					$scope.selectUser = function (item) {
						if (item === null || item === undefined) {
							return;
						}
						$scope.selectedUsers.push({
							id: item.id,
							name: item.name,
							active: true,
							has_project_role: item.has_project_role,
							has_member_role: item.has_member_role,
							is_group: item.is_group,
							admin: false,
						});
					};
					$scope.addUser = function (item) {
						const result = $scope.displayedUsers.filter(
							(user) => user.member_id === item.id
						);
						if (result.length === 0) {
							$scope.displayedUsers.push({
								is_group: item.is_group,
								member_id: item.id,
								hasProjectRole: item.has_project_role,
								hasMemberRole: item.has_member_role,
								admin: item.admin,
								canDelete: true,
							});
							vm.members = $scope.displayedUsers;
						}
					};
					$scope.mapUser = function (targetMember, index) {
						vm.members[index].target_member_id = targetMember.id;
						vm.members[index].target_is_group = targetMember.is_group;
						vm.members[index].has_project_role = targetMember.has_project_role;
					};

					function toggleElements(type) {
						if (type === vm.elem.type.datablock) {
							vm.elementTypes.datablock.checked === true
								? toggleAssociatedElements(
										vm.elementTypes[vm.elem.type.datablock].checked,
										[vm.elem.type.entity]
								  )
								: toggleAssociatedElements(false, [
										vm.elem.type.hdElements,
										vm.elem.type.dashboard,
								  ]);
							$scope.$emit(
								'elementToDuplicate:handleDataElements:selection',
								vm.elementTypes[vm.elem.type.hdElements].checked
							);
						} else if (
							type === vm.elem.type.dashboard &&
							vm.elementTypes.dashboard.checked === true
						) {
							toggleAssociatedElements(
								vm.elementTypes[vm.elem.type.dashboard].checked,
								[
									vm.elem.type.hdElements,
									vm.elem.type.datablock,
									vm.elem.type.entity,
								]
							);
							$scope.$emit(
								'elementToDuplicate:handleDataElements:selection',
								vm.elementTypes[vm.elem.type.hdElements].checked
							);
						} else if (type === vm.elem.type.hdElements) {
							vm.elementTypes.hdElements.checked === true
								? toggleAssociatedElements(
										vm.elementTypes[vm.elem.type.hdElements].checked,
										[vm.elem.type.datablock, vm.elem.type.entity]
								  )
								: toggleAssociatedElements(false, [vm.elem.type.dashboard]);

							$scope.$emit(
								'elementToDuplicate:handleDataElements:selection',
								vm.elementTypes[vm.elem.type.hdElements].checked
							);
						} else if (
							type === vm.elem.type.connector &&
							vm.elementTypes.connector.checked === false
						) {
							toggleAssociatedElements(false, [
								vm.elem.type.repository,
								vm.elem.type.exportTemplate,
								vm.elem.type.localData,
								vm.elem.type.extractRepository,
							]);
						} else if (
							type === vm.elem.type.entity &&
							vm.elementTypes.entity.checked === false
						) {
							toggleAssociatedElements(false, [
								vm.elem.type.datablock,
								vm.elem.type.hdElements,
								vm.elem.type.dashboard,
							]);
							$scope.$emit(
								'elementToDuplicate:handleDataElements:selection',
								vm.elementTypes[vm.elem.type.hdElements].checked
							);
						} else if (type === vm.elem.type.repository) {
							vm.elementTypes.repository.checked === true
								? toggleAssociatedElements(
										vm.elementTypes[vm.elem.type.repository].checked,
										[vm.elem.type.connector]
								  )
								: toggleAssociatedElements(false, [
										vm.elem.type.extractRepository,
										vm.elem.type.localData,
								  ]);
						} else if (
							type === vm.elem.type.exportTemplate &&
							vm.elementTypes.exportTemplate.checked === true
						) {
							toggleAssociatedElements(
								vm.elementTypes[vm.elem.type.exportTemplate].checked,
								[vm.elem.type.connector]
							);
						} else if (
							type === vm.elem.type.extractRepository &&
							vm.elementTypes.extractRepository.checked === true
						) {
							toggleAssociatedElements(
								vm.elementTypes[vm.elem.type.extractRepository].checked,
								[vm.elem.type.repository, vm.elem.type.connector]
							);
						} else if (
							type === vm.elem.type.localData &&
							vm.elementTypes.localData.checked === true
						) {
							toggleAssociatedElements(
								vm.elementTypes[vm.elem.type.localData].checked,
								[vm.elem.type.repository, vm.elem.type.connector]
							);
						} else if (type === vm.elem.type.noneContext) {
							toggleAssociatedElements(false, [
								vm.elem.type.extractRepository,
								vm.elem.type.localData,
							]);
							vm.disabled = true;
						} else if (type === vm.elem.type.sameContext) {
							vm.disabled = false;
						}
					}

					function createFilterFor(query) {
						return function filterFn(user) {
							return (
								!_.find($scope.selectedUsers, function (item) {
									return item.id == user.id;
								}) && user.name.toLowerCase().indexOf(query.toLowerCase()) > -1
							);
						};
					}

					function querySearch(query) {
						return query ? vm.users.filter(createFilterFor(query)) : [];
					}

					function toggleAssociatedElements(state, elements) {
						elements.forEach((el) => {
							vm.elementTypes[el].checked = state;
						});
					}

					function initVmMembers(index) {
						let user = vm.projectToImportInfos.members[index];
						vm.members[index] = {
							imported_member_id: user.id,
							imported_is_group: !!user.name,
							admin: user.has_admin_role,
						};
					}

					$scope.$watch(
						'displayedUsers',
						function (newValue) {
							if (
								newValue &&
								vm.from === 'import' &&
								vm.projectToImportInfos &&
								vm.projectToImportInfos.members &&
								vm.projectToImportInfos.members.length === 0
							) {
								vm.members = newValue;
							}
						},
						true
					);

					function init() {
						vm.members = [];
						vm.initialElementsState = {
							datablock: { checked: false },
							dashboard: { checked: false },
							hdElements: { checked: false },
							entity: { checked: false },
							repository: { checked: false },
							exportTemplate: { checked: false },
							connector: { checked: false },
							extractRepository: { checked: false },
							localData: { checked: false },
						};
						vm.elementTypes =
							vm.elementTypes && Object.keys(vm.elementTypes).length !== 0
								? vm.elementTypes
								: vm.initialElementsState;
						vm.currentUser = _.find(vm.users, function (p) {
							return p.id === $rootScope.account.userId;
						});
						vm.currentUser = vm.currentUser ? vm.currentUser : {};
						vm.members = vm.users ? [] : $scope.displayedUsers;

						$scope.selectedItem = null;
						$scope.searchText = null;
						$scope.autocompleteDemoRequireMatch = false;
						$scope.selectedUsers = [];
						$scope.isEmpty = Object.keys(vm.currentUser).length === 0;
						$scope.displayedUsers =
							$scope.isEmpty === false
								? [
										{
											is_group: vm.currentUser.is_group,
											member_id: vm.currentUser.id,
											hasProjectRole: vm.currentUser.has_project_role,
											hasMemberRole: vm.currentUser.has_member_role,
											admin: vm.currentUser.admin,
											canDelete: false,
										},
								  ]
								: [];
					}
				},
			];

			return buildDirectiveReturnObject(
				{
					data: '=',
					elementTypes: '=',
					disabled: '=',
					from: '=',
					projectToImportInfos: '=',
					members: '=',
					users: '=',
					usage: '<',
				},
				controller,
				'./src/components/directives/duplicateProject/elementToDuplicate.html',
				function postLink(scope, element, attrs, $ctrl) {
					$ctrl.init();

					initElementForDirective(element, attrs, function () {});

					watchElementForDirective(
						element,
						scope,
						function () {
							return $ctrl.data ? $ctrl.data.showExportDatablock : false;
						},
						function () {},
						true
					);
				}
			);
		},
	]);
})();
