(function () {
	'use strict';

	angular.module('dcApp').factory('CaracService', [
		'$http',
		'$q',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		function ($http, $q, $rootScope, API_BASE_URL_BACKEND) {
			var CaracService = {};

			CaracService.getCarac = function (caracId) {
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('CARACS_GET');
				url = url.replace('{id}', caracId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			CaracService.createCaracsAndMappingFromDepot = function (linkId) {
				var url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('CARACS_CREATE_FROM_DEPOT');
				url = url.replace('{linkId}', linkId);
				return $http.post(url).then(function (response) {
					return response;
				});
			};

			CaracService.getCaracElementaryDataByUetId = function (entiteId) {
				var url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('CARACS_ELEMENTARY_DATA_BY_ENTITE');
				url = url.replace('{entiteTypeId}', entiteId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			CaracService.getLibellesLang = function (caracId) {
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('CARACS_LIBS');
				url = url.replace('{id}', caracId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			CaracService.deleteCarac = function (caracId) {
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('CARACS_DELETE');
				url = url.replace('{id}', caracId);

				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			CaracService.createCarac = function (entityTypeId, carac) {
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('CARACS_CREATE');
				url = url.replace('{entiteTypeId}', entityTypeId);
				var deferred = $q.defer();

				$http.post(url, carac).then(function (response) {
					var results = {};
					results.data = response.data;
					results.headers = response.headers();
					results.status = response.status;
					results.config = response.config;
					deferred.resolve(results);
				},function (err) {
					deferred.reject(err);
				});
				return deferred.promise;
			};

			CaracService.editCarac = function (entityTypeId, carac) {
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('CARACS_EDIT');
				url = url.replace('{entiteTypeId}', entityTypeId);
				var deferred = $q.defer();
				$http.put(url, carac).then(function (response) {
					var results = {};
					results.data = response.data;
					results.headers = response.headers();
					results.status = response.status;
					results.config = response.config;
					deferred.resolve(results);
				},function (err) {
					deferred.reject(err);
				});
				return deferred.promise;
			};

			CaracService.getTopValues = function (caracId, text, limit, searchMode) {
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('CARACS_TOP_VALUES');
				url = url.replace('{id}', caracId);
				url = url + '?limit=' + limit;

				if (text) {
					url = url + '&text=' + text;
				}
				if (searchMode) {
					url = url + '&searchMode=' + searchMode;
				}
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			CaracService.getCaracsByEdge = function (edgeId) {
				var url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('CARACS_SEARCH_BY_EDGE');
				url = url + '?edgeId=' + edgeId;
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			CaracService.setCaracCf = function (caracId, isCf) {
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('CARACS_SET_CF');
				url = url.replace('{id}', caracId);
				url = url.replace('{isCf}', isCf);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			CaracService.getSimpleCaracsByEntiteId = function (id) {
				var url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('CARACS_SEARCH_BY_ENTITE');
				url = url + '?entiteId=' + id;
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			CaracService.saveFormula = function (carac) {
				var url = API_BASE_URL_BACKEND + $rootScope.getUrl('CARACS_FORMULA');
				return $http.post(url, carac).then(function (response) {
					return response;
				});
			};

			return CaracService;
		},
	]);
})();
