(function () {
	'use strict';
	angular.module('dcApp').factory('GridViewCommon', GridViewCommon);

	GridViewCommon.$inject = [
		'$state',
		'$window',
		'PermissionService',
		'GridService',
		'gettextCatalog',
		'hdSourceService',
	];

	function GridViewCommon(
		$state,
		$window,
		PermissionService,
		GridService,
		gettextCatalog,
		hdSourceService
	) {
		return {
			setCommonGridConfig: setCommonGridConfig,
			initializeGridViewScope: initializeGridViewScope,
		};

		function setCommonGridConfig(gridOptions, config) {
			gridOptions.hoverStateEnabled = config.global.hover_state_enabled;
			gridOptions.headerFilter = {
				visible: config.global.header_filter_visible,
			};
			gridOptions.filterRow = { visible: config.global.row_filter_visible };
			gridOptions.searchPanel = {
				visible: config.global.search_panel_visible,
				width: 240,
				placeholder: gettextCatalog.getString('Rechercher'),
			};
			gridOptions.rowAlternationEnabled = config.global.row_alternation_enabled;
			gridOptions.showColumnLines = config.global.show_column_lines;
			gridOptions.showRowLines = config.global.show_row_lines;
			gridOptions.wordWrapEnabled = config.global.word_wrap_enabled;
			gridOptions.sorting = {
				mode: config.global.sorting_enabled ? 'multiple' : 'none',
			};
			gridOptions.export = {
				enabled: config.global.export_panel_enabled,
				allowExportSelectedData: true,
			};
			gridOptions.allowColumnResizing = config.global.allow_column_resizing;
			gridOptions.columnResizingMode = config.global.column_resizing_mode;
		}

		function initializeGridViewScope(
			ignoreCache,
			$scope,
			viewModel,
			type,
			checkConfig,
			loadGroupDataInGrid
		) {
			if (!viewModel.data.full_data) {
				PermissionService.havePermission(
					viewModel.data.gridId,
					'edit',
					'grid'
				).then(function (response) {
					$scope.editIsPermitted = response.data;
				});
			}

			$scope.init = function (withCache) {
				$scope.dataMessage = false;
				delete $scope.gridOptions;
				delete $scope.treeOptions;
				$scope.uuid = generateUuid('_');
				if (!viewModel.data.full_data) {
					GridService.getGridConfig(type, viewModel.data.gridId).then(function (
						response
					) {
						$scope.config = response.data;
						initializeGridWithConfig(
							false,
							withCache,
							$scope,
							viewModel,
							checkConfig,
							gettextCatalog,
							hdSourceService,
							loadGroupDataInGrid
						);
					});
				} else {
					$scope.config =
						'simple' === type
							? viewModel.data.full_data.simple
							: viewModel.data.full_data.pivot;
					initializeGridWithConfig(
						true,
						withCache,
						$scope,
						viewModel,
						checkConfig,
						gettextCatalog,
						hdSourceService,
						loadGroupDataInGrid
					);
				}
			};

			$scope.edit = function () {
				const url = $state.href('grids-edit', {
					id: viewModel.data.gridId,
					type: type,
				});
				$window.open(url, '_blank');
				return;
			};

			$scope.switchDataVisibility = function () {
				$scope.showTableData = !$scope.showTableData;
			};

			$scope.rerun = function () {
				$scope.showTableData = false;
				$scope.init(false);
			};

			$scope.rerunWithoutCache = function () {
				$scope.init(true);
			};
		}

		function initializeGridWithConfig(
			fullData,
			ignoreCache,
			$scope,
			viewModel,
			checkConfig,
			gettextCatalog,
			hdSourceService,
			loadGroupDataInGrid
		) {
			if (!$scope.config.groups[0]) {
				$scope.dataMessage = gettextCatalog.getString(
					'Aucune source disponible'
				);
				return;
			}
			// override title conf if configured in dashboard
			let dashBoardTitleConfig = $scope.vm.data && $scope.vm.data.config ? $scope.vm.data.config.title_config : {};
			if (!$scope.config.global.title) {
				$scope.config.global.title = dashBoardTitleConfig;
			} else {
				if (dashBoardTitleConfig && !isEmpty(dashBoardTitleConfig.text)) {
					$scope.config.global.title.text = dashBoardTitleConfig.text;
					$scope.config.global.title.font = dashBoardTitleConfig.font;
				}
				if (!$scope.config.global.title.subtitle) {
					$scope.config.global.title.subtitle = dashBoardTitleConfig.subtitle;
				} else {
					if (dashBoardTitleConfig && dashBoardTitleConfig.subtitle && !isEmpty(dashBoardTitleConfig.subtitle.text)) {
						$scope.config.global.title.subtitle.text = dashBoardTitleConfig.subtitle.text;
						$scope.config.global.title.subtitle.font = dashBoardTitleConfig.subtitle.font;
					}
				}
			}
			$scope.group = angular.copy($scope.config.groups[0]);
			if (!checkConfig($scope.config)) {
				$scope.dataMessage = gettextCatalog.getString(
					'Le Tableau ne peut pas être visualisé. Certain paramètres ne sont pas valides.'
				);
				return;
			}
			if ($scope.jobDetails && $scope.jobDetails.init) {
				$scope.jobDetails.init();
			}
			if (!fullData) {
				initializeGridGroup(
					$scope,
					viewModel,
					ignoreCache,
					hdSourceService,
					loadGroupDataInGrid
				);
			} else {
				initializeGridGroupWithFullData(
					$scope,
					viewModel,
					ignoreCache,
					hdSourceService,
					loadGroupDataInGrid
				);
			}
		}

		function initializeGridGroup(
			$scope,
			viewModel,
			ignoreCache,
			hdSourceService,
			loadGroupDataInGrid
		) {
			hdSourceService
				.getSource($scope.group.source_id, 'GRID', viewModel.data.gridId)
				.then(function (response) {
					$scope.group.grammar = response.data.grammar;
					$scope.group.dataviewData = {
						auto_execute: true,
						hide_operations: true,
						hdType: 'GRID',
						hdId: viewModel.data.gridId,
					};
					$scope.group.dataviewData.afterDataLoaded = function (data, columns) {
						$scope.group.columns = columns;
						loadGroupDataInGrid(data);
					};
					$scope.group.dataviewData.jobDetails = $scope.jobDetails;
					$scope.group.dataviewData.dataview = {
						source_id: $scope.group.source_id,
						grammar: $scope.group.grammar,
						lib: $scope.group.lib,
						centering: viewModel.data.centering,
						ignore_cache_on_init: ignoreCache,
					};
				});
		}

		function initializeGridGroupWithFullData(
			$scope,
			viewModel,
			ignoreCache,
			hdSourceService,
			loadGroupDataInGrid
		) {
			$scope.group.grammar = viewModel.data.full_data.sources[0].grammar;
			$scope.group.dataviewData = {
				full_data: viewModel.data.full_data.sources[0],
				auto_execute: true,
				hide_operations: true,
				hdType: 'GRID',
				hdId: viewModel.data.gridId,
			};
			$scope.group.dataviewData.afterDataLoaded = function (data, columns) {
				$scope.group.columns = columns;
				loadGroupDataInGrid(data);
			};
			$scope.group.dataviewData.jobDetails = $scope.jobDetails;
			$scope.group.dataviewData.dataview = {
				source_id: $scope.group.source_id,
				grammar: $scope.group.grammar,
				lib: $scope.group.lib,
				centering: viewModel.data.centering,
				ignore_cache_on_init: ignoreCache,
			};
		}
	}
})();
