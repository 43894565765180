'use strict';

angular
	.module('dcApp')
	.controller('ChartListController', [
		'$location',
		'$scope',
		'$http',
		'$state',
		'$stateParams',
		'$rootScope',
		'ChartService',
		'toaster',
		'$q',
		'API_BASE_URL_BACKEND',
		'DEFAULT_CELL_TEMPLATE',
		'$timeout',
		'PermissionService',
		'gettextCatalog',
		'DcElementListService',
		function (
			$location,
			$scope,
			$http,
			$state,
			$stateParams,
			$rootScope,
			ChartService,
			toaster,
			$q,
			API_BASE_URL_BACKEND,
			DEFAULT_CELL_TEMPLATE,
			$timeout,
			PermissionService,
			gettextCatalog,
			DcElementListService
		) {
			let toasterPopSuccess = gettextCatalog.getString('Succès');
			let toasterPopDeleteChartSuccess = gettextCatalog.getString(
				'Suppression du Graphique effectuée'
			);
			let toasterPopDeleteChartsSuccess = gettextCatalog.getString(
				'Suppression des Graphiques effectuée'
			);
			let toasterPopDuplicateChartSuccess = gettextCatalog.getString(
				'Duplication Graphique effectuée'
			);

			$scope.checkPermissionData = {};
			$scope.currentProjectId = $rootScope.currentProjectId;
			PermissionService.haveGlobalPermission('chart-create').then(function (
				response
			) {
				$scope.create_authorised = response.data;
			});

			$scope.accessToken = window._keycloak.token;
			$scope.API_BASE_URL_BACKEND = API_BASE_URL_BACKEND;
			$scope.showCurrentProjectOnly = true;
			$scope.activeElementsOnly = false;
			$scope.bulkDelete = false;
			$scope.showDeleteModal = function (obj) {
				$scope.objToDelete = obj;
				$scope.bulkDelete = false;
				$scope.checkPermissionData.elementId = obj.id;
				$scope.checkPermissionData.type = 'chart';
				$scope.checkPermissionData.action = 'delete';
				$scope.checkPermissionData.actionIfPermitted = $scope.doShowDeleteModal;
				$scope.checkPermissionData.showPermissionCheck = true;
			};

			$scope.deleteBulkChartsModal = function () {
				$scope.bulkDelete = true;
				$scope.doShowDeleteModal($scope.selectedElems.length);
			};

			$scope.deleteBulkCharts = function () {
				let chartIds = _.map($scope.selectedElems, function (con) {
					return con.id;
				});
				ChartService.deleteCharts(chartIds)
					.then(function () {
						if ($scope.chart_authorised) {
							initCharts();
						}
						toaster.pop(
							'success',
							toasterPopSuccess,
							toasterPopDeleteChartsSuccess
						);
					})
					.catch(function (e) {
						if (e && e.data && e.data.code == '40' && $scope.chart_authorised) {
							initCharts();
						}
					});
			};

			$scope.editChart = function (obj) {
				$scope.objToEdit = obj;
				$scope.checkPermissionData.elementId = obj.id;
				$scope.checkPermissionData.type = 'chart';
				$scope.checkPermissionData.action = 'edit';
				$scope.checkPermissionData.actionIfPermitted = $scope.goToEdit;
				$scope.checkPermissionData.showPermissionCheck = true;
			};

			$scope.duplicateChart = function (obj) {
				ChartService.duplicateChart(obj.type, obj.id).then(function (response) {
					toaster.pop(
						'success',
						toasterPopSuccess,
						toasterPopDuplicateChartSuccess
					);
					$state.go('charts-edit', { id: response.data, type: obj.type });
				});
			};

			$scope.createChart = function () {
				$state.go('charts-select', {});
			};

			$scope.goToEdit = function () {
				$state.go('charts-edit', {
					id: $scope.objToEdit.id,
					type: $scope.objToEdit.type,
				});
			};

			$scope.viewChart = function (obj) {
				$state.go('charts-view', {
					id: obj.id,
					type: obj.type,
					mode: 'visualisation',
				});
			};

			$scope.deleteElementData = {};
			$scope.doShowDeleteModal = function (count) {
				$scope.deleteElementData = {
					label: $scope.objToDelete ? $scope.objToDelete.lc : undefined,
					doDelete: $scope.bulkDelete
						? $scope.deleteBulkCharts
						: $scope.deleteChart,
					showConfirmDeleteElement: true,
					bulk: $scope.bulkDelete,
					count: count,
				};
			};

			$scope.deleteChart = function () {
				ChartService.deleteChart(
					$scope.objToDelete.type,
					$scope.objToDelete.id
				).then(function (response) {
					afterDeleteChart();
				});
			};

			var afterDeleteChart = function () {
				toaster.pop('success', toasterPopSuccess, toasterPopDeleteChartSuccess);
				initCharts();
			};

			function getListCharts() {
				var deferred = $q.defer();
				ChartService.getAllCharts($scope.showCurrentProjectOnly, $scope.activeElementsOnly).then(
					function (response) {
						deferred.resolve(response.data);
					},
					function (err) {
						deferred.reject(err);
					}
				);

				return deferred.promise;
			}

			$scope.getGridData = function () {
				$scope.showGrid = false;
				getListCharts().then(function (content) {
					if (content !== undefined) {
						$scope.charts = [];
						$scope.tags = [];
						var tags = [];
						for (var r in content) {
							$scope.charts.push(
								DcElementListService.buildHdListElement(
									content[r],
									$rootScope,
									true
								)
							);
							tags = _.union(tags, content[r].metadata.tags);
						}

						for (var i in tags) {
							if (_.find($scope.tags, { id: tags[i].id }) == null) {
								$scope.tags.push({ id: tags[i].id, label: tags[i].code });
							}
						}
						$scope.showGrid = true;
						$scope.gridData = angular.copy($scope.charts);
						$scope.dataGridOptions.dataSource = $scope.gridData;
					}
				});
			};

			function initCharts() {
				PermissionService.haveGlobalPermission('chart').then(function (
					response
				) {
					$scope.chart_authorised = response.data;
					if ($scope.chart_authorised) {
						$scope.getGridData();
						$scope.disableAllSelectedElems = true;
					}
				});
			}

			$scope.moveOrCopyDataChart = {};
			$scope.moveOrCopyChartsToProject = function () {
				let connectorIds = _.map($scope.selectedElems, function (con) {
					return con.id;
				});
				$scope.moveOrCopyDataChart = {
					elements: connectorIds,
					type: 'chart',
					showMoveCopy: true,
				};
			};
			$scope.moveOrCopyOneChartToProject = function (elementId) {
				$scope.moveOrCopyDataChart = {
					elements: [elementId],
					type: 'chart',
					showMoveCopy: true,
				};
			};

			// Get DxGrid dataGridOptions
			DcElementListService.getDcElementDxList($scope, 'chart', false);
			initCharts();
		},
	])
	.controller('ChartEditController', [
		'$q',
		'$http',
		'API_BASE_URL_BACKEND',
		'$stateParams',
		'$state',
		'$timeout',
		'$scope',
		'$rootScope',
		'toaster',
		'ChartService',
		'GenericService',
		'PAGINATIONS_SIZES',
		'PAGINATIONS_SIZE',
		'$window',
		'PictogrammeService',
		'hdSourceService',
		'gettextCatalog',
		'PermissionService',
		function (
			$q,
			$http,
			API_BASE_URL_BACKEND,
			$stateParams,
			$state,
			$timeout,
			$scope,
			$rootScope,
			toaster,
			ChartService,
			GenericService,
			PAGINATIONS_SIZES,
			PAGINATIONS_SIZE,
			$window,
			PictogrammeService,
			hdSourceService,
			gettextCatalog,
			PermissionService
		) {
			// Help
			$scope.helpTitleTra = gettextCatalog.getString('Aide Graphiques');

			let toasterPopSuccess = gettextCatalog.getString('Succès');
			let toasterPopDeleteChartSuccess = gettextCatalog.getString(
				'Suppression du Graphique effectuée'
			);
			let toasterPopSaveConfChartSuccess = gettextCatalog.getString(
				'Enregistrement des paramètres effectué'
			);
			let toasterPopCreateChartSuccess = gettextCatalog.getString(
				'Création du Graphique effectuée'
			);
			let toasterPopUpdateChartSuccess = gettextCatalog.getString(
				'Modification du Graphique effectuée'
			);
			let toasterPopInvalidParam = gettextCatalog.getString(
				'Paramètres invalides'
			);

			$scope.accessToken =
				window._keycloak && window._keycloak.token
					? window._keycloak.token
					: '';
			$scope.checkPermissionData = {};
			$scope.metadata = {};
			$scope.metadata.actif = true;
			$scope.metadata.tags = [];
			$scope.permissionData = {};
			$scope.API_BASE_URL_BACKEND = API_BASE_URL_BACKEND;
			$scope.widgetData = {};
			$scope.iconsSelector = {};
			$scope.elementGraphData = {};
			$scope.dataModel = {};

			$scope.chartView = {};

			$scope.getHelpCode = function () {
				let code = 'CHART_';
				switch ($scope.type) {
					case 'barline':
						code = code + 'BAR_';
						break;
					case 'pie':
						code = code + 'PIE_';
						break;
					case 'treemap':
						code = code + 'TREEMAP_';
						break;
					case 'sankey':
						code = code + 'SANKEY_';
						break;
					case 'funnel':
						code = code + 'FUNNEL_';
						break;
					case 'gauge_circular':
					case 'gauge_linear':
					case 'gauge_bar':
						code = code + 'GAUGE_';
						break;
					default:
						code = code + '_';
				}

				return code + ($scope.isNew ? 'CREATE' : 'EDIT');
			};

			$scope.redirectionToList = function () {
				$('#confirmationModal').modal('hide');
				$timeout(function () {
					$state.go('charts');
				}, 300);
			};

			$scope.dataModel.redirectionToList = $scope.redirectionToList;

			$scope.deleteElementData = {};
			$scope.doShowDeleteModal = function () {
				$scope.deleteElementData = {
					label:
						$scope.chart && $scope.chart.metadata
							? $scope.chart.metadata.label
							: undefined,
					doDelete: $scope.deleteChart,
					showConfirmDeleteElement: true,
				};
			};

			$scope.showDeleteModal = function () {
				$scope.checkPermissionData.elementId = $scope.chart.id;
				$scope.checkPermissionData.type = 'chart';
				$scope.checkPermissionData.action = 'delete';
				$scope.checkPermissionData.actionIfPermitted = $scope.doShowDeleteModal;
				$scope.checkPermissionData.showPermissionCheck = true;
			};

			$scope.deleteChart = function () {
				ChartService.deleteChart($scope.chart.type, $scope.chart.id).then(
					function (response) {
						toaster.pop(
							'success',
							toasterPopSuccess,
							toasterPopDeleteChartSuccess
						);
						$timeout(function () {
							$state.go('charts');
						}, 300);
					}
				);
			};

			$scope.toggleShowTabs = function () {
				$scope.hideTabs = !$scope.hideTabs;
			};
			//handle data source directive
			$scope.onLoadSource = function (noAvailableSources) {
				$scope.sourceExists = !noAvailableSources;
				$scope.disabledConfig = noAvailableSources;
				if ($scope.disabledConfig) {
					$scope.disabledVisualisation = true;
				}
			};

			$scope.dataSource = {
				onOpenSource: $scope.toggleShowTabs,
				onLoadSource: $scope.onLoadSource,
			};
			//handle data source directive

			$scope.showDataBlockViews = function (saveSelectedDataBlockViews) {
				$scope.widgetData.saveSelectedDataBlockViews =
					saveSelectedDataBlockViews;
				$scope.widgetData.showDataBlockViews = true;
			};

			$scope.dataSource.showDataBlockViews = $scope.showDataBlockViews;

			$scope.showSources = function () {
				if (!$scope.configSaved && $scope.config && !$scope.disabledConfig) {
					$scope.saveConfig(showSources);
					return;
				}
				showSources();
			};
			//end

			var showSources = function () {
				delete $scope.chartView.chartId;
				$scope.dataSource.reinit();
				$scope.configSaved = true;
			};

			$scope.iconsSelector = {};

			$scope.selectIconForPositive = function () {
				$scope.config.global.positive_style = $scope.config.global
					.positive_style
					? $scope.config.global.positive_style
					: {};
				$scope.iconsSelector.saveSelection = function (icon) {
					$scope.config.global.positive_style.icon = icon;
				};
				$scope.iconsSelector.showIconsSelector = true;
			};

			$scope.selectIconForNegative = function () {
				$scope.config.global.negative_style = $scope.config.global
					.negative_style
					? $scope.config.global.negative_style
					: {};
				$scope.iconsSelector.saveSelection = function (icon) {
					$scope.config.global.negative_style.icon = icon;
				};
				$scope.iconsSelector.showIconsSelector = true;
			};

			$scope.showHdhGraph = function () {
				$scope.elementGraphData.centeredElementLabel =
					$scope.chart.metadata.label;
				$scope.elementGraphData.centeredElementType = 'CHART';
				$scope.elementGraphData.centeredElementId = $scope.chart.id;
				$scope.elementGraphData.showHdhElementGraph = true;
			};

			$scope.showPermissions = function () {
				$scope.checkPermissionData.elementId = $scope.chart.id;
				$scope.checkPermissionData.type = 'chart';
				$scope.checkPermissionData.action = 'permissions';
				$scope.checkPermissionData.actionIfPermitted = $scope.doShowPermissions;
				$scope.checkPermissionData.showPermissionCheck = true;
			};

			$scope.doShowPermissions = function () {
				$scope.permissionData.metadataId = $scope.chart.metadata.id;
				$scope.permissionData.type = 'chart';
				$scope.permissionData.elementId = $scope.chart.id;
				$scope.permissionData.lib = $scope.chart.metadata.label;
				$scope.permissionData.showElementPermissions = true;
			};

			$scope.showConfirmationModal = function () {
				$('#confirmationModal').modal('show');
			};

			$scope.showConfig = function () {
				delete $scope.chartView.chartId;
				$scope.configSaved = false;

				ChartService.getChartConfig($stateParams.type, $stateParams.id).then(
					function (response) {
						$scope.config = response.data;
						$scope.config.global.serie_template_ = $scope.config.global
							.serie_template
							? 'F'
							: 'A';
						ChartService.getChartOptions().then(function (
							responseChartOptions
						) {
							initConstants(responseChartOptions.data);
							if (
								$scope.config.global.title.text == undefined &&
								$scope.config.groups[0]
							) {
								$scope.config.global.title.text = $scope.config.groups[0].lib;
								$scope.config.global.title.subtitle.text =
									$scope.config.groups[0].desc;
							}
							if ($stateParams.type == 'treemap') {
								initTreemapConfig($scope.config);
							}
							if (
								$stateParams.type == 'sankey' ||
								$stateParams.type == 'indicator'
							) {
								$scope.disableLegend = true;
								$scope.disableZoom = true;
								$scope.disableScroll = true;
								$scope.disableRotated = true;
								$scope.disableTooltip = true;
							}
							if ($stateParams.type == 'indicator') {
								$scope.disablePalette = true;
								$scope.disableExport = true;
							}
							setGroupColumns(0);
						});
					}
				);
			};

			var initTreemapConfig = function (config) {
				config.global.range_values_txt = config.global.range_values
					? config.global.range_values.join(',')
					: '';
			};

			var setTreemapConfig = function (config) {
				if (config.global.range_values_txt) {
					config.global.range_values = _.map(
						config.global.range_values_txt.split(','),
						function (item) {
							return Number(item);
						}
					);
				}
			};

			var initConstants = function (data) {
				$scope.chartOptions = data;
			};

			var setGroupColumns = function (i) {
				if (!$scope.config.groups[i]) {
					clearInvalidColumnReferences($scope.config.global);
					return;
				}
				hdSourceService
					.getSourceColumns(
						$scope.config.groups[i].source_id,
						'CHART',
						$stateParams.id
					)
					.then(function (response) {
						$scope.config.groups[i].columns = response.data;
						$scope.config.groups[i].columns_uuids = _.map(
							response.data,
							function (item) {
								return item.uuid;
							}
						);
						setGroupColumns(i + 1);
					});
			};

			$scope.getSubTypes = function (type) {
				if (!$scope.chartOptions) {
					return [];
				}
				if (type == 'bar') {
					return $scope.chartOptions.bar_chart_sub_types;
				}
				if (type == 'line') {
					return $scope.chartOptions.line_chart_sub_types;
				}
				if (type == 'area') {
					return $scope.chartOptions.area_chart_sub_types;
				}
			};

			$scope.changeArgumentAxisColumn = function () {
				let col = _.find($scope.config.groups[0].columns, function (item) {
					return item.uuid == $scope.config.global.argument_axis.column;
				});
				$scope.config.global.argument_axis.title = col.lib;
				if (col.type == 'date') {
					$scope.config.global.argument_axis.argument_type = 'date';
					$scope.config.global.argument_axis.type = 'continuous';
				} else if (
					col.type == 'integer' ||
					col.type == 'big_integer' ||
					col.type == 'decimal'
				) {
					$scope.config.global.argument_axis.argument_type = 'numeric';
					$scope.config.global.argument_axis.type = 'continuous';
				} else {
					$scope.config.global.argument_axis.argument_type = 'string';
					$scope.config.global.argument_axis.type = 'discrete';
				}
			};

			$scope.changeArgumentAxisType = function () {
				if ($scope.config.global.argument_axis.argument_type == 'string') {
					delete $scope.config.global.argument_axis.aggregation_interval.value;
					delete $scope.config.global.argument_axis.aggregation_interval.type;
					delete $scope.config.global.argument_axis.tick_interval.value;
					delete $scope.config.global.argument_axis.tick_interval.type;
					delete $scope.config.global.argument_axis.label.format;
				} else if (
					$scope.config.global.argument_axis.argument_type == 'numeric'
				) {
					delete $scope.config.global.argument_axis.aggregation_interval.type;
					delete $scope.config.global.argument_axis.tick_interval.type;
					delete $scope.config.global.argument_axis.label.format;
				}
			};

			$scope.getArgumentAxisTypes = function () {
				if (!$scope.chartOptions) {
					return [];
				}
				if ($scope.config.global.argument_axis.argument_type == 'string') {
					return _.filter($scope.chartOptions.axis_types, function (item) {
						return item.code != 'logarithmic';
					});
				} else {
					return $scope.chartOptions.axis_types;
				}
			};

			$scope.changeArgumentAxisTypes = function () {
				if (!$scope.chartOptions) {
					return [];
				}
				if ($scope.config.global.argument_axis.argument_type == 'string') {
					_.filter($scope.chartOptions.axis_types, function (item) {
						return item.code != 'logarithmic';
					});
				} else {
					return $scope.chartOptions.axis_types;
				}
			};

			$scope.addConstant = function () {
				if (!$scope.config.global.value_axis) {
					$scope.config.global.value_axis = {};
				}
				if (!$scope.config.global.value_axis.constant_lines) {
					$scope.config.global.value_axis.constant_lines = [];
				}
				$scope.config.global.value_axis.constant_lines.push({});
			};

			$scope.addSerie = function () {
				if (!$scope.config.global.series) {
					$scope.config.global.series = [];
				}
				$scope.config.global.series.push({});
			};

			$scope.removeConstant = function (cons) {
				$scope.config.global.value_axis.constant_lines.splice(
					$scope.config.global.value_axis.constant_lines.indexOf(cons),
					1
				);
			};

			$scope.removeSerie = function (serie) {
				$scope.config.global.series.splice(
					$scope.config.global.series.indexOf(serie),
					1
				);
			};

			$scope.addRange = function () {
				if (!$scope.config.global.ranges) {
					$scope.config.global.ranges = [];
				}
				$scope.config.global.ranges.push({});
			};

			$scope.removeRange = function (range) {
				$scope.config.global.ranges.splice(
					$scope.config.global.ranges.indexOf(range),
					1
				);
			};

			$scope.addSubvalue = function () {
				if (!$scope.config.global.subvalues) {
					$scope.config.global.subvalues = [];
				}
				$scope.config.global.subvalues.push({});
			};

			$scope.removeValue = function (value) {
				$scope.config.global.values.splice(
					$scope.config.global.values.indexOf(value),
					1
				);
			};

			$scope.addValue = function () {
				if (!$scope.config.global.values) {
					$scope.config.global.values = [];
				}
				$scope.config.global.values.push({});
			};

			$scope.removeSubvalue = function (subvalue) {
				$scope.config.global.subvalues.splice(
					$scope.config.global.subvalues.indexOf(subvalue),
					1
				);
			};

			$scope.changeRotated = function () {
				if ($stateParams.type !== 'barline') {
					return;
				}
				if ($scope.config.global.rotated) {
					$scope.config.global.argument_axis.position = 'right';
					$scope.config.global.value_axis.position = 'bottom';
				} else {
					$scope.config.global.argument_axis.position = 'bottom';
					$scope.config.global.value_axis.position = 'right';
				}
			};

			$scope.getPositionsForArgumentAxis = function () {
				if ($scope.chartOptions) {
					return _.filter($scope.chartOptions.position_types, function (item) {
						return (
							($scope.config.global.rotated &&
								(item.code == 'left' || item.code == 'right')) ||
							(!$scope.config.global.rotated &&
								(item.code == 'top' || item.code == 'bottom'))
						);
					});
				}
				return [];
			};

			$scope.getVerticalAlignements = function () {
				if ($scope.chartOptions) {
					return _.filter($scope.chartOptions.alignment_types, function (item) {
						return (
							item.code == 'top' ||
							item.code == 'center' ||
							item.code == 'bottom'
						);
					});
				}
				return [];
			};

			$scope.getHorizontalAlignements = function () {
				if ($scope.chartOptions) {
					return _.filter($scope.chartOptions.alignment_types, function (item) {
						return (
							item.code == 'center' ||
							item.code == 'left' ||
							item.code == 'right'
						);
					});
				}
				return [];
			};

			$scope.getPositionsForValueAxis = function () {
				if ($scope.chartOptions) {
					return _.filter($scope.chartOptions.position_types, function (item) {
						return (
							(!$scope.config.global.rotated &&
								(item.code == 'left' || item.code == 'right')) ||
							($scope.config.global.rotated &&
								(item.code == 'top' || item.code == 'bottom'))
						);
					});
				}
				return [];
			};

			$scope.saveConfig = function (afterSave) {
				if (!checkConfig()) {
					return;
				}
				var config = angular.copy($scope.config);
				config.global.serie_template = config.global.serie_template_ == 'F';
				if (config.groups[0]) {
					config.groups = [{ id: config.groups[0].id, config: {} }];
				} else {
					config.groups = [];
				}
				if ($stateParams.type == 'treemap') {
					setTreemapConfig($scope.config);
				}
				ChartService.editChartConfig(
					$stateParams.type,
					$stateParams.id,
					config
				).then(function (response) {
					toaster.pop(
						'success',
						toasterPopSuccess,
						toasterPopSaveConfChartSuccess
					);
					$scope.disabledVisualisation = false;
					$scope.configIsValid = true;
					if (afterSave) {
						afterSave();
					}
				});
			};

			function checkConfig() {
				if ($scope.config.global.argument_axis) {
					if (
						$scope.config.global.argument_axis.aggregation_interval &&
						$scope.config.global.argument_axis.aggregation_interval.value &&
						!$scope.config.global.argument_axis.aggregation_interval.type
					) {
						toaster.pop(
							'error',
							toasterPopInvalidParam,
							gettextCatalog.getString(
								"Une valeur d'échelle doit être saisie avec l'option Agg. interval"
							)
						);
						return false;
					}
					if (
						$scope.config.global.argument_axis.aggregation_interval &&
						$scope.config.global.argument_axis.aggregation_interval.value &&
						!$scope.config.global.value_axis.aggregation
					) {
						toaster.pop(
							'error',
							toasterPopInvalidParam,
							gettextCatalog.getString(
								"Une fonction d'agrégation doit être saisie avec l'option Agg. interval"
							)
						);
						return false;
					}
					if (
						$scope.config.global.argument_axis.tick_interval &&
						$scope.config.global.argument_axis.tick_interval.value &&
						!$scope.config.global.argument_axis.tick_interval.type
					) {
						toaster.pop(
							'error',
							toasterPopInvalidParam,
							gettextCatalog.getString(
								"Une valeur d'échelle doit être saisie avec l'option label interval"
							)
						);
						return false;
					}
				}
				return true;
			}

			$scope.showVisualization = function () {
				if (!$scope.configSaved && $scope.config) {
					PermissionService.havePermission(
						$stateParams.id,
						'edit',
						'chart'
					).then(function (response) {
						if (response.data) {
							$scope.saveConfig(showVisualization);
						} else {
							showVisualization();
						}
					});
				} else {
					showVisualization();
				}
			};

			var showVisualization = function () {
				$scope.configSaved = true;
				if ($scope.sourceExists && $scope.configIsValid) {
					if (!$scope.chartView.chartId) {
						$scope.chartView.chartId = $stateParams.id;
					} else {
						$scope.chartView.reinit();
					}
				}
			};

			init();

			function init() {
				$scope.isNew = true;
				$scope.type = $stateParams.type;
				$scope.barType = $stateParams.barType;
				$scope.savingInProgress = false;
				if ($stateParams.id) {
					$scope.isNew = false;
					$scope.metadata.isNew = false;
					//if no source is available => deactivate config and visualisation tab. Else activate config tab
					hdSourceService
						.getNbrSourcesByElement('CHART', $stateParams.id)
						.then(function (response) {
							$scope.sourceExists = response.data;
							if ($scope.sourceExists) {
								ChartService.checkChartConfig(
									$stateParams.type,
									$stateParams.id
								).then(function (responseCheck) {
									$scope.configIsValid = responseCheck.data;
									if ($stateParams.mode == 'visualisation') {
										$('.nav-tabs a[data-target="#visualization"]').tab('show');
										$scope.showVisualization();
									} else {
										$('.nav-tabs a[data-target="#config"]').tab('show');
										$scope.disabledVisualisation = !$scope.configIsValid;
										$scope.showConfig();
									}
								});
							} else if ($stateParams.mode == 'visualisation') {
								$scope.disabledConfig = true;
								$('.nav-tabs a[data-target="#visualization"]').tab('show');
								$scope.showVisualization();
							} else {
								$scope.disabledConfig = true;
								$scope.disabledVisualisation = true;
							}
						});
					var chartId = $stateParams.id;
					$scope.dataSource.hdId = chartId;
					$scope.dataSource.hdType = 'CHART';
					$scope.dataSource.unableMultiSource = true;

					ChartService.getChart($stateParams.type, chartId).then(function (
						response
					) {
						$scope.chart = response.data;
						if ($scope.chart.sub_type) {
							$scope.barType = $scope.chart.sub_type;
						}
						$scope.metadata = $scope.chart.metadata;
						$scope.metadataLoaded = true;
					});
				} else {
					$scope.metadataLoaded = true;
				}
			}

			$scope.saveAndReturnToList = function () {
				$scope.saveChart(true);
			};

			$scope.dataModel.save = $scope.saveAndReturnToList;

			$scope.savingInProgress = false;
			let catchChartSaveErrors = function (error) {
				$scope.savingInProgress = false;
			};

			$scope.saveChart = function (returnToList) {
				$('#confirmationModal').modal('hide');
				// set metadata
				for (var t in $scope.metadata.tags) {
					$scope.metadata.tags[t].color =
						$scope.metadata.tags[t].color !== undefined
							? $scope.metadata.tags[t].color
							: '#dbf5d1';
				}

				var chart = {};

				chart.metadata = {
					active: $scope.metadata.actif,
					tags: $scope.metadata.tags,
					licence_type: $scope.metadata.licence_type,
					icon_id: $scope.metadata.icon_id,
					short_label: $scope.metadata.label,
					description: $scope.metadata.description,
				};

				$scope.savingInProgress = true;

				if ($scope.isNew) {
					chart.metadata.code = $scope.metadata.code;
					if ($scope.barType) {
						chart.default_type = $scope.barType;
					}
					ChartService.createChart($stateParams.type, chart)
						.then(function (response) {
							toaster.pop(
								'success',
								toasterPopSuccess,
								toasterPopCreateChartSuccess
							);
							if (returnToList) {
								$timeout(function () {
									$scope.savingInProgress = false;
									$state.go('charts');
								}, 300);
							} else {
								$timeout(function () {
									$scope.savingInProgress = false;
									$state.go('charts-edit', {
										id: response.data,
										type: $stateParams.type,
									});
								}, 300);
							}
						})
						.catch(catchChartSaveErrors);
				} else {
					ChartService.editChart($stateParams.type, $scope.chart.id, chart)
						.then(function (response) {
							toaster.pop(
								'success',
								toasterPopSuccess,
								toasterPopUpdateChartSuccess
							);
							$scope.savingInProgress = false;
							if (returnToList) {
								$timeout(function () {
									$state.go('charts');
								}, 300);
							}
						})
						.catch(catchChartSaveErrors);
				}
			};

			$scope.utcAllowed = true;
			$scope.openTooltip = function () {
				updateSortedColumns();
				$('#listTooltipColumnsModal').modal('show');
			};

			var updateSortedColumns = function () {
				if (!$scope.config.global.tooltip_columns) {
					$scope.config.global.tooltip_columns = [];
					$scope.config.global.tooltip_columns_patterns = {};
				}
				var sortedColumns = [];
				for (var c in $scope.config.global.tooltip_columns) {
					var col = _.find($scope.config.groups[0].columns, function (item) {
						return item.uuid == $scope.config.global.tooltip_columns[c];
					});
					if (col) {
						col.selected = true;
						if ($scope.config.global.tooltip_columns_patterns && $scope.config.global.tooltip_columns_patterns[col.uuid]) {
							col.pattern = $scope.config.global.tooltip_columns_patterns[col.uuid].pattern;
							col.precision = $scope.config.global.tooltip_columns_patterns[col.uuid].precision;
							col.showUtc = $scope.config.global.tooltip_columns_patterns[col.uuid].show_utc;
						}
						sortedColumns.push(col);
					}
				}

				for (let k in $scope.config.groups[0].columns) {
					if (sortedColumns.indexOf($scope.config.groups[0].columns[k]) < 0) {
						$scope.config.groups[0].columns[k].selected = false;
						sortedColumns.push($scope.config.groups[0].columns[k]);
					}
				}
				$scope.sortedColumns = sortedColumns;
			};

			$scope.saveTooltip = function () {
				$scope.config.global.tooltip_columns = [];
				$scope.config.global.tooltip_columns_patterns = {};
				for (var c in $scope.sortedColumns) {
					if ($scope.sortedColumns[c].selected) {
						$scope.config.global.tooltip_columns.push(
							$scope.sortedColumns[c].uuid
						);
						if ($scope.sortedColumns[c].pattern) {
							let precision = !isEmpty($scope.sortedColumns[c].precision) ? $scope.sortedColumns[c].precision : 2;
							let showUtc = !isEmpty($scope.sortedColumns[c].showUtc) ? $scope.sortedColumns[c].showUtc : false;
							$scope.config.global.tooltip_columns_patterns[$scope.sortedColumns[c].uuid] = {pattern: $scope.sortedColumns[c].pattern, precision: precision, show_utc: showUtc};
						} else {
							delete $scope.config.global.tooltip_columns_patterns[$scope.sortedColumns[c].uuid];
						}
					}
				}
				$('#listTooltipColumnsModal').modal('hide');
			};

			$scope.closeTooltip = function () {
				$('#listTooltipColumnsModal').modal('hide');
			};

			$scope.getPaletteColors = function (paletteName) {
				return paletteName
					? DevExpress.viz.getPalette(paletteName.replace('_', ' ')).simpleSet
					: [];
			};

			//for indicator chart
			$scope.deletePositiveIcon = function () {
				delete $scope.config.global.positive_style.icon;
				delete $scope.config.global.positive_style.icon_column;
			};

			$scope.deleteNegativeIcon = function () {
				delete $scope.config.global.negative_style.icon;
				delete $scope.config.global.negative_style.icon_column;
			};

			$scope.changeSerieTemplate = function () {
				$scope.config.global.series = [];
				delete $scope.config.global.serie_template_title;
				delete $scope.config.global.serie_template_field;
			};

			let clearInvalidColumnReferences = function (globalConfig) {
				if (isInvalidColumnReference(globalConfig.description_column)) {
					globalConfig.description_column = undefined;
				}
				if (isInvalidColumnReference(globalConfig.value)) {
					globalConfig.value = undefined;
				}
				if (isInvalidColumnReference(globalConfig.value_field)) {
					globalConfig.value_field = undefined;
				}
				if (isInvalidColumnReference(globalConfig.argument_field)) {
					globalConfig.argument_field = undefined;
				}
				if (isInvalidColumnReference(globalConfig.argument)) {
					globalConfig.argument = undefined;
				}
				if (isInvalidColumnReference(globalConfig.target_field)) {
					globalConfig.target_field = undefined;
				}
				if (isInvalidColumnReference(globalConfig.source_field)) {
					globalConfig.source_field = undefined;
				}
				if (isInvalidColumnReference(globalConfig.weight_field)) {
					globalConfig.weight_field = undefined;
				}
				if (isInvalidColumnReference(globalConfig.indicator)) {
					globalConfig.indicator = undefined;
				}
				if (isInvalidColumnReference(globalConfig.goal)) {
					globalConfig.goal = undefined;
				}

				if (isInvalidColumnReference(globalConfig.group1)) {
					globalConfig.group1 = undefined;
				}
				if (isInvalidColumnReference(globalConfig.group2)) {
					globalConfig.group2 = undefined;
				}
				if (isInvalidColumnReference(globalConfig.group3)) {
					globalConfig.group3 = undefined;
				}

				if (isInvalidColumnReference(globalConfig.serie_template_field)) {
					globalConfig.serie_template_field = undefined;
				}
				if (isInvalidColumnReference(globalConfig.start_value)) {
					globalConfig.start_value = undefined;
				}
				if (isInvalidColumnReference(globalConfig.end_value)) {
					globalConfig.end_value = undefined;
				}

				if (
					globalConfig.argument_axis &&
					isInvalidColumnReference(globalConfig.argument_axis.column)
				) {
					globalConfig.argument_axis.column = undefined;
				}

				if (
					globalConfig.value_axis &&
					isInvalidColumnReference(globalConfig.value_axis.column)
				) {
					globalConfig.value_axis.column = undefined;
				}

				if (globalConfig.scale) {
					clearInvalidColumnReferencesInScale(globalConfig.scale);
				}

				if (globalConfig.negative_style) {
					clearInvalidColumnReferencesInIndicatorStyle(
						globalConfig.negative_style
					);
				}
				if (globalConfig.positive_style) {
					clearInvalidColumnReferencesInIndicatorStyle(
						globalConfig.positive_style
					);
				}

				if (globalConfig.series) {
					clearInvalidColumnReferencesInSeries(globalConfig.series);
				}

				if (globalConfig.ranges) {
					clearInvalidColumnReferencesInRanges(globalConfig.ranges);
				}

				if (globalConfig.values) {
					clearInvalidColumnReferencesInValues(globalConfig.values);
				}

				if (globalConfig.subvalues) {
					clearInvalidColumnReferencesInSubValues(globalConfig.subvalues);
				}
			};

			let clearInvalidColumnReferencesInScale = function (scaleConfig) {
				scaleConfig.start_value = isInvalidColumnReference(
					scaleConfig.start_value
				)
					? undefined
					: scaleConfig.start_value;
				scaleConfig.end_value = isInvalidColumnReference(scaleConfig.end_value)
					? undefined
					: scaleConfig.end_value;
			};

			let clearInvalidColumnReferencesInIndicatorStyle = function (
				styleConfig
			) {
				styleConfig.note_column = isInvalidColumnReference(
					styleConfig.note_column
				)
					? undefined
					: styleConfig.note_column;
				styleConfig.color_column = isInvalidColumnReference(
					styleConfig.color_column
				)
					? undefined
					: styleConfig.color_column;
				styleConfig.icon_column = isInvalidColumnReference(
					styleConfig.icon_column
				)
					? undefined
					: styleConfig.icon_column;
			};

			let clearInvalidColumnReferencesInSeries = function (series) {
				series.forEach((serie) => {
					serie.value_field = isInvalidColumnReference(serie.value_field)
						? undefined
						: serie.value_field;
					serie.range_value1_field = isInvalidColumnReference(
						serie.range_value1_field
					)
						? undefined
						: serie.range_value1_field;
					serie.range_value2_field = isInvalidColumnReference(
						serie.range_value2_field
					)
						? undefined
						: serie.range_value2_field;
				});
			};

			let clearInvalidColumnReferencesInRanges = function (ranges) {
				ranges.forEach((range) => {
					range.start_value = isInvalidColumnReference(range.start_value)
						? undefined
						: range.start_value;
					range.color_column = isInvalidColumnReference(range.color_column)
						? undefined
						: range.color_column;
					range.end_value = isInvalidColumnReference(range.end_value)
						? undefined
						: range.end_value;
				});
			};

			let clearInvalidColumnReferencesInValues = function (values) {
				values.forEach((value) => {
					value.value = isInvalidColumnReference(value.value)
						? undefined
						: value.value;
					value.color_column = isInvalidColumnReference(value.color_column)
						? undefined
						: value.color_column;
				});
			};

			let clearInvalidColumnReferencesInSubValues = function (subvalues) {
				subvalues.forEach((subvalue) => {
					subvalue.value = isInvalidColumnReference(subvalue.value)
						? undefined
						: subvalue.value;
				});
			};

			let isInvalidColumnReference = function (columnReference) {
				return (
					columnReference &&
					$scope.config.groups[0].columns_uuids.indexOf(columnReference) < 0
				);
			};
		},
	]);
