(function () {
	'use strict';

	angular.module('dcApp').factory('ElementsLinkGenerator', [
		'$state',
		function ($state) {
			var ElementsLinkGenerator = {};

			ElementsLinkGenerator.getLinkAndGo = function (el) {
				let url = '#';
				if (el.type === 'DEPOT') {
					url = goToDepot(el);
				} else if (el.type === 'ENTITETYPE') {
					url = goToEntity(el);
				} else if (el.type === 'DATABLOCK') {
					url = goToDataBlock(el);
				} else if (el.type === 'GRID') {
					url = goToGrid(el);
				} else if (el.type === 'DASHBOARD') {
					url = goToDashboard(el);
				} else if (el.type === 'MAP') {
					url = goToMap(el);
				} else if (el.type === 'TIMELINE') {
					url = goToTimeLine(el);
				} else if (el.type === 'CHART') {
					url = goToChart(el);
				} else if (el.type === 'DATABLOCK_EXPORT_TEMPLATE') {
					url = gotToExportTemplate(el);
				} else if (el.type === 'EXPOSITION') {
					url = gotToExposition(el);
				}
				window.open(url, '_blank');
			}

			ElementsLinkGenerator.goToProject = function(projectId) {
				let url = $state.href('projects-edit', { projectId: projectId });
				window.open(url, '_blank');
			}

			function goToDepot(depot) {
				let url = '#';
				if (depot.sub_type) {
					let subType = depot.sub_type.toUpperCase();
					if (subType.indexOf('CSV') > -1) {
						url = $state.href('depots-edit', { id: depot.id, type: 'CSV' });
					} else if (subType.indexOf('JSON') > -1) {
						url = $state.href('depots-edit', {
							id: depot.id,
							type: 'JSON',
						});
					} else if (subType.indexOf('PARQUET') > -1) {
						url = $state.href('depots-edit', {
							id: depot.id,
							type: 'PARQUET',
						});
					} else if (subType.indexOf('BINARY') > -1) {
						url = $state.href('depots-edit', {
							id: depot.id,
							type: 'BINARY',
						});
					} else if (subType.indexOf('EXCEL') > -1) {
						url = $state.href('depots-edit', {
							id: depot.id,
							type: 'EXCEL',
						});
					} else if (subType.indexOf('SQL') > -1) {
						url = $state.href('depots-edit', { id: depot.id, type: 'SQL' });
					}
				}

				return url;
			}

			function goToEntity(entity) {
				return $state.href('entites-edit', { entiteId: entity.id });
			}

			function goToDataBlock(db) {
				return $state.href('datablocks-edit', { id: db.id });
			}

			function goToGrid(grid) {
				return $state.href('grids-edit', {
					id: grid.id,
					type: grid.sub_type,
				});
			}

			function goToDashboard(dashboard) {
				return $state.href('dashboards-edit', { id: dashboard.id });
			}

			function goToMap(map) {
				return $state.href('maps-edit', { id: map.id });
			}

			function goToTimeLine(timeLine) {
				return $state.href('timelines-edit', { id: timeLine.id });
			}

			function goToChart(chart) {
				return $state.href('charts-edit', {
					id: chart.id,
					type: chart.sub_type,
				});
			}

			function gotToExportTemplate(exportTemplate) {
				return $state.href('export-template-edit', {
					exportConfigId: exportTemplate.id
				});
			}

			function gotToExposition(exposition) {
				return $state.href('exposition-view', {
					expositionId: exposition.id
				});
			}

			return ElementsLinkGenerator;
		},
	]);
})();
