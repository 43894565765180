(function () {
	'use strict';

	angular.module('dcApp').directive('stepInputMapping', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$timeout',
				'GenericService',
				'DataBlocksService',
				'toaster',
				'$sce',
				'gettextCatalog',
				'CaracService',
				'DATA_TYPES',
				'GrammarUtils',
				function (
					$scope,
					$timeout,
					GenericService,
					DataBlocksService,
					toaster,
					$sce,
					gettextCatalog,
					CaracService,
					DATA_TYPES,
					GrammarUtils
				) {
					var ModificationMode = {
						AddNewColumn: 'newColumn',
						EditColumn: 'editColumn',
					};

					var vm = this;
					$scope.columnModificationMode = ModificationMode.AddNewColumn;
					$scope.currentColumn = null;
					$scope.columnFilterText = '';
					$scope.columnsSortMode = 1;
					$scope.sourceColumnsSortMode = 1;
					$scope.sortableOptions = {
						disabled: false,
						handle: '#move-handle',
						stop: function () {
							$scope.tmpColumns = $scope.filteredColumns;
						},
					};
					$scope.types = _.each(DATA_TYPES, function (e) {
						e.label = gettextCatalog.getString(e.lib);
					});

					$scope.getHtmlIconByType = function (type, isList) {
						if (type != undefined) {
							type = type.toLowerCase();
							return $sce.trustAsHtml(getHtmlIconByType(type, isList, GrammarUtils.getTypeLabel(type)));
						}
					};

					$scope.init = function () {
						$scope.sourceColumns = $scope.vm.data.columns;
						$scope.sourceColumnsUuids = _.map($scope.sourceColumns, function(item)  { return item.uuid;})
						if (vm.data.step.input) {
							$scope.tmpColumns = angular.copy(vm.data.step.input.columns);
							for (var c in $scope.sourceColumns) {
								let col = $scope.sourceColumns[c];
								if (
									!_.find($scope.tmpColumns, function (item) {
										return (
											item.uuid === col.uuid || item.column_uuid === col.uuid
										);
									})
								) {
									$scope.tmpColumns.push({
										uuid: col.uuid,
										column_uuid: col.uuid,
										label: col.lib,
										type: col.type,
										is_list: col.is_list,
									});
								}
							}
						} else {
							$scope.tmpColumns = [];
							for (var c in $scope.sourceColumns) {
								let col = $scope.sourceColumns[c];
								$scope.tmpColumns.push({
									uuid: col.uuid,
									column_uuid: col.uuid,
									label: col.lib,
									type: col.type,
									is_list: col.is_list,
								});
							}
						}
						_.each($scope.tmpColumns, function (column) {
							if (column.column_uuid) {
								let sourceCol = _.find($scope.sourceColumns, function (item) {
									return item.uuid === column.column_uuid;
								});
								column.source_label = sourceCol ? sourceCol.lib : '';
							}
							column.fromPreviousStep = $scope.sourceColumnsUuids.indexOf(column.uuid) > -1;
						});

						$scope.filteredColumns = $scope.tmpColumns;
					};

					$scope.getColumns = function (column) {
						return _.filter($scope.sourceColumns, function (item) {
							return (
								item.type === column.type &&
								((item.is_list && column.is_list) ||
									(!item.is_list && !column.is_list))
							);
						});
					};

					$scope.filterColumns = function () {
						$scope.sortableOptions.disabled = $scope.columnFilterText !== '';
						$scope.sourceColumnFilterText = '';
						$scope.filteredColumns = _.filter($scope.tmpColumns, function (el) {
							return (
								el.label
									.toLowerCase()
									.indexOf($scope.columnFilterText.toLowerCase()) > -1
							);
						});
					};

					$scope.filterSourceColumns = function () {
						$scope.columnFilterText = '';
						$scope.filteredColumns = _.filter($scope.tmpColumns, function (el) {
							return (
								el.source_label
									.toLowerCase()
									.indexOf($scope.sourceColumnFilterText.toLowerCase()) > -1
							);
						});
					};

					$scope.sortColumns = function () {
						$scope.filteredColumns = _.sortBy(
							$scope.filteredColumns,
							function (item) {
								return item.label.trim().toUpperCase();
							}
						);
						if ($scope.columnsSortMode === 1) {
							$scope.filteredColumns = $scope.filteredColumns.reverse();
						}
						$scope.columnsSortMode = -$scope.columnsSortMode;
					};

					$scope.sortSourceColumns = function () {
						$scope.filteredColumns = _.sortBy(
							$scope.filteredColumns,
							function (item) {
								return item.source_label
									? item.source_label.trim().toUpperCase()
									: '';
							}
						);
						if ($scope.sourceColumnsSortMode === 1) {
							$scope.filteredColumns = $scope.filteredColumns.reverse();
						}
						$scope.sourceColumnsSortMode = -$scope.sourceColumnsSortMode;
					};

					$scope.changeColumnMapping = function (column) {
						delete column.source_label;
						if (column.column_uuid) {
							column.source_label = _.find(
								$scope.sourceColumns,
								function (item) {
									return column.uuid === column.column_uuid;
								}
							).lib;
						}
					};

					$scope.editColumn = function (column) {
						$scope.columnModificationMode = ModificationMode.EditColumn;
						$scope.currentColumn = {
							uuid: column.uuid,
							active: true,
							type: column.type,
							label: column.label,
							is_list: column.is_list,
							fromPreviousStep: column.fromPreviousStep
						};
						$('#newColumnModal').modal('show');
					};

					$scope.addNewColumn = function () {
						$scope.columnModificationMode = ModificationMode.AddNewColumn;
						$scope.currentColumn = {
							uuid: generateUuid('_'),
							active: true,
							type: 'string',
							label: '',
							is_list: false,
							fromPreviousStep: false,
							newColumn: true
						};
						$('#newColumnModal').modal('show');
					};

					$scope.saveColumn = function () {
						if ($scope.columnModificationMode === ModificationMode.EditColumn) {
							saveModifiedColumn($scope.currentColumn);
							return;
						}
						saveNewColumn();
					};

					$scope.cancelColumnEdit = function () {
						if ($scope.columnModificationMode === ModificationMode.EditColumn) {
							cancelModifiedColumn();
							return;
						}
						cancelNewColumn();
					};

					$scope.deleteColumn = function (column) {
						if (column.column_uuid || column.fromPreviousStep) {
							return;
						}
						$scope.tmpColumns.splice($scope.tmpColumns.indexOf(column), 1);
					};
					$scope.save = function () {
						vm.data.saveInputMethod(
							{ columns: $scope.tmpColumns },
							$scope.saveAndClose
						);
					};

					$scope.saveAndClose = function () {
						vm.closeModal();
						toaster.pop(
							'success',
							gettextCatalog.getString('Succès'),
							gettextCatalog.getString('Enregistrement effectué')
						);
						vm.clean();
					};

					vm.closeModal = function () {
						$(vm.element).modal('hide');
						vm.data.showStepInputMapping = false;
					};

					vm.clean = function () {
						$scope.columnFilterText = '';
						$scope.sourceColumnFilterText = '';
						$scope.columnsSortMode = 1;
						$scope.sourceColumnsSortMode = 1;
						$scope.sortableOptions.disabled = false;
					};

					function saveNewColumn() {
						$scope.tmpColumns.push($scope.currentColumn);
						$scope.filteredColumns = $scope.tmpColumns;
						$('#newColumnModal').modal('hide');
					}

					function cancelNewColumn() {
						delete $scope.currentColumn;
						$('#newColumnModal').modal('hide');
					}

					function saveModifiedColumn(modified) {
						var colToSave = _.find($scope.tmpColumns, function (col) {
							return col.uuid === modified.uuid;
						});
						colToSave.label = modified.label;
						$('#newColumnModal').modal('hide');
					}

					function cancelModifiedColumn() {
						$('#newColumnModal').modal('hide');
					}
				},
			];

			return buildDirectiveReturnObject(
				{ data: '=' },
				controller,
				'./src/components/directives/dataOperationTools/stepInputMapping/stepInputMapping.html',
				function postLink(scope, element, attrs) {
					initElementForDirective(element, attrs, function () {
						scope.vm.closeModal();
					});
					watchElementForDirective(
						element,
						scope,
						function () {
							return scope.vm.data.showStepInputMapping;
						},
						function () {
							scope.init();
						}
					);
				}
			);
		},
	]);
})();
