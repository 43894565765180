(function () {
	'use strict';

	angular
		.module('dcApp')
		.controller(
			'DashBoardPagesConfigurationController',
			DashBoardPagesConfigurationController
		);

	DashBoardPagesConfigurationController.$inject = ['$scope', 'DashboardService', 'gettextCatalog', 'toaster', 'ModalService'];

	function DashBoardPagesConfigurationController($scope, DashboardService, gettextCatalog, toaster, ModalService) {
		var vm = this;
		const PAGE_MAX = 10;
		var pageToDeleteIndex = undefined;

		vm.sortableOptions = {};
		vm.pages = vm.pages ? vm.pages : [];
		vm.addNewPage = addNewPage;
		vm.deleteConfirmationData = {};
		vm.deletePageRequest = deletePageRequest;

		function addNewPage() {
			if (vm.pages && vm.pages.length == PAGE_MAX) {
				let pageLimitReachErrorTitle = gettextCatalog.getString('dashboard.pages.error.limit.reached.title');
				let pageLimitReachErrorMsg = gettextCatalog.getString('dashboard.pages.error.limit.reached.msg');
				toaster.pop('error', pageLimitReachErrorTitle, pageLimitReachErrorMsg);
				return;
			}
			if (!vm.pages) {
				vm.pages = [];
			}
			const count = vm.pages.length;
			const newPage = DashboardService.generatePage(count);
			vm.pages.push(newPage);
		}

		function deletePageRequest(pageTitle, pageIndex) {
			if (vm.pages.length === 1) {
				const errorMsg = gettextCatalog.getString('dashboard.page.delete.last.error');
				const errorMsgTitle = gettextCatalog.getString('dashboard.page.delete.last.error.title');
				toaster.pop('error', errorMsgTitle, errorMsg);
				return;
			}
			pageToDeleteIndex = pageIndex;
			vm.deleteConfirmationData.confirmationDialogTitle = gettextCatalog.getString('dashboard.page.delete.confirmation.title', {title: pageTitle});
			vm.deleteConfirmationData.confirmationDialogMessage = gettextCatalog.getString('dashboard.page.delete.confirmation.message');
			vm.deleteConfirmationData.confirmationDialogActionName = gettextCatalog.getString('dashboard.page.delete.confirmation.action-label');
			vm.deleteConfirmationData.confirmationGreenAction = false;
			vm.deleteConfirmationData.enableConfirmDialogAction = false;
			vm.deleteConfirmationData.enableConfirmDialogAction = true;
			vm.deleteConfirmationData.confirmDialogAction = deletePage;
			vm.deleteConfirmationData.showConfirmationDialog = true;
		}

		function deletePage() {
			vm.pages.splice(pageToDeleteIndex, 1);
			pageToDeleteIndex = undefined;
		}
	}
})();
