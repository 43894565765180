(function () {
	'use strict';

	angular.module('dcApp').directive('scatterchartview', [
		'$parse',
		function ($parse) {
			scatterchartviewCtrl.$inject = [
				'$scope',
				'$state',
				'$window',
				'$filter',
				'$timeout',
				'gettextCatalog',
				'ChartService',
				'hdSourceService',
				'PermissionService',
				'ChartViewCommonFactory',
				'DX_RERENDER_TIMEOUT',
			];

			function scatterchartviewCtrl(
				$scope,
				$state,
				$window,
				$filter,
				$timeout,
				gettextCatalog,
				ChartService,
				hdSourceService,
				PermissionService,
				ChartViewCommonFactory,
				DX_RERENDER_TIMEOUT
			) {
				var vm = this;
				vm.init = function (ignoreCache) {
					ChartViewCommonFactory.initialize(
						'scatter',
						$scope,
						checkConfig,
						loadGroupDataInChart,
						ignoreCache
					);
				};

				ChartViewCommonFactory.initializeDirectiveScope($scope, vm, 'scatter');

				$scope.showPopover = function ($event, obj) {
					if (Object.keys(obj).length === 0) {
						$scope.noFilterApplied = true;
					} else {
						$scope.noFilterApplied = false;
					}
					// Set a bigger z-index
					$(function () {
						DevExpress.ui.dxOverlay.baseZIndex(1999999998);
					});
				};

				function initPopover() {
					$scope.sourceCentring = vm.data.centering
						? vm.data.centering.source_centering
						: {};
					$scope.filterDetailsPopover = {
						target: '#filterInfoPopover' + $scope.uuid,
						showEvent: 'dxclick',
						position: 'top',
						width: 450,
						bindingOptions: {
							visible: 'visibleMetaData',
						},
					};
				}

				var loadGroupDataInChart = function (data) {
					$scope.config.global.commonSeriesSettings = {
						type: 'scatter',
						argumentField: $scope.config.global.argumentAxis.column,
					};
					initPopover();
					if (!$scope.config.global.series) {
						$scope.config.global.series = [];
					}

					if (!$scope.config.global.legend.visible) {
						$scope.config.global.legend.visible = false;
					}

					let valueAxis = [];

					for (var s in $scope.config.global.series) {
						if ($scope.config.global.series[s].separatedAxis) {
							valueAxis.push({
								position: 'right',
								title: $scope.config.global.series[s].name,
								name: $scope.config.global.series[s].valueField,
							});
							$scope.config.global.series[s].axis =
								$scope.config.global.series[s].valueField;
						}
						if ($scope.config.global.series[s].aggregation) {
							$scope.config.global.series[s].aggregation = {
								enabled: true,
								method: $scope.config.global.series[s].aggregation,
							};
						}
						$scope.config.global.series[s].point = {
							symbol: $scope.config.global.series[s].symbol,
							size: $scope.config.global.series[s].pointSize
								? $scope.config.global.series[s].pointSize
								: 7,
						};
						$scope.config.global.series[s].argumentField =
							$scope.config.global.argumentAxis.column;
					}

					if (
						$scope.config.global.argumentAxis.tickInterval &&
						$scope.config.global.argumentAxis.tickInterval.value
					) {
						var tickInterval = {};
						tickInterval[$scope.config.global.argumentAxis.tickInterval.type] =
							$scope.config.global.argumentAxis.tickInterval.value;
						$scope.config.global.argumentAxis.tickInterval = tickInterval;
					} else {
						delete $scope.config.global.argumentAxis.tickInterval;
					}

					$scope.config.global.argumentAxis.aggregateByCategory = true;

					var column = _.find($scope.group.columns, function (item) {
						return item.uuid == $scope.config.global.valueAxis.column;
					});

					if ($scope.config.global.serieTemplate) {
						$scope.config.global.commonSeriesSettings.valueField =
							$scope.config.global.valueAxis.column;
						$scope.config.global.commonSeriesSettings.point = {
							symbol: $scope.config.global.valueAxis.symbol,
							size: $scope.config.global.valueAxis.pointSize
								? $scope.config.global.valueAxis.pointSize
								: 7,
						};
						$scope.config.global.commonSeriesSettings.dashStyle =
							$scope.config.global.valueAxis.dashStyle;
						$scope.config.global.seriesTemplate = {
							nameField: $scope.config.global.serieTemplateField,
						};
						delete $scope.config.global.series;
					} else {
						let valueSerie = {
							valueField: $scope.config.global.valueAxis.column,
							name: !isEmpty($scope.config.global.valueAxis.title)  ? $scope.config.global.valueAxis.title : column.lib,
							point: {
								symbol: $scope.config.global.valueAxis.symbol,
								size: $scope.config.global.valueAxis.pointSize
									? $scope.config.global.valueAxis.pointSize
									: 7,
							},
							color: $scope.config.global.valueAxis.color,
							dashStyle: $scope.config.global.valueAxis.dashStyle,
							argumentField: $scope.config.global.argumentAxis.column,
						};
						if ($scope.config.global.valueAxis.aggregation) {
							valueSerie.aggregation = {
								enabled: true,
								method: $scope.config.global.valueAxis.aggregation,
							};
						}
						delete $scope.config.global.valueAxis.aggregation;
						$scope.config.global.series.push(valueSerie);
					}

					$scope.config.global.tooltipColumns = _.filter(
						$scope.config.global.tooltipColumns,
						function (item) {
							return _.find($scope.group.columns, function (col) {
								return col.uuid == item;
							});
						}
					);
					if (
						$scope.config.global.tooltipColumns &&
						$scope.config.global.tooltipColumns[0]
					) {
						$scope.config.global.tooltip = {
							enabled: true,
							location: 'edge',
							customizeTooltip: function (arg) {
								return {
									html: ChartService.getTooltip(
										arg.point.data,
										$scope.config.global.tooltipColumns,
										$scope.config.global.tooltipColumnsPatterns,
										$scope.group.columns,
										$scope.config.global.argumentAxis
									),
								};
							},
						};
					}

					$scope.config.global.argumentAxis.grid = {
						visible: $scope.config.global.argumentAxis.grid,
					};
					$scope.config.global.argumentAxis.minorGrid = {
						visible: $scope.config.global.argumentAxis.minorGrid,
					};
					$scope.dxChartOptions = angular.copy($scope.config.global);
					// add valueAxis
					if (valueAxis.length > 0) {
						let mainAxisVal = $scope.dxChartOptions.valueAxis;
						valueAxis.unshift({
							name: mainAxisVal.column,
							title: { text: mainAxisVal.title },
						});
						$scope.dxChartOptions.valueAxis = valueAxis;
					}
					$scope.dxChartOptions.dataSource = data;
					$('#chart' + $scope.uuid).dxChart($scope.dxChartOptions);
					$timeout(function () {
						$('#chart' + $scope.uuid)
							.dxChart('instance')
							.render();
					}, DX_RERENDER_TIMEOUT);

					ChartViewCommonFactory.chartAutoResizing(
						$scope,
						$('#chart' + $scope.uuid).dxChart('instance'),
						DX_RERENDER_TIMEOUT
					);

					return true;
				};

				var checkConfig = function (config) {
					if (
						!config.global.value_axis.column ||
						!config.global.argument_axis.column
					) {
						return false;
					}
					return true;
				};
			}

			return buildDirectiveReturnObject(
				{ data: '=' },
				scatterchartviewCtrl,
				'./src/components/directives/handledata/chartView/scatter/chartView.html',
				function postLink(scope, element, attrs) {
					if (!scope.vm.data.full_data) {
						scope.jobDetails = { rerunMethod: scope.rerunWithoutCache };
						scope.jobDetailsId = generateUuid('_');
					}
					scope.vm.init(false);
				}
			);
		},
	]);
})();
