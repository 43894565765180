(function () {
	'use strict';

	angular.module('dcApp').directive('importProjectDetails', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$rootScope',
				'elem',
				function ($scope, $rootScope, elem) {
					var vm = this;
					vm.elem = elem;
					vm.init = function () {
						vm.data =
							vm.data && Object.keys(vm.data).length !== 0
								? vm.data
								: { contexts: [] };

						if (vm.contexts) {
							for (let i = 0; i < vm.contexts.length; i++) {
								let context = {
									context_id: vm.contexts[i].id,
									persistence: false,
									jobs: false,
								};
								vm.data.contexts.push(context);
							}
							// if only one context select it by default
							if (vm.contexts.length === 1) {
								vm.data.contexts[0].persistence = true;
								vm.data.contexts[0].jobs = true;
							}
						}

						$scope.$emit('importProjectDetails:validity', false);
					};

					vm.checkDateValidity = function (type) {
						if (type === 'effect' && vm.data.importedEffectDate) {
							var end = moment(vm.data.importedEffectDate).format();
							var now = moment(new Date()); //todays date
							var duration = moment.duration(now.diff(end));
							return duration.asDays() > 0;
						}
						if (type === 'expire' && vm.data.importExpirationDate) {
							var end = moment(vm.data.importExpirationDate).format();
							var now = moment(new Date()); //todays date
							var duration = moment.duration(now.diff(end));
							return duration.asDays() > 0;
						}
					};

					vm.toggleCtxConfig = function (newVal, ctxIdx) {
						vm.data.contexts = vm.data.contexts.reduce((acc, curr, idx) => {
							if (ctxIdx !== idx) {
								acc.push(curr);
								return acc;
							}
							acc.push(newVal);
							return acc;
						}, []);
						vm.checkContexts(vm.data.contexts);
					};

					vm.checkContexts = function (contexts) {
						let ctxs = contexts || vm.data.contexts;
						const hasPersistenceContext = ctxs.reduce((acc, curr) => {
							return acc || curr.persistence;
						}, false);
						const hasOtherJobsContext = ctxs.reduce((acc, curr) => {
							return acc || curr.jobs;
						}, false);
						$scope.$emit(
							'importProjectDetails:validity',
							hasPersistenceContext &&
								hasOtherJobsContext &&
								vm.data.importedProjectLabel &&
								vm.data.importedProjectLabel !== ''
						);
					};
				},
			];
			return buildDirectiveReturnObject(
				{
					data: '=',
					projectInfos: '=',
					contexts: '=',
				},
				controller,
				'./src/components/directives/importProject/importProjectDetails.html',
				function postLink(scope, element, attrs, $ctrl) {
					$ctrl.init();

					scope.$watch('importDetailsForm.$valid', function (newVal) {
						// in case form is valid and more then one context exist => checks if one context is selected per job type
						if (newVal) {
							return $ctrl.checkContexts($ctrl.data.contexts);
						}
						scope.$emit('importProjectDetails:validity', false);
					});

					scope.$watch(
						function () {
							return $ctrl.data.importedProjectLabel;
						},
						function (newVal) {
							// in case changes to label => checks if one context is selected per job type
							if (newVal) {
								return $ctrl.checkContexts($ctrl.data.contexts);
							}
							scope.$emit('importProjectDetails:validity', false);
						}
					);

					initElementForDirective(element, attrs, function () {
						$ctrl.data = undefined;
					});
					watchElementForDirective(
						element,
						scope,
						function () {
							return $ctrl.data;
						},
						function () {},
						true
					);
				}
			);
		},
	]);
})();
