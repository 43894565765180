(function () {
	'use strict';

	angular.module('dcApp').directive('tlview', [
		'$parse',
		function ($parse) {
			var controller = [
				'$rootScope',
				'$scope',
				'$state',
				'$http',
				'API_BASE_URL_BACKEND',
				'TimeLineService',
				'hdSourceService',
				'$timeout',
				'mainTimeline',
				'subTimeline',
				'timelineDataProvider',
				'PAGINATIONS_SIZES',
				'PAGINATIONS_SIZE',
				'$window',
				'PermissionService',
				'gettextCatalog',
				'DateService',
				'CommonServices',
				function (
					$rootScope,
					$scope,
					$state,
					$http,
					API_BASE_URL_BACKEND,
					TimeLineService,
					hdSourceService,
					$timeout,
					mainTimelineService,
					subTimelineService,
					dataProvider,
					PAGINATIONS_SIZES,
					PAGINATIONS_SIZE,
					$window,
					PermissionService,
					gettextCatalog,
					DateService,
					CommonServices
				) {
					var vm = this;
					let timeline, subTimeline, timelineEvents;
					let seeTheTableTra = gettextCatalog.getString('Voir dans le tableau');
					let centerTableTra = gettextCatalog.getString('Centrer');
					let timelineSammaryGrid = {
						paging: {
							enabled: true,
							pageSize: PAGINATIONS_SIZE,
						},
						pager: {
							showPageSizeSelector: true,
							allowedPageSizes: PAGINATIONS_SIZES,
							showInfo: true,
							visible: true,
						},
						filterRow: {
							visible: true,
							applyFilter: 'auto',
						},
						rowAlternationEnabled: true,
						headerFilter: {
							visible: false,
							applyFilter: 'auto',
						},
						showColumnLines: true,
						showRowLines: true,
						allowColumnReordering: true,
						allowColumnResizing: true,
						columnAutoWidth: true,
						showBorders: true,
						columnFixing: {
							enabled: true,
						},
						columns: [
							{
								caption: gettextCatalog.getString('Nombre'),
								dataField: 'cpt',
								dataType: 'number',
								width: 100,
							},
							{
								caption: gettextCatalog.getString('Date Evt'),
								dataField: 'start',
							},
							{
								caption: gettextCatalog.getString('Fin'),
								dataField: 'end',
							},
							{
								caption: gettextCatalog.getString('Durée'),
								dataField: 'duration',
							},
							{
								caption: gettextCatalog.getString('Groupe'),
								dataField: 'group',
								cellTemplate: 'group',
							},
							{
								caption: gettextCatalog.getString('Détails'),
								dataField: 'details',
								cellTemplate: 'details',
							},
							{
								caption: gettextCatalog.getString('Temps 1'),
								dataField: 'time1',
							},
							{
								caption: gettextCatalog.getString('Temps 2'),
								dataField: 'time2',
							},
							{
								caption: ' ',
								cellTemplate: 'rebound',
								width: 100,
							},
						],
						onInitialized: function (e) {
							$scope.gridSammaryInstance = e.component;
						},
					};

					document.onclick = function (e) {
						if (!e.target.closest('tooltip')) {
							hideInfo();
						}
					};

					vm.initAll = function () {
						$scope.API_BASE_URL_BACKEND = API_BASE_URL_BACKEND;
						$scope.accessToken =
							window._keycloak && window._keycloak.token
								? window._keycloak.token
								: '';
						$scope.timeline_limit = 10000;
						$scope.widgetData = {};

						if (!vm.data.full_data) {
							PermissionService.havePermission(
								vm.data.timelineId,
								'edit',
								'timeline'
							).then(function (response) {
								$scope.editIsPermitted = response.data;
							});
						}
						initPopover();
					};

					vm.init = function (ignoreConfigForAuto, ignoreCache) {
						$scope.from_date = $('#from_date' + $scope.uuid).val();
						$scope.to_date = $('#to_date' + $scope.uuid).val();
						$scope.central_date = $('#central_date' + $scope.uuid).val();

						delete $scope.dataMessage;
						if ($scope.datesScaleRelative && !$scope.central_date) {
							$scope.dataMessage = gettextCatalog.getString(
								'La date de référence de l’axe 0 est obligatoire'
							);
							return;
						}

						if ($scope.from_date) {
							var from = moment($scope.from_date, 'DD/MM/YYYY HH:mm:ss')
								.toDate()
								.getTime();
							if ($scope.to_date) {
								var to = moment($scope.to_date, 'DD/MM/YYYY HH:mm:ss')
									.toDate()
									.getTime();
								if (from > to) {
									$scope.dataMessage = gettextCatalog.getString(
										'La date de début doit être inférieur à la date de fin'
									);
									return;
								}
								if ($scope.central_date && $scope.datesScaleRelative) {
									var scale = moment($scope.central_date, 'DD/MM/YYYY HH:mm:ss')
										.toDate()
										.getTime();
									if (scale < from) {
										$scope.dataMessage = gettextCatalog.getString(
											'La date de référence de l’axe 0 n’est pas valide'
										);
										return;
									}
								}
							}
						}

						if ($scope.to_date) {
							var to = moment($scope.to_date, 'DD/MM/YYYY HH:mm:ss')
								.toDate()
								.getTime();
							if ($scope.to_date) {
								if ($scope.central_date && $scope.datesScaleRelative) {
									var scale = moment($scope.central_date, 'DD/MM/YYYY HH:mm:ss')
										.toDate()
										.getTime();
									if (scale > to) {
										$scope.dataMessage = gettextCatalog.getString(
											'La date de référence de l’axe 0 n’est pas valide'
										);
										return;
									}
								}
							}
						}

						delete $scope.tlData;
						delete $scope.timelineSammaryGrid;
						delete $scope.tlDataCount;

						if (timeline) {
							$('#tp' + $scope.uuid).empty();
							$('#ts' + $scope.uuid).empty();
						}

						if ($scope.jobDetails && $scope.jobDetails.init) {
							$scope.jobDetails.init();
						}

						if (!vm.data.full_data) {
							TimeLineService.getTimeLineConfig(vm.data.timelineId).then(
								function (response) {
									$scope.tlConfig = response.data;
									initTimelineConfig(ignoreConfigForAuto, false, ignoreCache);
								}
							);
						} else {
							$scope.tlConfig = vm.data.full_data.config;
							initTimelineConfig(ignoreConfigForAuto, true, ignoreCache);
						}
					};

					$scope.updateIndicatorInterval = function (start, end) {
						updateIntervalWithDate(start, end, timeline);
					};

					$scope.showInfo = function (event, groupId, ids_, showCenter) {
						hideInfo();
						var items = $scope.getItemDataByIds(groupId, ids_);
						var innerHTML;
						var baseWidth = 600;
						if (items.length === 1) {
							var item = items[0];
							var group = $scope.getGroupById(groupId);
							var columns = group.columns;
							innerHTML = $scope.getItemDataInfoHtml(
								item.details,
								columns,
								showCenter,
								groupId,
								ids_,
								item.start
							);
						} else {
							innerHTML = $scope.getItemDataInfoHtmlForMultiple(
								groupId,
								ids_,
								items.length,
								showCenter
							);
							baseWidth = 500;
						}

						base.innerHTML = innerHTML;
						var windowWidth = $(window).width();
						if (event.clientX + 20 + baseWidth < windowWidth) {
							base.style.left = event.clientX + 20 + 'px';
						} else {
							base.style.left = event.clientX - baseWidth + 'px';
						}
						base.style.top = event.clientY + 20 + 'px';
						base.style.width = baseWidth + 'px';
						document.body.appendChild(base);
					};

					$scope.showPopover = function ($event, obj) {
						if (Object.keys(obj).length === 0) {
							$scope.noFilterApplied = true;
						} else {
							$scope.noFilterApplied = false;
						}
						// Set a bigger z-index
						$(function () {
							DevExpress.ui.dxOverlay.baseZIndex(1999999998);
						});
					};

					function initPopover() {
						$scope.sourceCentring = vm.data.centering
							? vm.data.centering.source_centering
							: {};
						$scope.filterDetailsPopover = {
							target: '#filterInfoPopover' + $scope.uuid,
							showEvent: 'dxclick',
							position: 'top',
							width: 450,
							bindingOptions: {
								visible: 'visibleMetaData',
							},
						};
					}

					$scope.getItemDataInfoHtml = function (
						row,
						columns,
						showCentrer,
						groupId,
						ids,
						start
					) {
						var group = $scope.getGroupById(groupId);
						var groupClass = group ? group.icon_id : '';
						var groupLib = group ? group.lib : '';
						var innerHTML = ' <div style="margin : 10px 10px 10px">';

						if (group) {
							var items = $scope.getItemDataByIds(groupId, ids);
							var dates = getStartEndDates(items);
							innerHTML = innerHTML + ' <div>';
							innerHTML =
								innerHTML +
								' <img style="width: 15px; height: 15px; margin-top:-5px" src="' +
								$rootScope.API_BASE_URL_BACKEND +
								'pictogramme_image?id=' +
								groupClass +
								'" title="' +
								gettextCatalog.getString('Pictogramme') +
								'"/>';
							innerHTML =
								innerHTML +
								' <b> ' +
								groupLib +
								' </b>  <button type="button" class="close left-space-20" data-dismiss="modal" onClick="hideInfo()"> <span aria-hidden="true">×</span><span class="sr-only">Close</span></button></div> ';
							innerHTML = innerHTML + ' <div>';
							innerHTML =
								innerHTML +
								' <br/> <b>  ' +
								gettextCatalog.getString('timeline.tooltip.le') +
								' ' +
								dates.formatted_start;
							if (dates.start !== dates.end) {
								innerHTML = innerHTML + ' au ' + dates.formatted_end;
							}

							innerHTML = innerHTML + ' </b> <div class="pull-right">    ';
							innerHTML =
								innerHTML +
								' <a title="' +
								seeTheTableTra +
								'" class="clickable" onclick="showItemsInWidget(\'' +
								$scope.uuid +
								"','" +
								groupId +
								"', '" +
								ids +
								'\')"> <img src="./src/img/svg/icon-table.svg" class="icon-grid icon-23" />  </a>';

							if (showCentrer) {
								innerHTML =
									innerHTML +
									' <a title="' +
									centerTableTra +
									'" class="clickable" onclick="setSubTimeLineInterval(\'' +
									$scope.uuid +
									"','" +
									groupId +
									"', '" +
									ids +
									'\')"> <img src="./src/img/svg/icon-centrer.svg" class="icon-grid icon-23" />  </a>';
							}

							innerHTML = innerHTML + '  </div>';
							innerHTML = innerHTML + ' <Br/> <br/>  </div>';
						}

						innerHTML =
							innerHTML + ' <table style="width : 100%; font-size : 12px">';

						for (var c in group.config.tooltip_columns) {
							var column = _.find(columns, function (item) {
								return item.uuid == group.config.tooltip_columns[c];
							});

							innerHTML = innerHTML + '<tr>';
							innerHTML =
								innerHTML + '<td class="width-50"> <a style="cursor: pointer">';
							innerHTML = innerHTML + ' </a> ';
							innerHTML =
								innerHTML +
								'<span style="font-weight: bolder;" class="left-space-5">';
							innerHTML = innerHTML + column.lib;
							innerHTML = innerHTML + '</span>';
							innerHTML = innerHTML + '</td>';

							let formattedValue;
							if (group && group.config && group.config.tooltip_columns_patterns && column.uuid in group.config.tooltip_columns_patterns) {
								let tooltipPattern = group.config.tooltip_columns_patterns[column.uuid];
								let pattern = tooltipPattern ? tooltipPattern.pattern : null;
								if (column.type === "date") {
									if (row[column.uuid].val instanceof Array)  {
										formattedValue = row[column.uuid].val.map(v => DateService.dateToStringWithPatternAndTZ(v, pattern, $rootScope.getDefaultTimezone())).join("; ");
									} else {
										formattedValue = DateService.dateToStringWithPatternAndTZ(
											row[column.uuid].val,
											pattern,
											$rootScope.getDefaultTimezone()
										);
									}
								} else if (column.type === "decimal" || column.type === 'integer' || column.type === 'big_integer') {
										formattedValue = CommonServices.formatNumber(row[column.uuid].val, tooltipPattern);
								}
							} else {
								formattedValue = $scope.formatStringContent(row[column.uuid].val, column.type);
							}
							innerHTML =
								innerHTML +
								'<td class="width-50"> <a style="cursor: pointer" > </a> ';
							innerHTML =
								innerHTML +
								'<span> ' +
								formattedValue +
								'</span>';
							innerHTML = innerHTML + '</td>';
							innerHTML = innerHTML + '</tr>';
						}

						innerHTML = innerHTML + '</table> </div>';
						return innerHTML;
					};

					$scope.getItemDataInfoHtmlForMultiple = function (
						groupId,
						ids,
						itemsNumber,
						showCentrer
					) {
						var innerHTML = ' <div style="margin : 10px 10px 10px">';
						var group = $scope.getGroupById(groupId);
						innerHTML = innerHTML + ' <div>';
						innerHTML =
							innerHTML +
							' <img style="width: 15px; height: 15px; margin-top:-5px" src="' +
							$rootScope.API_BASE_URL_BACKEND +
							'pictogramme_image?id=' +
							group.icon_id +
							'" title=" ' +
							gettextCatalog.getString('Pictogramme') +
							'"/>';
						innerHTML =
							innerHTML +
							' <b> ' +
							group.lib +
							' </b> <button type="button" class="close left-space-20" data-dismiss="modal" onClick="hideInfo()"> <span aria-hidden="true">×</span><span class="sr-only">Close</span></button> </div> ';

						var items = $scope.getItemDataByIds(groupId, ids);
						var dates = getStartEndDates(items);
						innerHTML = innerHTML + ' <div>';
						innerHTML =
							innerHTML +
							' <b> ' +
							gettextCatalog.getString('De') +
							' ' +
							dates.formatted_start;
						innerHTML =
							innerHTML +
							' ' +
							gettextCatalog.getString('à') +
							' ' +
							dates.formatted_end;
						innerHTML = innerHTML + ' </b> <div class="pull-right"> ';

						innerHTML =
							innerHTML +
							' <a title="' +
							seeTheTableTra +
							'" class="clickable" onclick="showItemsInWidget(\'' +
							$scope.uuid +
							"','" +
							groupId +
							"', '" +
							ids +
							'\')"> <img src="./src/img/svg/icon-table.svg" class="icon-grid icon-23" />  </a>';

						if (showCentrer) {
							innerHTML =
								innerHTML +
								' <a title="' +
								centerTableTra +
								'" class="clickable" onclick="setSubTimeLineInterval(\'' +
								$scope.uuid +
								"','" +
								groupId +
								"', '" +
								ids +
								'\')"> <img src="./src/img/svg/icon-centrer.svg" class="icon-grid icon-23" />  </a>';
						}
						innerHTML = innerHTML + '  </div> ';
						innerHTML = innerHTML + '  <br/> <br/>  </div>';

						innerHTML =
							innerHTML +
							' <b> ' +
							gettextCatalog.getString('Informations multiples') +
							' </b>  <br/>';
						innerHTML =
							innerHTML +
							'<button class="btn btn-default" style="cursor : pointer" onclick="doReboundMany(\'' +
							$scope.uuid +
							"','" +
							groupId +
							"', '" +
							ids +
							'\')"> <b>' +
							gettextCatalog.getString('Voir les détails') +
							' </b> (' +
							itemsNumber +
							' ' +
							gettextCatalog.getString('évènements') +
							')  </button>';
						innerHTML = innerHTML + '</div>';
						return innerHTML;
					};

					$scope.showItemsInWidget = function (groupId, ids_) {
						var items = $scope.getItemDataByIds(groupId, ids_);
						var group = $scope.getGroupById(groupId);
						var dates = getStartEndDates(items);
						group.dataviewData.filterOnRows(function (item) {
							return (
								item[group.config.start_date_column].timestamp <= dates.end &&
								item[group.config.start_date_column].timestamp >= dates.start
							);
						});
						hideInfo();
						$('.nav-tabs a[data-target="#' + group.id + $scope.uuid + '"]').tab(
							'show'
						);
					};

					$scope.doReboundMany = function (groupId, ids_) {
						var items = $scope.getItemDataByIds(groupId, ids_);
						$scope.reboundMany(groupId, items);
					};

					$scope.reboundMany = function (groupId, items) {
						$scope.widgetData.multipleRebound = true;
						$scope.reboundItems(items);
					};

					$scope.reboundItems = function (items) {
						var reboundColumns = [];
						var reboundRows = [];
						for (var i in items) {
							var group = $scope.getGroupById(items[i].group);
							var columns = group.columns;
							reboundColumns.push(columns);
							let itemDetails = items[i].details;
							for (let key in itemDetails) {
								if (itemDetails[key].rawDate || itemDetails[key].timestamp) {
									itemDetails[key].val = itemDetails[key].rawDate
										? DateService.dateToStringWithPatternAndTZ(
												itemDetails[key].rawDate,
												dataProvider.getDefaultFormat(),
												$rootScope.getDefaultTimezone()
										  )
										: DateService.dateToStringWithPatternAndTZ(
												itemDetails[key].timestamp,
												dataProvider.getDefaultFormat(),
												$rootScope.getDefaultTimezone()
										  );
								}
							}
							reboundRows.push(itemDetails);
						}
						$scope.widgetData.renoundedRow = reboundRows;
						$scope.widgetData.columnsRebound = reboundColumns;
						$scope.widgetData.showRebound = true;
						$scope.$apply();
					};

					$scope.rerun = function () {
						$scope.showTableData = false;
						vm.init(true, false);
					};

					$scope.edit = function () {
						const url = $state.href('timelines-edit', {
							id: vm.data.timelineId,
						});
						$window.open(url, '_blank');
						return;
					};

					$scope.switchDataVisibility = function () {
						$scope.showTableData = !$scope.showTableData;
					};

					$scope.rerunWithoutCache = function () {
						vm.init(true, true);
					};

					$scope.getItemData = function (groupId, ids_) {
						return $scope.getItemDataByIds(groupId, ids_);
					};

					$scope.getItemDataByIds = function (groupId, ids_) {
						ids_ = ids_.toString();
						var items = [];
						if (ids_.indexOf('-') != -1) {
							var ids = ids_.split('-');
							for (var i in ids) {
								var item = _.find(subTimeline.itemsData, {
									id: Number(ids[i]),
								});
								items.push(item);
							}
						} else {
							var item = _.find(subTimeline.itemsData, {
								id: Number(ids_),
							});
							items.push(item);
						}
						return items;
					};

					$scope.zoom = function (val) {
						zoom(val, subTimeline);
					};

					$scope.getGroupById = function (id) {
						for (var i in $scope.groups) {
							if ($scope.groups[i].id == id && $scope.groups[i].grammar) {
								return $scope.groups[i];
							}
						}
					};

					$scope.getGroupByIdFromAll = function (id) {
						for (var i in $scope.groups) {
							if ($scope.groups[i].id == id) {
								return $scope.groups[i];
							}
						}
					};

					$scope.toggleTimelineGroups = function () {
						$scope.fillTimeline();
					};

					$scope.fillTimeline = function () {
						if (!timeline) {
							return;
						}

						timeline.timeline.setGroups([]);
						subTimeline.timeline.setGroups([]);

						var mtGroups = _.filter($scope.groups, function (item) {
							return item.config.visibility_top;
						});

						var stGroups = _.filter($scope.groups, function (item) {
							return item.config.visibility_bottom;
						});

						subTimeline.timeline.setGroups(stGroups);
						timeline.timeline.setGroups(mtGroups);
					};

					$scope.toggleDisplayingTimelineGroups = function () {
						var idsTop = [];
						var idsBottom = [];
						var order_i = 0;
						for (var g in $scope.groups) {
							order_i++;
							if ($scope.groups[g].config.visibility_top) {
								idsTop.push($scope.groups[g].id);
							}
							if ($scope.groups[g].config.visibility_bottom) {
								idsBottom.push($scope.groups[g].id);
							}
						}

						timeline.timeline.setGroups([]);
						subTimeline.timeline.setGroups([]);

						timeline.timeline.setGroups(
							idsTop.map(function (id) {
								return _.find($scope.groups, {
									id: id,
								});
							})
						);

						subTimeline.timeline.setGroups(
							idsBottom.map(function (id) {
								return _.find($scope.groups, {
									id: id,
								});
							})
						);
					};

					$scope.initEvents = function () {
						var TimelineEvents = function () {};

						Object.assign(TimelineEvents.prototype, EventDispatcher.prototype);
						timelineEvents = new TimelineEvents();

						timelineEvents.addEventListener(
							'interval_change',
							function (event) {
								setTimeLineDateFromLiveInterval(subTimeline);
								updateIndicatorPosition(timeline);
							}
						);

						timelineEvents.addEventListener(
							'interval_changed',
							function (event) {
								setTimeLineDateFromLiveInterval(subTimeline);
								timeline.intervalLocked = false;
								updateIndicatorPosition(timeline);
								updateInterval(timeline);
							}
						);

						return timelineEvents;
					};

					$scope.loadTimelineSammaryGrid = function (itemsData) {
						if (!itemsData[0]) {
							return;
						}
						var data = [];
						itemsData = _.sortBy(itemsData, function (item) {
							return item.start;
						});
						var groups = {};
						for (var g in $scope.groups) {
							groups[$scope.groups[g].id] = $scope.groups[g];
						}

						var minEndDate = itemsData[0].end
							? itemsData[0].end
							: itemsData[0].start;

						for (var d in itemsData) {
							var group = groups[itemsData[d].group];

							var item = {
								cpt: d,
								group_id: group.id,
								id: itemsData[d].id,
								group: group.lib,
								group_icon: group.icon_id,
								inGlobalTL: group.config.visibility_top,
							};
							item.start = moment
								.tz(itemsData[d].start, $rootScope.serverTimezone)
								.format(dataProvider.uiGridDefaultFormat);
							item.startTimestamp = itemsData[d].start;
							item.details = itemsData[d].details;
							item.startTimestamp = itemsData[d].start;
							item.endTimestamp = itemsData[d].end
								? itemsData[d].end
								: itemsData[d].start;

							if (itemsData[d].end) {
								item.end = moment
									.tz(itemsData[d].end, $rootScope.serverTimezone)
									.format(dataProvider.uiGridDefaultFormat);
								item.duration = getDiff(itemsData[d].start, itemsData[d].end);
								item.durationMS = getDiffMS(
									itemsData[d].start,
									itemsData[d].end
								);
							}

							item.time1 = getDiff(minEndDate, itemsData[d].start);
							item.time1MS = getDiffMS(minEndDate, itemsData[d].start);

							if (!item.end) {
								item.end = item.start;
								item.endTimestamp = item.startTimestamp;
							}
							if (group.lastItem) {
								group.lastItem.time2 = getDiff(
									group.lastItem.endTimestamp,
									item.startTimestamp
								);
								group.lastItem.time2MS = getDiffMS(
									group.lastItem.endTimestamp,
									item.startTimestamp
								);
							}

							item.durationMS = itemsData[d].end - itemsData[d].start;
							data.push(item);
							group.lastItem = item;
						}

						$scope.timelineSammaryGrid = timelineSammaryGrid;
						$scope.timelineSammaryGrid.dataSource = data;
					};

					$scope.formatStringContent = function (value, type) {
						if (type == 'date') {
							return moment
								.tz(value, $rootScope.serverTimezone)
								.format(dataProvider.uiGridDefaultFormat);
						}
						var originalValue = value;
						if (value && value.length > 30) {
							value = value.substring(0, 30);
							value = value + '...';
							if (value && value.length > 30) {
								value = value.substring(0, 30);
								value = value + '...';
							}
						}
						if (isUrl(value)) {
							return (
								"<a href='" +
								originalValue +
								"' target='_blank'>" +
								value +
								'</a>'
							);
						}
						return value ? value : '';
					};

					$scope.setSubTimeLineInterval = function (groupId, ids_) {
						var items = $scope.getItemDataByIds(groupId, ids_);
						var dates = getStartEndDates(items);
						var indicatorDate = getLiveIndicatorDates(timeline);
						subTimeline.timeline.setOptions({
							min: indicatorDate.start,
							max: indicatorDate.end,
							start: new Date(dates.start - 36000000),
							end: new Date(dates.end + 36000000),
						});
					};

					$scope.loadGroupGlobalDataGrid = function () {
						// FIXME: I have the method here because it was missing from current scope
						// usage from the global function getGlobalData was in error
						angular.noop();
					};

					$scope.loadGroupDetailsDataGrid = function () {
						// FIXME: I have the method here because it was missing from current scope
						// usage from the global function getDetailsData was in error
						angular.noop();
					};

					function initTimelineConfig(
						ignoreConfigForAuto,
						fullData,
						ignoreCache
					) {
						if (!$scope.tlConfig.groups[0]) {
							$scope.dataMessage = gettextCatalog.getString(
								'Aucune source disponible'
							);
							return;
						}
						if (!checkConfig($scope.tlConfig)) {
							$scope.dataMessage = gettextCatalog.getString(
								'La TimeLine ne peut pas être visualisée. Certains paramètres ne sont pas valides.'
							);
							return;
						}
						var autoExecute = ignoreConfigForAuto
							? true
							: $scope.tlConfig.global.auto_load;

						$scope.tlConfig.groups = _.sortBy(
							$scope.tlConfig.groups,
							function (item) {
								return item.order;
							}
						);
						$scope.groups = angular.copy($scope.tlConfig.groups);
						initTimeLine();
						$timeout(function () {
							$('#from_date' + $scope.uuid).datetimepicker({
								format: 'DD/MM/Y HH:mm:ss',
								inline: false,
							});
							$('#to_date' + $scope.uuid).datetimepicker({
								format: 'DD/MM/Y HH:mm:ss',
								inline: false,
							});
							$('#central_date' + $scope.uuid).datetimepicker({
								format: 'DD/MM/Y HH:mm:ss',
								inline: false,
							});
							if (!fullData) {
								initializeGroups(0, autoExecute, ignoreCache);
							} else {
								initializeGroupsWithFullData(0, autoExecute, ignoreCache);
							}
						}, 500);
					}

					function checkConfig(config) {
						for (var g in config.groups) {
							if (!config.groups[g].config.start_date_column) {
								return false;
							}
						}
						return true;
					}

					function initializeGroups(i, autoExecute, ignoreCache) {
						if (!$scope.groups[i]) {
							return;
						}
						hdSourceService
							.getSource(
								$scope.groups[i].source_id,
								'TIMELINE',
								vm.data.timelineId
							)
							.then(function (response) {
								$scope.groups[i].grammar = response.data.grammar;

								if ($scope.from_date) {
									addFilter(
										$scope.groups[i].grammar,
										$scope.groups[i].config.start_date_column,
										'date',
										$scope.from_date,
										'greater_or_equal'
									);
								}
								if ($scope.to_date) {
									addFilter(
										$scope.groups[i].grammar,
										$scope.groups[i].config.start_date_column,
										'date',
										$scope.to_date,
										'less_or_equal'
									);
								}
								$scope.groups[i].dataviewData = {
									auto_execute: autoExecute,
									hide_operations: true,
									hdType: 'TIMELINE',
									hdId: vm.data.timelineId,
								};
								$scope.groups[i].dataviewData.afterDataLoaded = function (
									data,
									columns
								) {
									$scope.groups[i].columns = columns;
									loadGroupDataInTimeline($scope.groups[i], data);
								};
								$scope.groups[i].dataviewData.jobDetails = $scope.jobDetails;
								$scope.groups[i].dataviewData.dataview = {
									source_id: $scope.groups[i].source_id,
									grammar: $scope.groups[i].grammar,
									lib: $scope.groups[i].lib,
									centering: vm.data.centering,
									ignore_cache_on_init: ignoreCache,
								};
								initializeGroups(i + 1, autoExecute, ignoreCache);
							});
					}

					function initializeGroupsWithFullData(i, autoExecute, ignoreCache) {
						if (!$scope.groups[i]) {
							return;
						}
						let fullData = _.find(
							$scope.vm.data.full_data.sources,
							function (item) {
								return item.source_id == $scope.groups[i].source_id;
							}
						);

						if ($scope.from_date) {
							addFilter(
								$scope.groups[i].grammar,
								$scope.groups[i].config.start_date_column,
								'date',
								$scope.from_date,
								'greater_or_equal'
							);
						}
						if ($scope.to_date) {
							addFilter(
								$scope.groups[i].grammar,
								$scope.groups[i].config.start_date_column,
								'date',
								$scope.to_date,
								'less_or_equal'
							);
						}
						$scope.groups[i].dataviewData = {
							full_data: fullData,
							auto_execute: autoExecute,
							hide_operations: true,
							hdType: 'TIMELINE',
							hdId: vm.data.timelineId,
						};
						$scope.groups[i].dataviewData.afterDataLoaded = function (
							data,
							columns
						) {
							$scope.groups[i].columns = columns;
							loadGroupDataInTimeline($scope.groups[i], data);
						};
						$scope.groups[i].dataviewData.jobDetails = $scope.jobDetails;
						$scope.groups[i].dataviewData.dataview = {
							source_id: $scope.groups[i].source_id,
							grammar: $scope.groups[i].grammar,
							lib: $scope.groups[i].lib,
							centering: vm.data.centering,
							ignore_cache_on_init: ignoreCache,
						};
						initializeGroupsWithFullData(i + 1, autoExecute, ignoreCache);
					}

					function loadGroupDataInTimeline(group, data) {
						if (!$scope.tlData) {
							$scope.tlData = [];
							$scope.tlDataCount = 0;
						}
						$scope.tlData.push({ group: group, items: data });
						$scope.tlDataCount = $scope.tlDataCount + data.length;
						if ($scope.tlData.length == $scope.groups.length) {
							createOrInit();
						}
					}

					function getStartEndDates(items) {
						var startDate;
						var endDate;
						var formattedStart;
						var formattedEnd;
						for (var i in items) {
							if (!startDate || items[i].start <= startDate) {
								startDate = items[i].start;
							}

							if (!endDate || items[i].start >= endDate) {
								endDate = items[i].start;
							}

							if (!endDate || items[i].end >= endDate) {
								endDate = items[i].end;
							}
						}
						return {
							start: startDate,
							end: endDate,
							formatted_start: DateService.dateToStringWithPatternAndTZ(
								startDate,
								dataProvider.getDefaultFormat(),
								$rootScope.getDefaultTimezone()
							),
							formatted_end: DateService.dateToStringWithPatternAndTZ(
								endDate,
								dataProvider.getDefaultFormat(),
								$rootScope.getDefaultTimezone()
							),
						};
					}

					function initTimeLine() {
						for (var g in $scope.groups) {
							$scope.groups[g].icon_id = $scope.groups[g].icon_id
								? $scope.groups[g].icon_id
								: -1;
							$scope.groups[g].content = getGroupContent(
								$scope.groups[g],
								true
							);
							$scope.groups[g].id_ = 'r_' + $scope.groups[g].id;
						}
					}

					function initDateRange(start, end, intervalStart, intervalEnd) {
						$('#range-selector' + $scope.uuid).dxRangeSelector({
							margin: {
								top: 10,
							},

							size: {
								height: 70,
							},

							scale: {
								startValue: start,
								endValue: end,
								minorTickInterval: 'hour',
								tickInterval: { minute: 1 },
								minRange: 'minute',
								maxRange: 'year',
								minorTick: {
									visible: true,
								},
							},

							sliderMarker: {
								format: 'longDateLongTime',
							},

							behavior: {
								callValueChanged: 'onMoving',
							},
							onValueChanged: function (e) {
								$scope.updateIndicatorInterval(
									e.value[0].toString(),
									e.value[1].toString()
								);
								timelineEvents.dispatchEvent({
									type: 'interval_change',
								});
							},
						});
					}

					function createOrInit() {
						$scope.ignoredItemsNbr = 0;
						var id = 1;
						var itemsData = [];
						for (var g in $scope.tlData) {
							for (var itm in $scope.tlData[g].items) {
								if (
									!$scope.tlData[g].items[itm][
										$scope.tlData[g].group.config.start_date_column
									].timestamp
								) {
									$scope.ignoredItemsNbr = $scope.ignoredItemsNbr + 1;
									continue;
								}
								var item = {};
								item.group = $scope.tlData[g].group.id;
								item.id = id++;
								item.details = $scope.tlData[g].items[itm];
								item.start =
									$scope.tlData[g].items[itm][
										$scope.tlData[g].group.config.start_date_column
									].timestamp;
								if ($scope.tlData[g].group.config.end_date_column) {
									item.end =
										$scope.tlData[g].items[itm][
											$scope.tlData[g].group.config.end_date_column
										].timestamp;
								}
								itemsData.push(item);
							}
						}

						if (timeline) {
							timeline.intervalLocked = true;
						}

						//TZ
						if (!$scope.tlDataCount || !itemsData[0]) {
							$scope.dataMessage = gettextCatalog.getString(
								'Evènements indisponibles pour cette période'
							);
							return;
						} else if ($scope.tlDataCount > $scope.timeline_limit) {
							$scope.dataMessage =
								gettextCatalog.getString('Le nombre d’évènements') +
								' [' +
								$scope.tlDataCount +
								'] ' +
								gettextCatalog.getString(
									"pour cette période dépasse la limite d'affichage. Merci d'affiner le filtre."
								);
							return;
						}

						var dateInterval = getFirstAndLastDate(itemsData);

						if (!$scope.from_date) {
							$scope.from_date = moment(new Date(dateInterval.first))
								.tz($rootScope.getDefaultTimezone())
								.format('DD/MM/YYYY HH:mm:ss');
						}

						if (!$scope.to_date) {
							$scope.to_date = moment(new Date(dateInterval.last))
								.tz($rootScope.getDefaultTimezone())
								.format('DD/MM/YYYY HH:mm:ss');
						}

						var diff =
							moment($scope.to_date, 'DD/MM/YYYY HH:mm:ss').toDate().getTime() -
							moment($scope.from_date, 'DD/MM/YYYY HH:mm:ss')
								.toDate()
								.getTime();

						diff = diff / (1000 * 60 * 60);

						if (diff < 24) {
							$scope.to_date = moment($scope.from_date, 'DD/MM/YYYY HH:mm:ss')
								.add(1, 'days')
								.format('DD/MM/YYYY HH:mm:ss');
							$scope.from_date = moment($scope.from_date, 'DD/MM/YYYY HH:mm:ss')
								.add(-1, 'days')
								.format('DD/MM/YYYY HH:mm:ss');
						}

						var dateForOption = getDatesForOptions(
							$scope.from_date,
							$scope.to_date
						);

						if ($scope.datesScaleRelative) {
							dateForOption.format = getDatesFormat();
						} else {
							dateForOption.format = {
								minorLabels: {
									millisecond: 'SSS',
									second: 's',
									minute: 'HH:mm',
									hour: 'HH:mm',
									weekday: 'ddd D',
									day: 'D',
									week: 'w',
									month: 'MMM',
									year: 'YYYY',
								},
								majorLabels: {
									millisecond: 'HH:mm:ss',
									second: 'D MMMM HH:mm',
									minute: 'ddd D MMMM',
									hour: 'ddd D MMMM',
									weekday: 'MMMM YYYY',
									day: 'MMMM YYYY',
									week: 'MMMM YYYY',
									month: 'YYYY',
									year: '',
								},
							};
						}

						timelineEvents = $scope.initEvents();

						var groupedItems = _.toArray(_.groupBy(itemsData, 'group'));

						timeline = mainTimelineService.init(
							$scope.uuid,
							document.getElementById('tp' + $scope.uuid),
							itemsData,
							groupedItems,
							[],
							_.extend(
								{
									editable: false,
									zoomable: false,
									moveable: false,
									locale: 'fr',
									moment: function (date) {
										return moment.tz(date, $rootScope.serverTimezone);
									},
									multiselect: false,
								},
								dateForOption
							)
						);

						subTimeline = subTimelineService.init(
							$scope.uuid,
							document.getElementById('ts' + $scope.uuid),
							itemsData,
							groupedItems,
							[],
							{
								editable: false,
								zoomable: true,
								moveable: true,
								locale: 'fr',
								moment: function (date) {
									return moment.tz(date, $rootScope.serverTimezone);
								},
								multiselect: false,
							},
							timeline
						);

						subTimeline.groups = new vis.DataSet($scope.groups);
						timeline.groups = new vis.DataSet($scope.groups);

						timeline.itemsData = subTimeline.itemsData = itemsData;
						timeline.groupsData = subTimeline.groupsData = [];

						timeline.groupedItems = subTimeline.groupedItems = groupedItems;

						setOptions(dateForOption, timeline);

						setGroupedItems(timeline);
						initInterval(timeline);

						setGroupedItems(subTimeline);
						subTimeline.parentTimeline = timeline;
						setTimeLineDateFromLiveInterval(subTimeline);
						$timeout(function () {
							$scope.zoom(1);
							$scope.zoom(-1);
						}, 500);

						$scope.toggleTimelineGroups();
						timeline.intervalLocked = true;

						if ($scope.tlConfig.global.sammary) {
							$scope.loadTimelineSammaryGrid(itemsData);
						}

						initDateRange(
							timeline.options.start,
							timeline.options.end,
							timeline.options.start,
							timeline.options.end
						);
					}

					function getDatesFormat() {
						var central_date = $('#central_date' + $scope.uuid).val();
						central_date = new Date(
							moment(central_date, 'DD/MM/YYYY HH:mm:ss').toDate().getTime()
						);
						return {
							minorLabels: function (date, scale, step) {
								var now = central_date;
								var ago = now - date;
								var divider;
								switch (scale) {
									case 'millisecond':
										divider = 1;
										break;
									case 'second':
										divider = 1000;
										break;
									case 'minute':
										divider = 1000 * 60;
										break;
									case 'hour':
										divider = 1000 * 60 * 60;
										break;
									case 'day':
										divider = 1000 * 60 * 60 * 24;
										break;
									case 'weekday':
										divider = 1000 * 60 * 60 * 24 * 7;
										break;
									case 'month':
										divider = 1000 * 60 * 60 * 24 * 30;
										break;
									case 'year':
										divider = 1000 * 60 * 60 * 24 * 365;
										break;
									default:
										return new Date(date);
								}
								return (
									Math.round((-ago * step) / divider) +
									' ' +
									getScaleLabel(scale)
								);
							},
							majorLabels: function (date, scale, step) {
								var now = new Date();
								var ago = now - date;
								var divider;
								switch (scale) {
									case 'millisecond':
										divider = 1;
										break;
									case 'second':
										divider = 1000;
										break;
									case 'minute':
										divider = 1000 * 60;
										break;
									case 'hour':
										divider = 1000 * 60 * 60;
										break;
									case 'day':
										divider = 1000 * 60 * 60 * 24;
										break;
									case 'weekday':
										divider = 1000 * 60 * 60 * 24 * 7;
										break;
									case 'month':
										divider = 1000 * 60 * 60 * 24 * 30;
										break;
									case 'year':
										divider = 1000 * 60 * 60 * 24 * 365;
										break;
									default:
										return new Date(date);
								}
								return '';
								//return  (Math.round(ago * step / divider)) + " " + getScaleLabel(scale)
							},
						};
					}

					function getGroupContent(group, details) {
						var str =
							$rootScope.API_BASE_URL_BACKEND +
							'pictogramme_image?id=' +
							group.icon_id;
						var methodName = 'getGlobalData';
						if (details) {
							methodName = 'getDetailsData';
						}
						var result =
							"<a style='cursor: pointer;' title='" +
							group.lib +
							"' class='category' onclick='" +
							methodName +
							'("' +
							group.id +
							'")\'> ';

						result =
							result +
							"<img style='width: 15px; height: 15px; margin-top:-5px' src='" +
							str +
							"' type='button' title='" +
							gettextCatalog.getString('Pictogramme') +
							"'/>";
						result =
							result + " </a> <b class='category_label'>" + group.lib + '</b>';

						return result;
					}
				},
			];

			return {
				restrict: 'E',

				scope: {
					data: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/handledata/timelineView/timelineView.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs, $ctrl) {
					if (!$ctrl.data.full_data) {
						scope.jobDetails = { rerunMethod: scope.rerunWithoutCache };
						scope.jobDetailsId = generateUuid('_');
					}
					scope.uuid = generateUuid('_');
					timelinesDirectiveScopes[scope.uuid] = scope;
					// $ctrl.addScope();
					$ctrl.initAll();
					$ctrl.init(false, false);
				},
			};
		},
	]);
})();
