(function () {
	'use strict';

	angular.module('dcApp').directive('barchartview', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$state',
				'ChartService',
				'hdSourceService',
				'$window',
				'PermissionService',
				'ChartViewCommonFactory',
				'gettextCatalog',
				'$filter',
				'$timeout',
				'DX_RERENDER_TIMEOUT',
				function (
					$scope,
					$state,
					ChartService,
					hdSourceService,
					$window,
					PermissionService,
					ChartViewCommonFactory,
					gettextCatalog,
					$filter,
					$timeout,
					DX_RERENDER_TIMEOUT
				) {
					var vm = this;
					vm.init = function (ignoreCache) {
						ChartViewCommonFactory.initialize(
							'barline',
							$scope,
							checkConfig,
							loadGroupDataInChart,
							ignoreCache
						);
					};

					ChartViewCommonFactory.initializeDirectiveScope(
						$scope,
						vm,
						'barline'
					);

					$scope.showPopover = function ($event, obj) {
						if (Object.keys(obj).length === 0) {
							$scope.noFilterApplied = true;
						} else {
							$scope.noFilterApplied = false;
						}
						// Set a bigger z-index
						$(function () {
							DevExpress.ui.dxOverlay.baseZIndex(1999999998);
						});
					};

					function initPopover() {
						$scope.sourceCentring = vm.data.centering
							? vm.data.centering.source_centering
							: {};
						$scope.filterDetailsPopover = {
							target: '#filterInfoPopover' + $scope.uuid,
							showEvent: 'dxclick',
							position: 'top',
							width: 450,
							bindingOptions: {
								visible: 'visibleMetaData',
							},
						};
					}

					var loadGroupDataInChart = function (data) {
						$scope.config.global.commonSeriesSettings = {
							argumentField: $scope.config.global.argumentAxis.column,
							type: $scope.config.global.subType
								? $scope.config.global.subType
								: $scope.config.global.type,
							barPadding: $scope.config.global.barPadding,
							label: { visible: $scope.config.global.valueAxis.showLabel },
						};
						initPopover();
						if (!$scope.config.global.series) {
							$scope.config.global.series = [];
						}

						if (!$scope.config.global.legend.visible) {
							$scope.config.global.legend.visible = false;
						}

						let valueAxis = [];
						for (var s in $scope.config.global.series) {
							// set order
							if ($scope.config.global.series[s].separatedAxis) {
								valueAxis.push({
									position: 'right',
									title: $scope.config.global.series[s].name,
									name: $scope.config.global.series[s].valueField,
								});

								$scope.config.global.series[s].axis =
									$scope.config.global.series[s].valueField;
							}
							if ($scope.config.global.series[s].aggregation) {
								$scope.config.global.series[s].aggregation = {
									enabled: true,
									method: $scope.config.global.series[s].aggregation,
								};
							}
							$scope.config.global.series[s].point = {
								visible: $scope.config.global.series[s].showPoint == true,
							};
							$scope.config.global.series[s].type = $scope.config.global.series[
								s
							].subType
								? $scope.config.global.series[s].subType
								: $scope.config.global.series[s].type;
						}

						if (
							$scope.config.global.argumentAxis.tickInterval &&
							$scope.config.global.argumentAxis.tickInterval.value &&
							$scope.config.global.argumentAxis.tickInterval.type
						) {
							var tickInterval = {};
							tickInterval[
								$scope.config.global.argumentAxis.tickInterval.type + 's'
							] = $scope.config.global.argumentAxis.tickInterval.value;
							$scope.config.global.argumentAxis.tickInterval = tickInterval;
						} else if (
							$scope.config.global.argumentAxis.tickInterval &&
							$scope.config.global.argumentAxis.tickInterval.value
						) {
							$scope.config.global.argumentAxis.tickInterval =
								$scope.config.global.argumentAxis.tickInterval.value;
						} else {
							delete $scope.config.global.argumentAxis.tickInterval;
						}

						if (
							$scope.config.global.argumentAxis.aggregationInterval &&
							$scope.config.global.argumentAxis.aggregationInterval.value &&
							$scope.config.global.argumentAxis.aggregationInterval.type
						) {
							var aggregationInterval = {};
							aggregationInterval[
								$scope.config.global.argumentAxis.aggregationInterval.type + 's'
							] = $scope.config.global.argumentAxis.aggregationInterval.value;
							$scope.config.global.argumentAxis.aggregationInterval =
								aggregationInterval;
						} else if (
							$scope.config.global.argumentAxis.aggregationInterval &&
							$scope.config.global.argumentAxis.aggregationInterval.value
						) {
							$scope.config.global.argumentAxis.aggregationInterval =
								$scope.config.global.argumentAxis.aggregationInterval.value;
						} else {
							delete $scope.config.global.argumentAxis.aggregationInterval;
						}

						if ($scope.config.global.valueAxis.constantLines) {
							for (var c in $scope.config.global.valueAxis.constantLines) {
								$scope.config.global.valueAxis.constantLines[c].label = {
									text: $scope.config.global.valueAxis.constantLines[c].label,
								};
							}
						}

						if (
							$scope.config.global.crosshair &&
							$scope.config.global.crosshair.enabled
						) {
							$scope.config.global.crosshair.label = { visible: true };
						}

						$scope.config.global.argumentAxis.aggregateByCategory = true;

						var column = _.find($scope.group.columns, function (item) {
							return item.uuid == $scope.config.global.valueAxis.column;
						});

						if ($scope.config.global.serieTemplate) {
							$scope.config.global.commonSeriesSettings.valueField =
								$scope.config.global.valueAxis.column;
							$scope.config.global.seriesTemplate = {
								nameField: $scope.config.global.serieTemplateField,
							};
							if ($scope.config.global.valueAxis.aggregation) {
								$scope.config.global.aggregation = {
									enabled: true,
									method: $scope.config.global.valueAxis.aggregation,
								};
							}
							$scope.config.global.commonSeriesSettings.point = {
								visible: $scope.config.global.valueAxis.showPoint == true,
							};
							$scope.config.global.commonSeriesSettings.color =
								$scope.config.global.valueAxis.color;
							$scope.config.global.commonSeriesSettings.dashStyle =
								$scope.config.global.valueAxis.dashStyle;
							delete $scope.config.global.series;
						} else {
							let valueSerie = {
								valueField: $scope.config.global.valueAxis.column,
								name: !isEmpty($scope.config.global.valueAxis.title)  ? $scope.config.global.valueAxis.title : column.lib,
								point: {
									visible: $scope.config.global.valueAxis.showPoint == true,
								},
								color: $scope.config.global.valueAxis.color,
								dashStyle: $scope.config.global.valueAxis.dashStyle,
							};

							if ($scope.config.global.valueAxis.aggregation) {
								valueSerie.aggregation = {
									enabled: true,
									method: $scope.config.global.valueAxis.aggregation,
								};
							}
							valueSerie.cumul = $scope.config.global.valueAxis.cumul;
							delete $scope.config.global.valueAxis.aggregation;
							$scope.config.global.series.push(valueSerie);
						}

						$scope.config.global.tooltipColumns = _.filter(
							$scope.config.global.tooltipColumns,
							function (item) {
								return _.find($scope.group.columns, function (col) {
									return col.uuid == item;
								});
							}
						);
						if (
							$scope.config.global.tooltipColumns &&
							$scope.config.global.tooltipColumns[0]
						) {
							$scope.config.global.tooltip = {
								enabled: true,
								location: 'edge',
								customizeTooltip: function (arg) {
									return {
										html: ChartService.getTooltip(
											arg.point.data,
											$scope.config.global.tooltipColumns,
											$scope.config.global.tooltipColumnsPatterns,
											$scope.group.columns,
											$scope.config.global.argumentAxis
										),
									};
								},
							};
						}

						let cumulSuffix = '_cumsum';
						let cumSumColumns = [];

						for (let s in $scope.config.global.series) {
							if ($scope.config.global.series[s].cumul) {
								cumSumColumns.push($scope.config.global.series[s].valueField);
								$scope.config.global.series[s].valueField =
									$scope.config.global.series[s].valueField + cumulSuffix;
							}
						}
						if (cumSumColumns[0]) {
							data = cumSumCompute(data, cumSumColumns, cumulSuffix);
						}

						if (
							$scope.config.global.valueAxis &&
							$scope.config.global.valueAxis.constantLines &&
							$scope.config.global.valueAxis.constantLines.length > 0
						) {
							let lineWithGreaterValue = _.find(
								$scope.config.global.valueAxis.constantLines,
								function (elm) {
									return (
										elm.colorSync &&
										elm.colorSync === 'GREATER_THAN_OR_EQUAL_TO'
									);
								}
							);
							let lineWithLowerValue = _.find(
								$scope.config.global.valueAxis.constantLines,
								function (elm) {
									return (
										elm.colorSync && elm.colorSync === 'LOWER_THAN_OR_EQUAL_TO'
									);
								}
							);
							if (lineWithGreaterValue || lineWithLowerValue) {
								let customizePoint = function customizePoint() {
									if (
										lineWithGreaterValue &&
										lineWithGreaterValue.value &&
										this.value > lineWithGreaterValue.value
									) {
										return { color: lineWithGreaterValue.color };
									} else if (
										lineWithLowerValue &&
										lineWithLowerValue.value &&
										this.value < lineWithLowerValue.value
									) {
										return { color: lineWithLowerValue.color };
									}
									return null;
								};

								$scope.config.global.customizePoint = customizePoint;
							}
						}
						$scope.dxChartOptions = angular.copy($scope.config.global);
						// add valueAxis
						if (valueAxis.length > 0) {
							let mainAxisVal = $scope.dxChartOptions.valueAxis;
							valueAxis.unshift({
								name: mainAxisVal.column,
								title: { text: mainAxisVal.title },
							});
							$scope.dxChartOptions.valueAxis = valueAxis;
						}
						$scope.dxChartOptions.dataSource = data;
						$('#chart' + $scope.uuid).dxChart($scope.dxChartOptions);
						$timeout(function () {
							$('#chart' + $scope.uuid)
								.dxChart('instance')
								.render();
						}, DX_RERENDER_TIMEOUT);

						ChartViewCommonFactory.chartAutoResizing(
							$scope,
							$('#chart' + $scope.uuid).dxChart('instance'),
							DX_RERENDER_TIMEOUT
						);

						return true;
					};

					var checkConfig = function (config) {
						if (
							!config.global.value_axis.column ||
							!config.global.argument_axis.column
						) {
							return false;
						}
						return true;
					};

					$scope.reset = function () {
						$('#chart' + $scope.uuid).dxChart('resetVisualRange');
					};

					function cumSumCompute(dataSource, columns, suffix) {
						var cumSource = [];
						for (var i = 0; i < dataSource.length; i++) {
							var data = dataSource[i];
							for (var j = 0; j < columns.length; j++) {
								let newName = columns[j] + suffix;
								if (i == 0) {
									data[newName] = data[columns[j]];
								} else {
									data[newName] = dataSource[i - 1][newName] + data[columns[j]];
								}
							}
							cumSource.push(data);
						}
						return cumSource;
					}
				},
			];

			return buildDirectiveReturnObject(
				{ data: '=' },
				controller,
				'./src/components/directives/handledata/chartView/barline/chartView.html',
				function postLink(scope, element, attrs) {
					if (!scope.vm.data.full_data) {
						scope.jobDetails = { rerunMethod: scope.rerunWithoutCache };
						scope.jobDetailsId = generateUuid('_');
					}
					scope.vm.init(false);
				}
			);
		},
	]);
})();
